import {combineReducers, configureStore} from "@reduxjs/toolkit";
import authReducer from './slices/auth'
import messageReducer from "./slices/message";
import userProfileReducer from "./slices/userProfile";
import categoryReducer from './slices/category';
import subCategoryReducer from './slices/subCategory';
import transactionReducer from './slices/transaction';
import transactionStatsReducer from './slices/transactionStats';
import walletReducer from './slices/wallet';
import reportReducer from './slices/reports';
import reminderReducer from './slices/reminder';
import notificationReducer from './slices/notification';

const combinedReducer = combineReducers({
    auth: authReducer,
    message: messageReducer,
    userProfile: userProfileReducer,
    category: categoryReducer,
    subCategory: subCategoryReducer,
    transaction: transactionReducer,
    transactionStats: transactionStatsReducer,
    wallet: walletReducer,
    report: reportReducer,
    reminder: reminderReducer,
    notification: notificationReducer
});

const rootReducer = (state, action) => {
    if(action.type === 'auth/logout/fulfilled') {
        state = undefined;
    }
    return combinedReducer(state, action);
};

export const store = configureStore({
    reducer: rootReducer,
    devTools: true,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})

// added middleware property because of below error:
// reactjs A non-serializable value was detected in an action, in the path

export default store;
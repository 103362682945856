import React from "react";
import Drawer from "@material-ui/core/Drawer";
import withStyles from "@material-ui/core/styles/withStyles";
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider
} from "@material-ui/core";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import LogoutIcon from '@mui/icons-material/Logout';
import {useNavigate} from 'react-router-dom';
import { Link } from "react-router-dom";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PaidIcon from '@mui/icons-material/Paid';
import SettingsIcon from '@mui/icons-material/Settings';
import CategoryIcon from '@mui/icons-material/Category';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import AlarmIcon from '@mui/icons-material/Alarm';

const styles = theme => ({
    list: {
        width: 250
    },
    fullList: {
        width: "auto"
    }
});


class DrawerComponent extends React.Component {


    state = {
        left: false
    };

    render() {
        const {classes} = this.props;

        const sideList = side => (
            <div
                className={classes.list}
                role="presentation"
                onClick={this.props.toggleDrawerHandler}
                onKeyDown={this.props.toggleDrawerHandler}
            >
                <List>

                    <ListItem button key="Transactions" component={Link} to='/Transactions/Page/this-month' >
                        <ListItemIcon>
                            <PaidIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Transactions"/>
                    </ListItem>
                    <ListItem button key="ReportPage" component={Link} to='/ReportPage' >
                        <ListItemIcon>
                            <EqualizerIcon/>
                        </ListItemIcon>
                        <ListItemText primary="ReportPage"/>
                    </ListItem>
                    <ListItem button key="Wallets" component={Link} to="/Wallets" >
                        <ListItemIcon>
                            <AccountBalanceWalletIcon />
                        </ListItemIcon>
                        <ListItemText primary="Wallets"/>
                    </ListItem>

                    <ListItem button key="Category" component={Link} to="/Category" >
                        <ListItemIcon>
                            <CategoryIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Category"/>
                    </ListItem>
                    <ListItem button key="Reminder" component={Link} to="/Reminder/page" >
                        <ListItemIcon>
                            <AlarmIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Reminders"/>
                    </ListItem>
                    <ListItem button key="Settings" component={Link} to="/Settings" >
                        <ListItemIcon>
                            <SettingsIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Settings"/>
                    </ListItem>
                </List>
                <Divider/>
                <List>
                    <ListItem button key="Logout" component={Link} to={"/Logout" }>
                            <ListItemIcon>
                                <LogoutIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Logout"/>

                    </ListItem>
                </List>
            </div>
        );

        return (
            <Drawer open={this.props.left} onClose={this.props.toggleDrawerHandler}>
                {sideList("left")}
            </Drawer>
        );
    }
}

export default withStyles(styles)(DrawerComponent);

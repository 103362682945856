import Box from "@mui/material/Box";
import CircularProgress from '@mui/material/CircularProgress';
import '../../assets/styles/circularLoading.scss';

const CircularLoading = ({enableFlag}) => {

    if (!enableFlag) {
        return "";
    }

    return (
        <div className="circular-loading-section">
            <div className="loading-content">
                <Box sx={{display: 'flex'}}>
                    <CircularProgress/>
                </Box>
            </div>
        </div>
    )
}
export default CircularLoading;
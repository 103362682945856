import axios from "axios";
import authHeader from "./auth-header";
import {DOMAIN_NAME} from "../utils/constants";

const API_URL = DOMAIN_NAME + "/api/auth/";

const getPublicContent = () => {

    return axios.get(API_URL + "hello");
};

const getUserInfo = () => {
    console.log("getUserInfo axios");
    return axios.get(API_URL + "userinfo", { headers: authHeader() });
};


const userService = {
    getPublicContent,
    getUserInfo
};

export default userService
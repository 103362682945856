import "bootstrap/dist/css/bootstrap.min.css"
import "./assets/styles/App.css"
import {BrowserRouter, Routes, Route} from "react-router-dom"
import SignUp from "./pages/auth/SignUp";
import SignIn from "./pages/auth/SignIn";
import Home from "./pages/home/Home";
import Profile from "./pages/profile/Profile";
import React, {useState, useEffect} from "react";
import Logout from "./pages/auth/Logout";
import Settings from "./pages/home/Settings";
import ToolbarComponent from "./components/toolbar/Toolbar";
import DrawerComponent from "./components/drawer/Drawer";
import {useSelector} from "react-redux";
import Transaction from "./pages/transaction/Transaction";
import Category from "./pages/category/Category";
import Wallets from "./pages/wallets/Wallets";
import Notifications from "./pages/notifications/Notifications";
import TransactionDetails from "./pages/transaction/TransactionDetails";
import SearchTransactions from "./pages/transaction/SearchTransactions";
import ReportPage from "./pages/reports/ReportPage";
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import Reminder from "./pages/reminder/Reminder";
import ReminderDetails from "./pages/reminder/ReminderDetails";

function App() {

    const {user: currentUser} = useSelector((state) => state.auth);
    const [left, setLeft] = useState(false);

    const toggleDrawer = () => {
        setLeft(false);
    };

    const openDrawer = () => {
        setLeft(true);
    };

    return (
        <div className="App">
            {
            <BrowserRouter>

            { currentUser &&
                (<div>
                    <ToolbarComponent openDrawerHandler={openDrawer} key="xxx123xx100"/>
                    <DrawerComponent
                        left={left}
                        toggleDrawerHandler={toggleDrawer}
                    />
                </div>)
            }

                <Routes>
                    <Route exact path="/" element={<Home/>}/>
                    <Route path="/Signin" element={<SignIn/>}/>
                    <Route path="/Signup" element={<SignUp/>}/>
                    <Route path="/Profile" element={<Profile/>}/>
                    <Route path="/ReportPage" element={<ReportPage/>}/>
                    <Route path="/Logout" element={<Logout/>}/>
                    <Route path="/Settings" element={<Settings/>}/>
                    <Route path="/Transactions/page/:id" element={<Transaction/>}/>
                    <Route path="/Transactions/:id" element={<TransactionDetails/>}/>
                    <Route path="/Category" element={<Category/>}/>
                    <Route path="/Wallets" element={<Wallets/>}/>
                    <Route path="/Notifications" element={<Notifications/>}/>
                    <Route path="/Search" element={<SearchTransactions/>}/>
                    <Route path="/Reminder/page" element={<Reminder/>}/>
                    <Route path="/Reminder/:id" element={<ReminderDetails/>}/>
                </Routes>
            </BrowserRouter> }
        </div>
    )
}

export default App;
import React, {useState} from "react";
import dayjs from "dayjs";
import MenuItem from "@mui/material/MenuItem";
import {Checkbox, FormControlLabel, FormGroup, FormHelperText, TextField} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {allCategoryList} from "../../store/slices/category";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider, MobileDatePicker, MobileDateTimePicker} from "@mui/x-date-pickers";
import '../../assets/styles/reminder/addReminderDialog.scss';
import moment from "moment";
import {addNewTransaction} from "../../store/slices/transaction";
import {NOTIFICATION_ACTIVE, NOTIFICATION_INACTIVE, TRANSACTION_OPERATION_ADD} from "../../utils/constants";
import {updateTransactionList} from "../../store/slices/transactionStats";
import {addNewReminder, updateReminder} from "../../store/slices/reminder";
import {getNextReminderDate} from "../../utils/utility.service";

const AddReminderDialog = ({addReminderRef, reminderView, isUpdate, onClose, categoryList}) => {

    const [datePicker, setDatePicker] = React.useState(dayjs());
    const {user: userProfile} = useSelector((state) => state.userProfile);
    const dispatch = useDispatch();

    const formInitialValues = {
        category: {
            value: reminderView ? reminderView.categoryId : 0,
            error: false,
            required: true
        },
        title: {
            value: reminderView ? reminderView.title : '',
            error: false,
            required: true
        },
        startDate: {
            value: reminderView ? (new Date(reminderView.startDate)) : datePicker,
            required: false,
            error: false,
        },
        endDate: {
            value: (reminderView) ? (new Date(reminderView.endDate)) : datePicker,
            required: false,
            error: false,
        },
        isNoEndDate: {
            value: (reminderView && reminderView.endDate) ? false : true,
            required: false
        },
        reminderType: {
            value: reminderView ? reminderView.reminderType : '0',
            required: false
        },
        reminderState: {
            value: reminderView ? reminderView.state : '0',
            required: false
        },
        remark: {
            value: reminderView ? reminderView.remark : '',
            error: false,
            required: true
        },
        errorMessage: {
            value: ''
        },
    };

    debugger;
    const [formValues, setFormValues] = useState(formInitialValues);
    const [upcomingReminderDt, setUpcomingReminderDt] = useState(null);

    React.useEffect(() => {
        if (addReminderRef) {
            addReminderRef.current = handleSubmitReminder;
        }
    }, [formValues])

    const handleCloseClick = () => {
        onClose && onClose(this);
    }

    const handleChange = (event) => {

        const {name, value} = event.target;
        const errorValue = value.length == 0;
        console.log("name: " + name + "   val:" + value);

        debugger;
        setFormValues({
            ...formValues,
            [name]: {
                ...formValues[name],
                value,
                error: errorValue
            }
        });


        if (name == 'category' && value) {

            // resetting sub-category options to first selection
            setFormValues({
                ...formValues,
                category: {
                    ...formValues["category"],
                    value,
                    error: value != '0' ? false : true
                }
            });
        }
    }

    const handleStartDateChange = (newValue) => {
        //setDatePicker(newValue);

        setFormValues({
            ...formValues,
            startDate: {
                value: new Date(newValue)
            }
        });
    };
    const handleEndDateChange = (newValue) => {
        //setDatePicker(newValue);

        setFormValues({
            ...formValues,
            endDate: {
                value: new Date(newValue)
            }
        });
    };

    const setErrorMessage = (message) => {

        setFormValues({
            ...formValues,
            errorMessage: {
                value: message
            }
        });
    };

    const setReminderState = (val) => {

        setFormValues({
            ...formValues,
            reminderState: {
                value: val
            }
        });
    };

    const handleChangeCheckBoxNoEndDate = (event) => {

        setFormValues({
            ...formValues,
            isNoEndDate: {
                value: event.target.checked
            }
        });
    }

    const handleSubmitReminder = () => {
        const formFields = Object.keys(formValues);
        let newFormValues = {...formValues}

        debugger;
        let isFormError = false;
        for (let index = 0; index < formFields.length; index++) {
            const currentField = formFields[index];
            const currentValue = formValues[currentField].value;

            if ((currentValue === '' && formValues[currentField].required) ||
                currentValue == '0' && formValues[currentField].required &&
                (currentField == 'category')
            ) {
                isFormError = true;
                newFormValues = {
                    ...newFormValues,
                    [currentField]: {
                        ...newFormValues[currentField],
                        error: true
                    }
                }
            }
        }

        setFormValues(newFormValues);
        if (isFormError) {
            return;
        }

        if (isUpdate) {
            updateReminderEntry();
        } else {
            saveReminderEntry();
        }
    }

    const saveReminderEntry = () => {

        debugger;
        let nextReminderDate = dayjs();

        if (formValues.startDate.value) {
            //let isNextReminderAfterTodaysDate =  dayjs(formValues.startDate.value).isAfter(dayjs());
            let isNextReminderAfterTodaysDate = dayjs(formValues.startDate.value).valueOf() > dayjs().valueOf();
            if (isNextReminderAfterTodaysDate) {
                nextReminderDate = dayjs(formValues.startDate.value);
            } else {

                if (formValues.reminderType.value == '0') {  // 0 : None
                    //this is not allowed because start date was passed(less than current) and there is no repeat option set. hence setting this error
                    setErrorMessage("Error creating reminder, Please either setup repeat or change the start date to setup future reminder");
                    return;
                }
                nextReminderDate = getNextReminderDate(formValues.startDate.value, formValues.endDate.value, formValues.reminderType.value);
            }
        }

        let newReminderView = {
            userId: userProfile.id,
            categoryId: formValues.category.value,
            title: formValues.title.value,
            startDate: formValues.startDate.value,
            reminderType: formValues.reminderType.value,
            endDate: formValues.isNoEndDate.value ? null : formValues.endDate.value,
            isNoEndDate: formValues.isNoEndDate.value,
            state: parseInt(NOTIFICATION_ACTIVE),
            remark: formValues.remark.value,
            upcomingReminder: nextReminderDate
        };

        dispatch(addNewReminder(newReminderView))
            .unwrap()
            .then((response) => {
                console.log("New Reminder added");
                handleCloseClick();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const updateReminderEntry = () => {
debugger

        let nextReminderDate = dayjs();
        let isSetToActive = 0;
        if (formValues.reminderState.value == NOTIFICATION_INACTIVE) {
            let isUNextReminderAfterTodaysDate = dayjs(formValues.startDate.value).valueOf() > dayjs().valueOf();
            if (isUNextReminderAfterTodaysDate) {
                nextReminderDate = dayjs(formValues.startDate.value);
                setReminderState(NOTIFICATION_ACTIVE);
                isSetToActive = 1;
            } else {

                if (formValues.reminderType.value == '0') {  // 0 : None
                    //this is not allowed because start date was passed(less than current) and there is no repeat option set. hence setting this error
                    setErrorMessage("Error creating reminder, Please either setup repeat or change the start date to setup future reminder");
                    return;
                }
                nextReminderDate = getNextReminderDate(formValues.startDate.value, formValues.endDate.value, formValues.reminderType.value);

                if (dayjs(nextReminderDate).valueOf() > dayjs().valueOf()) {
                    setReminderState(NOTIFICATION_ACTIVE)
                    isSetToActive = 1;
                }
            }
        }


        let newReminderView = {
            id: reminderView.id,
            categoryId: formValues.category.value,
            title: formValues.title.value,
            startDate: formValues.startDate.value,
            reminderType: formValues.reminderType.value,
            endDate: formValues.isNoEndDate.value ? null : formValues.endDate.value,
            isNoEndDate: formValues.isNoEndDate.value,
            state: isSetToActive,
            remark: formValues.remark.value,
            upcomingReminder: nextReminderDate
        };

        dispatch(updateReminder(newReminderView))
            .unwrap()
            .then((response) => {
                console.log(" Reminder updated");
                handleCloseClick();
            })
            .catch((err) => {
                console.log(err);
            });
    }


    return (<section className="add-reminder-screen-section">
        <div className="reminder-form-header">

            <TextField
                className="field-title"
                id="outlined-basic"
                label="Reminder title"
                name="title"
                value={formValues.title.value}
                error={formValues.title.error}
                onChange={handleChange}
                inputProps={{maxLength: 40}}
                variant="outlined"/>

            <TextField select label="Select"
                       className="field-category"
                       label="Category"
                       value={formValues.category.value}
                       name="category"
                       error={formValues.category.error}
                       helperText={formValues.category.error && formValues.category.errorMessage}
                       onChange={handleChange}
            >
                <MenuItem value="0"> Category </MenuItem>)
                {
                    categoryList ? categoryList.map((category) => {

                        let image_path = '';
                        try {
                            image_path = require('../../assets/images/' + category.iconUrl + '.png');
                        } catch (err) {
                            image_path = require('../../assets/images/ic_default.png');  //set default image path
                        }
                        return (<MenuItem value={category.id} id={category.id}
                                          key={category.id}><img className="select-category-icon" alt="category-icons"
                                                                 src={image_path}/> {category.title} </MenuItem>)
                    }) : ""
                }
            </TextField>

            <TextField select label="Select"
                       className="field-reminderType"
                       label="Repeat"
                       value={formValues.reminderType.value}
                       name="reminderType"
                       error={formValues.reminderType.error}
                       helperText={formValues.reminderType.error && formValues.reminderType.errorMessage}
                       onChange={handleChange}
            >
                <MenuItem value="0" id="0" key="0"> None</MenuItem>
                <MenuItem value="1" id="1" key="1"> Daily</MenuItem>
                <MenuItem value="2" id="2" key="2"> Weekly</MenuItem>
                <MenuItem value="3" id="3" key="3"> By Weekly</MenuItem>
                <MenuItem value="4" id="4" key="4"> Monthly</MenuItem>
                <MenuItem value="5" id="5" key="5"> Quarterly</MenuItem>
                <MenuItem value="6" id="6" key="6"> Half Yearly</MenuItem>
                <MenuItem value="7" id="7" key="7"> Yearly</MenuItem>
                <MenuItem value="8" id="8" key="8" disabled> Custom</MenuItem>
            </TextField>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDateTimePicker
                    className="field-date"
                    label="Start date"
                    inputFormat="DD/MM/YYYY hh:mm A"
                    value={formValues.startDate.value}
                    onChange={handleStartDateChange}
                    minDate={moment().toDate()}
                    renderInput={(params) => <TextField {...params} error={false}/>}
                />
            </LocalizationProvider>
            <FormGroup className="field-important">
                <FormControlLabel
                    control={<Checkbox name="noEndDate"
                                       checked={formValues.isNoEndDate.value}
                                       value={formValues.isNoEndDate.value}
                    />}
                    label="No end date"
                    onChange={handleChangeCheckBoxNoEndDate}
                />
            </FormGroup>

            {
                !formValues.isNoEndDate.value ?
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDateTimePicker
                            className="field-date"
                            label="End date"
                            inputFormat="DD/MM/YYYY hh:mm A"
                            value={formValues.endDate.value}
                            onChange={handleEndDateChange}
                            minDate={formValues.startDate.value ? formValues.startDate.value : moment().toDate()}
                            renderInput={(params) => <TextField {...params} error={false}/>}
                        />
                    </LocalizationProvider> : ''
            }

            <TextField
                className="field-message"
                id="filled-basic"
                label="Message"
                name="remark"
                inputProps={{maxLength: 200}}
                value={formValues.remark.value}
                onChange={handleChange}
                multiline
                rows={2}
                error={formValues.remark.error}
                variant="outlined"/>
            <div className="footer-info">
                {
                    formValues.errorMessage.value ?
                        <span>
                         <b>Error: </b> {formValues.errorMessage.value}
                        </span> : ''
                }

            </div>

        </div>
    </section>);
}

export default AddReminderDialog;
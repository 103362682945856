import axios from "axios";
import {DOMAIN_NAME} from "../utils/constants";

const API_URL = DOMAIN_NAME + "/api/auth/";

const signup = (firstName, lastName, email, password) => {
    console.log("signup axios");
    return axios.post(API_URL + "signup", {
        firstName,
        lastName,
        email,
        password});
};

const signin = (username, password, firstName, lastName, email, pictureUrl, signInSource, uniqueId) => {
    console.log("signin axios");
    return axios.post(API_URL + "signin", {
        username,
        password,
        firstName,
        lastName,
        email,
        pictureUrl,
        signInSource,
        uniqueId
    })
        .then( (response) => {
            saveToken(response);
            return response.data;
        });
};

const saveToken = (response) => {
    if(response && response.data && response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
    }
}

const logout = () => {
    console.log("Logout successful");
    localStorage.removeItem("user");
}

const authService = {
    signup,
    signin,
    logout
};

export default authService;
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import ReminderService from "../../services/reminder.service";
import {setAllReminderList, setReminder} from "./reminder";
import NotificationService from "../../services/notification.service";
import {getActiveNotificationCount} from "../../utils/utility.service";
import {setMessage} from "./message";
import {NOTIFICATION_ACTIVE} from "../../utils/constants";


export const getAllNotification = createAsyncThunk(
    "notification/getAllNotification",
    async ({userId}, thunkAPI) => {
        try {
            const response = await NotificationService.getAllNotificationList(userId);
            thunkAPI.dispatch(setAllNotificationList(response.data));
            let activeNotificationCount = response.data.length > 0 ? getActiveNotificationCount(response.data) : 0;
            console.log("active notify: " + activeNotificationCount);
            return activeNotificationCount;
        } catch (error) {
            console.log("direct error, it may token expired");
            if (error.response) {
                return error.response;
            }
            console.log(error);
        }
    }
)

export const notificationCompleted = createAsyncThunk(
    "notification/getAllNotification",
    async ({notificationObj}, thunkAPI) => {
        try {
            const response = await NotificationService.notificationMarkDone(notificationObj.id);
            if (response && response.data.status == "SUCCESS") {
                let newNotificationObj = Object.assign({}, notificationObj);  // creating copy of state variable jasper
                newNotificationObj.state = 0;
                thunkAPI.dispatch(setNotification(newNotificationObj));
                return "SUCCESS";
            }
            return "FAILED";
        } catch (error) {
            console.log("direct error, it may token expired");
            if (error.response) {
                return error.response;
            }
            console.log(error);
        }
    }
)

const initialState = {
    activeNotificationCount: 0,
    activeNotificationColor: "red",
    notificationList: null
};

const notificationSlice = createSlice({
    name: "notification",
    initialState,
    reducers: {
        setAllNotificationList: (state, action) => {
            state.notificationList = action.payload;

            let count = 0
            state.notificationList.map((notify) => {
                if (notify.state == NOTIFICATION_ACTIVE)
                    count++;
            });
            state.activeNotificationCount = count;
        },
        setNotification: (state, action) => {
            debugger
            if (action.payload.id) {
                debugger;
                let index = state.notificationList ? state.notificationList.findIndex(rem => rem.id === action.payload.id) : -1;
                if (index != -1) {

                    let newArray = [...state.notificationList];
                    newArray[index] = action.payload;

                    state.notificationList = newArray;
                    state.activeNotificationCount = parseInt(state.activeNotificationCount) - 1;
                    console.log("notification count:" + state.activeNotificationCount);
                } else if (state.notificationList == undefined) {
                    state.notificationList = [action.payload];
                    state.activeNotificationCount = parseInt(state.activeNotificationCount) + 1;
                }
            } else
                state.notificationList = [...state.notificationList, action.payload];
        },
        updateNotification: (state, action) => {

            // Delete and update action
            if (action.payload.id) {
                debugger;
                let index = state.notificationList ? state.notificationList.findIndex(rem => rem.id === action.payload.id) : -1;
                if (index != -1) {
                    let newArray = [...state.notificationList];
                    newArray.splice(index, 1);
                    state.notificationList = newArray;
                } else if (state.notificationList == undefined) {
                    state.notificationList = [action.payload];
                }
            }
        }
    }
})

export const allNotificationList = (state) => {
    return state.notification;
}

const {reducer, actions} = notificationSlice;

export const {setAllNotificationList, setNotification, updateNotification} = actions;
export default reducer;
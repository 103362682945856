import {LocalizationProvider, MobileDatePicker} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {Checkbox, FormControlLabel, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import React, {useState} from "react";
import {getDefaultWallet} from "../../utils/utility.service";
import {addNewWallet, editWallet} from "../../store/slices/wallet";
import {useDispatch, useSelector} from "react-redux";
import {currentUserProfile} from "../../store/slices/userProfile";


const AddWallet = ({walletRef, onClose, updateWallet }) => {

    const formInitialValues = {
        title: {
            value: updateWallet ? updateWallet.title : '',
            error: false,
            errorMessage: 'Enter wallet title',
            required: true
        },
        balance: {
            value: updateWallet ? updateWallet.balance : 0,
            error: false,
            errorMessage: 'Enter wallet balance',
            required: true
        },
        isDefault: {
            value: updateWallet ? updateWallet.isDefault : false,
            disable: updateWallet ? updateWallet.isDefault : false
        }
    };

    const [formValues, setFormValues] = useState(formInitialValues);
    const {user: currentUser} = useSelector(currentUserProfile);
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (walletRef) {
            walletRef.current = handleSubmitWallet;
        }
    }, [formValues])

    const handleChange = (event) => {

        const {name, value} = event.target;
        const errorValue = value.length == 0;

        setFormValues({
            ...formValues,
            [name]: {
                ...formValues[name],
                value,
                error: errorValue
            }
        });
    };

    const handleChangeCheckBoxDefault = (event) => {

        setFormValues({
            ...formValues,
            isDefault: {
                value: event.target.checked
            }
        });
    }

    const handleCloseClick = () => {
        onClose && onClose(this);
    }

    const handleSubmitWallet = () => {

        const walletFields = Object.keys(formValues);
        let newWalletValues = {...formValues}

        let isFormError = false;
        for (let index = 0; index < walletFields.length; index++) {
            const currentField = walletFields[index];
            const currentValue = formValues[currentField].value;

            if(currentValue === ''){
                isFormError = true;
                newWalletValues = {
                    ...newWalletValues,
                    [currentField]:{
                        ...newWalletValues[currentField],
                        error:true
                    }
                }
            }
        }

        setFormValues(newWalletValues)
        if(isFormError) {
            return;
        }

        if(updateWallet) {
            dispatch(editWallet({
                id: updateWallet.id,
                title: formValues.title.value,
                balance: formValues.balance.value,
                isDefault: formValues.isDefault.value,
                userId: currentUser.id
            })).unwrap()
                .then((response) =>{
                    handleCloseClick();
                }).catch((err) => {
                console.log(err);
            });
        } else {

            dispatch(addNewWallet({
                title: formValues.title.value,
                balance: formValues.balance.value,
                isDefault: false,
                userId: currentUser.id
            })).unwrap()
                .then((response) =>{
                    handleCloseClick();
                }).catch((err) => {
                console.log(err);
            });
        }
    }

    return (<div>
        <Box
            component="form"
            sx={{
                '& > :not(style)': {m: 1, width: '20ch'},
            }}
            noValidate
            autoComplete="off">

            <TextField
                id="filled-basic"
                label="Wallet title"
                name="title"
                value={formValues.title.value}
                onChange={ handleChange }
                error={formValues.title.error}
                helperText={formValues.title.error && formValues.title.errorMessage}
                variant="outlined"/>

            <TextField
                id="filled-basic"
                label="Initial balance"
                type="number"
                name="balance"
                value={formValues.balance.value}
                onChange={ handleChange }
                error={formValues.balance.error}
                helperText={formValues.balance.error && formValues.balance.errorMessage}
                variant="outlined"/>

        </Box>

        {
            updateWallet ?
                <Box
                    component="form"
                    sx={{
                        '& > :not(style)': {m: 1, width: '20ch'},
                    }}
                    noValidate
                    autoComplete="off">

                    <FormControlLabel
                        control={<Checkbox name="isDefault"
                                           disabled={formValues.isDefault.disable}
                                           checked={formValues.isDefault.value}
                                           value={formValues.isDefault.value}
                        />}
                        label="Make default wallet"
                        onChange={ handleChangeCheckBoxDefault }
                    />

                </Box> : ''
        }

    </div>)
}

export default AddWallet;
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import UserService from "../../services/user.service";
import {ERROR_BAD_REQUEST} from "../../utils/constants";
import {logout} from "./auth";
import SettingService from "../../services/setting.service";
import CategoryService from "../../services/category.service";
import {setMessage} from "./message";
import {setCategory} from "./category";


export const getUserInfo = createAsyncThunk(
    "auth/userinfo",
    async (unUsedArg,thunkAPI) => {
        try {
            const response = await UserService.getUserInfo();
            if(response && response.data) {
                thunkAPI.dispatch(setMessage(response.data.message));
                thunkAPI.dispatch(setUserProfile(response.data));
                console.log(response.data);
                return response.data;
            }
        } catch (error) {
            console.log("Error while loading user information");
            if(error.response && error.response.status == ERROR_BAD_REQUEST) {
                thunkAPI.dispatch(logout());
            }
            console.log(error);
        }
    }
)

export const addUserSetting = createAsyncThunk(
    "userSettings/addUserSetting",
    async ({userId, currency, lastLogin, userTimezone}, thunkAPI) => {

        try{
            const response = await SettingService.addUserSetting(userId, currency, lastLogin, userTimezone);

            if(response && response.data) {
                thunkAPI.dispatch(setMessage(response.data.message));
                thunkAPI.dispatch(setUserSetting(response.data));
                return response.data;
            }
        } catch (error) {
            console.log(error);
            const message =
                (error.response && error.response.data && error.response.data.errorMessage) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);

export const updateUserSetting = createAsyncThunk(
    "userSettings/updateUserSetting",
    async ({userId, currency, lastLogin}, thunkAPI) => {

        try{
            let response = undefined;
            if(currency !== undefined && currency !== '') {
                response = await SettingService.updateCurrency(userId, currency);
            }else if(lastLogin !== undefined && lastLogin !== '') {
                response = await SettingService.updateLastLogin(userId, lastLogin);
            }

            if(response && response.data) {
                thunkAPI.dispatch(setMessage(response.data.message));
                return response.data;
            }
        } catch (error) {
            console.log(error);
            const message =
                (error.response && error.response.data && error.response.data.errorMessage) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);

export const getUserSettings = createAsyncThunk(
    "userSettings/getUserSettings",
    async ({userId},thunkAPI) => {
        try {
            const response = await SettingService.getUserSettings(userId);
            thunkAPI.dispatch(setUserSetting(response.data));
            console.log(response.data);
            return response.data;
        } catch (error) {
            console.log("Error while loading user setting info");
            if(error.response && error.response.status == ERROR_BAD_REQUEST) {
                thunkAPI.dispatch(logout());
            }
            console.log(error);
        }
    }
)

const initialState = {};

const userProfileSlice = createSlice({
    name: "userProfile",
    initialState: {
        user: null,
        settings: null
    },
    reducers: {
        setUserProfile: (state, action) => {
            state.user = action.payload;
            //return { user: action.payload };
        },
        setUserSetting: (state, action) => {
            state.settings = action.payload;
        }
    }
});

export const currentUserProfile = (state) => {
    console.log(state.userProfile);
    return state.userProfile;
};

const { reducer, actions } = userProfileSlice;

export const { setUserProfile, setUserSetting } = actions;

export default reducer;
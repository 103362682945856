import AddTransactionDialog from "../../components/transaction/AddTransactionDialog";
import RecentTransactions from "../../components/transaction/RecentTransactions";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {allTransactionStats} from "../../store/slices/transactionStats";
import {DEFAULT_CURRENCY, LAST_MONTH_TRANSACTION, THIS_MONTH_TRANSACTION} from "../../utils/constants";
import MonthlyView from "../../components/transaction/MonthlyView";
import React, {useEffect, useState} from "react";
import '../../assets/styles/transactions/transactionPage.scss'


const Transaction = () => {

    const { id } = useParams();
    const { lastMonthList, currentMonthList, lastMonthTotalSpent, currentMonthTotalSpent  } = useSelector(allTransactionStats);
    const {settings: userSettings} = useSelector((state) => state.userProfile);

    const [transactionView, setTransactionView] = useState(THIS_MONTH_TRANSACTION);
    const [transactionList, setTransactionList] = useState(currentMonthList);

    useEffect(()=>{

        if(id == LAST_MONTH_TRANSACTION ){
            setTransactionView(LAST_MONTH_TRANSACTION);
            setTransactionList(lastMonthList);
        } else {
            setTransactionView(THIS_MONTH_TRANSACTION);
            setTransactionList(currentMonthList);
        }

    }, [])

    return (
        <div className="transaction-page-container header-top-padding">
            <div className="monthly-stats">

                {
                    transactionView == LAST_MONTH_TRANSACTION ?
                        <MonthlyView
                            monthName="Last month"
                            totalSpent={ lastMonthTotalSpent }
                            currency={userSettings ? userSettings.currency : DEFAULT_CURRENCY }
                        /> :
                        <MonthlyView
                            monthName="This month"
                            totalSpent={ currentMonthTotalSpent }
                            currency={userSettings ? userSettings.currency : DEFAULT_CURRENCY }
                        />
                }

            </div>
            {
                transactionList ? transactionList.length > 0 ? <RecentTransactions transactionList={ transactionList }/> :
                    <span className="no-recent-transaction">No transactions</span> : ''
            }

        </div>
    )
}

export default Transaction;
import {Component} from "react";
import {Select, TextField} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import {connect} from "react-redux"
import {camelCase} from "../../utils/utility.service";
import {addNewTransaction} from "../../store/slices/transaction";
import {addNewWallet, getAllWalletByUser} from "../../store/slices/wallet";
import {addUserSetting} from "../../store/slices/userProfile";
import moment from "moment";

class StepTwo extends Component {

    constructor(props) {
        super(props);

        let {user} = props.user;

        this.state = {

            title: {
                value: user ? camelCase(user.firstName) + " Wallets" : '',
                error: false,
                errorMessage: 'Enter wallet title',
                required: true
            },
            balance: {
                value: 0,
                error: false,
                errorMessage: 'Enter balance',
                required: false
            }
        };
        this.handleChange = this.handleChange.bind(this);
    };

    componentDidMount() {
        this.props.onRef(this);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    handleChange(event) {

        const {name, value} = event.target;
        const errorValue = value.length == 0;

        this.setState({
            ...this.state,
            [name]: {
                ...this.state[name],
                value,
                error: errorValue
            }
        });
    };

    handleCreateWallet(event) {
        console.log("Create wallet btn click");
        if (this.state.title.value == '') {
            this.setState({
                ...this.state,
                'title': {
                    ...this.state.title,
                    error: true
                }
            });

            return false;
        }

        const { user } = this.props.user;
        this.props.createWallet({
            title: this.state.title.value,
            balance: this.state.balance.value,
            isDefault: true,
            userId: user.id
        }).then((response) => {
            if(response && response.payload && response.payload.status === "SUCCESS") {
                // get wallet list
                this.props.getAllWallet({userId: user.id});
            }
            console.log(response);
        }).catch((err) => {
            console.log(err);
        });

        this.props.createUserSettings({
            userId: user.id,
            currency: '$',
            lastLogin: moment(),
            userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }).then((response) =>{
            console.log(response);
        }).catch((err) => {

        });
    }

    render() {
        if (this.props.currentStep !== 2) {
            return null;
        }

        return (
            <div className="step_two_wallet_creation">

                <div>

                    <p>
                        Create your wallet with below fields:
                    </p>

                </div>
                <Box
                    component="form"
                    sx={{
                        '& > :not(style)': {m: 1, width: '20ch'},
                    }}
                    noValidate
                    autoComplete="off">
                    <TextField
                        id="filled-basic"
                        label="Wallet title"
                        name="title"
                        value={this.state.title.value}
                        onChange={this.handleChange}
                        error={this.state.title.error}
                        helperText={this.state.title.error && this.state.title.errorMessage}
                        variant="outlined"/>

                </Box>

                <Box
                    component="form"
                    sx={{
                        '& > :not(style)': {m: 1, width: '20ch'},
                    }}
                    noValidate
                    autoComplete="off">

                    <TextField
                        id="filled-basic"
                        label="Balance"
                        type="number"
                        name="balance"
                        value={this.state.balance.value}
                        onChange={this.handleChange}
                        error={this.state.balance.error}
                        helperText={this.state.balance.error && this.state.balance.errorMessage}
                        variant="outlined"/>
                </Box>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.userProfile,
})

const mapDispatchToProps = dispatch => {
    return {
        createWallet: ({title, balance, isDefault, userId}) => {
            return dispatch(addNewWallet({title, balance, isDefault, userId}))
        },
        createUserSettings : ({ userId, currency, lastLogin, userTimezone}) => {
            return dispatch(addUserSetting({ userId, currency, lastLogin, userTimezone}))
        },
        getAllWallet: ({ userId}) => {
            return dispatch(getAllWalletByUser({ userId}))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StepTwo)
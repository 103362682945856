import {useDispatch} from "react-redux";
import '../../assets/styles/notification/notificationItem.scss';
import RepeatIcon from "@mui/icons-material/Repeat";
import dayjs from "dayjs";
import React from "react";
import {getCategoryObject} from "../../utils/utility.service";
import {Link} from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import Badge from "@mui/material/Badge";

const NotificationsItem = ({notificationView, categoryList, lastItem, onClick}) => {

    let categoryObj = getCategoryObject(notificationView.categoryId, categoryList);

    let image_path = '';
    try {
        image_path = require('../../assets/images/' + categoryObj.iconUrl + '.png');
    } catch (err) {
        image_path = require('../../assets/images/ic_default.png');  //set default image path
    }

    let alertTime = "";
    const isYesterday = dayjs(notificationView.insertDate).isSame(dayjs().subtract(1, 'day'))
    const isToday = dayjs(notificationView.insertDate).isSame(dayjs()); // dayjs()

    alertTime = isYesterday ? "Yesterday" : isToday ? "Today" : dayjs(notificationView.insertDate).format('DD-MMM');

    return (<div
        onClick={onClick}
        className={`notificationItem-section ${!lastItem ? 'divider-item' : ''} ${!notificationView.state ? 'notificationItem-inactive' : ''}`}
        key={notificationView.id}>

        {/*<span>Title : {notificationView.title}</span><br/>
                <span>State : {notificationView.state}</span><br/>
                <span>Message : {notificationView.remark}</span><br/><br/>*/}


        <div className="notification-item-header">
            <div className="category-icon">
                <img alt="category-icons" src={image_path}/>
                {
                    notificationView.state ? <NotificationsActiveIcon className="notify-icon nofity-active"/> :
                        <NotificationsOffIcon className="notify-icon nofity-inactive"/>
                }


            </div>

            <div className="notification-main-div">
                <div className="notification-title">
                    <span className="">{notificationView.title} </span>
                    <div className="category-section">
                        <span className="category-title">{categoryObj.title} </span>
                    </div>
                </div>


                <div className="notification-other-details">
                    <div className="date-section">
                            <span
                                className="notification-date">{ alertTime }</span>
                    </div>
                </div>
            </div>
        </div>


    </div>)
}

export default NotificationsItem
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import CategoryService from "../../services/category.service";
import {setMessage} from "./message";
import {ERROR_BAD_REQUEST, ERROR_NO_WALLET_FOUND, TRANSACTION_TYPE_WITHDRAW} from "../../utils/constants";
import {logout} from "./auth";
import WalletService from "../../services/wallet.service";

export const addNewWallet = createAsyncThunk(
    "wallet/addNewWallet",
    async ({title, balance, isDefault, userId}, thunkAPI) => {
        try {
            const response = await WalletService.addUserWallet(title, balance, isDefault, userId);
            thunkAPI.dispatch(setMessage(response.data.message));
            return response.data;
        } catch (error) {
            console.log(error);
            const message =
                (error.response && error.response.data && error.response.data.errorMessage) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);

export const editWallet = createAsyncThunk(
    "wallet/updateWallet",
    async ({id, title, balance, isDefault, userId}, thunkAPI) => {
        try {
            const response = await WalletService.updateWallet(id, title, balance, isDefault, userId);
            thunkAPI.dispatch(setMessage(response.data.message));
            return response.data;
        } catch (error) {
            console.log(error);
            const message =
                (error.response && error.response.data && error.response.data.errorMessage) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);

export const updateWalletBalance = createAsyncThunk(
    "wallet/updateWalletBalance",
    async ({amount, transactionType}, thunkAPI) => {
        try {
            let walletOperation = {amount, transactionType}
            thunkAPI.dispatch(setWalletBalance(walletOperation));
            return null;
        } catch (error) {
            console.log(error);
            const message =
                (error.response && error.response.data && error.response.data.errorMessage) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);

export const getAllWalletByUser = createAsyncThunk(
    "wallet/getAllUserWallet",
    async ({userId}, thunkAPI) => {
        try {
            const response = await WalletService.getAllWalletByUser(userId);

            if (response.data) {

                let defaultWallet = response.data.filter((wallet) => wallet.isDefault)[0];
                //thunkAPI.dispatch(setDefaultWallet(defaultWallet));
                thunkAPI.dispatch(setDefaultWallet(defaultWallet));

                thunkAPI.dispatch(setAllWallet(response.data));
            }

            return response.data;
        } catch (error) {
            console.log("Error occured while getting all user wallets");
            if (error.response &&
                error.response.data) {
                console.log("No wallet found")
                return error.response.data;
            }
            console.log(error);
        }
    }
)

const initialState = {};

const walletSlice = createSlice({
    name: "wallet",
    initialState: {
        wallet: null,
        defaultWallet: null
    },
    reducers: {
        setAllWallet: (state, action) => {
            state.wallet = action.payload;
            //return { walletList: action.payload };
        },
        setDefaultWallet: (state, action) => {
            state.defaultWallet = action.payload;
            //return { defaultWallet : action.payload };
        },
        setWalletBalance: (state, action) => {

            if (state.defaultWallet) {
                let walletObj = Object.assign({}, state.defaultWallet);
                if (action.payload.transactionType == TRANSACTION_TYPE_WITHDRAW) {
                    walletObj.totalSpentSoFar = Number(walletObj.totalSpentSoFar) - Number(action.payload.amount);
                } else {
                    walletObj.totalSpentSoFar = Number(walletObj.totalSpentSoFar) + Number(action.payload.amount);
                }
                state.defaultWallet = walletObj;
            }
        }

    }
})

export const allWalletList = (state) => {
    return state.wallet;
}

const {reducer, actions} = walletSlice;

export const {setAllWallet, setDefaultWallet, setWalletBalance} = actions;
export default reducer;
import {Component} from "react";
import React from "react";
import '../assets/styles/DialogStyle.scss'

import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider
} from "@mui/material";


export default class SimpleDialog extends Component {


  constructor(props) {
        super(props);
        this.state = {
            isPositiveButtonClick: false,
            isProgressInvoked: false
        };
    }

    static getDerivedStateFromProps(props, state) {
        if(props.show && props.positiveBtnProgress && !state.isProgressInvoked) {
            return {
                isPositiveButtonClick:true,
                isProgressInvoked:true
            };
        } else if(!props.show && state.isProgressInvoked) {

            return {
                isPositiveButtonClick:false,
                isProgressInvoked:false
            };
        }
        //console.log("show:"+props.show + " invoked:"+state.isProgressInvoked + " prgres: "+state.isPositiveButtonClick);
        return null;
    }
    handleCloseClick = (e) => {
        document.body.classList.remove('modal-open');
        this.props.onClose && this.props.onClose(e);
    }

    handlePositiveClick = (e) => {
        document.body.classList.remove('modal-open');
        this.props.positiveClick && this.props.positiveClick(e);
    }

    render() {

        if (!this.props.show) {
            return null;
        }

        const {title, subtitle, positiveBtn, negativeBtn, isPositiveBtnDisable} = this.props;

        //document.body.classList.add('modal-open');

        return (
            <Dialog className="modal-container modal-main"
                    title={title}
                    modal={ "true" }
                    disableEnforceFocus
                    open={this.props.show}
            >
                <DialogTitle id="edit-apartment">{title}</DialogTitle>
                <Divider/>
                <DialogContent>
                    <DialogContentText>
                        {subtitle}
                    </DialogContentText>

                    {this.props.children}

                </DialogContent>

                <DialogActions>
                    {
                        !this.state.isPositiveButtonClick ?
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={this.handleCloseClick}>
                                {negativeBtn}
                            </Button> : ''
                    }

                    {
                        isPositiveBtnDisable ? '':
                            <Button
                                color="primary"
                                variant="contained"
                                disabled={ this.state.isPositiveButtonClick }
                                onClick={this.handlePositiveClick}>
                                {
                                    this.state.isPositiveButtonClick ?
                                        <CircularProgress className="circularProgress" size={15}/> : ''
                                }

                                {positiveBtn}
                            </Button>
                    }
                </DialogActions>
            </Dialog>
        )
    }
}
import SimpleDialog from "../../components/SimpleDialog";
import AddTransactionDialog from "../../components/transaction/AddTransactionDialog";
import React, {useEffect, useState} from "react";
import AddReminderDialog from "./AddReminderDialog";
import {Button} from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import {useDispatch, useSelector} from "react-redux";
import {allCategoryList, getAllCategory} from "../../store/slices/category";
import {allSubCategoryList, getAllSubCategory} from "../../store/slices/subCategory";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import '../../assets/styles/reminder/reminderPage.scss';
import {allReminderList, getAllReminder} from "../../store/slices/reminder";
import ReminderItem from "./ReminderItem";

const Reminder = () => {

    const [addReminderModal, setAddReminderModal] = useState(false);
    const [isUpdate, setUpdate] = useState(false);
    const [selectedReminderView, setSelectedReminderView] = useState(false);
    const addReminderRef = React.useRef(null);
    const {categoryList} = useSelector(allCategoryList);
    const {reminderList} = useSelector(allReminderList);
    const {user: userProfile} = useSelector((state) => state.userProfile);
    const dispatch = useDispatch();

    useEffect(() => {
        debugger;
        if (!reminderList && userProfile) {
            dispatch(getAllReminder({userId: userProfile.id}));
        }
    }, [userProfile]);

    const toggleAddReminderModal = () => {
        setAddReminderModal(!addReminderModal);

        if (addReminderModal) {
            // while closing pop-up
            setSelectedReminderView(null);
        }
    }

    const btnClickAddNewReminder = () => {
        setUpdate(false);
        toggleAddReminderModal();
    }

    const handleListClick = (value) => () => {
        debugger;
        if (value) {
            setUpdate(true);
            setSelectedReminderView(value);
            toggleAddReminderModal();
        }
    };

    const handleDeleteClick = (value) => () => {
        console.log("delete click --->" + value);
    };

    return (<section className="reminder-page-section header-top-padding">

        <div className="main-reminder-section">
            <span className="heading">Reminders List</span>
            <span className="description col-sm-1 col-md-4">You can set up a new reminder here using the button below.
                Once the reminder is set, you will receive notifications in the Notification panel,
                as well as an email to your registered email address.</span>
            <Button className="add-reminder-btn" variant="contained" startIcon={<AddCircleOutlineIcon/>}
                    onClick={btnClickAddNewReminder}>
                Setup Reminder
            </Button>
            <div className="reminder-all-items">
                {
                    (reminderList && reminderList.length > 0) ?
                        reminderList.map((reminder, index) => {
                            return (
                                <div key={reminder.id}>
                                    <ReminderItem
                                        reminderView={reminder}
                                        categoryList={categoryList}
                                        deleteClick={handleDeleteClick(reminder)}
                                        lastItem={ index == (reminderList.length-1)}
                                        onClick={handleListClick(reminder)}/>
                                </div>);
                        }) :
                        <span>
                            No Reminder set yet
                        </span>

                }
            </div>
        </div>
        <SimpleDialog
            title="Setup reminder"
            subtitle="Add below details to setup the alert notification:"
            positiveBtn={isUpdate ? "Update" : "Save"}
            negativeBtn="Cancel"
            onClose={toggleAddReminderModal}
            positiveClick={() => addReminderRef.current()}
            show={addReminderModal}>
            {(<div>
                <AddReminderDialog
                    reminderView={selectedReminderView}
                    addReminderRef={addReminderRef}
                    onClose={toggleAddReminderModal}
                    isUpdate={isUpdate}
                    categoryList={categoryList}
                />
            </div>)}
        </SimpleDialog>
    </section>);
}

export default Reminder;
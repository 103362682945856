import axios from "axios";
import authHeader from "./auth-header";
import {DOMAIN_NAME} from "../utils/constants";

const API_URL = DOMAIN_NAME + "/api/setting/";

const addUserSetting = (userId, currency, lastLogin, userTimezone) => {
    return axios.post( API_URL + "add/", {
        userId, currency, lastLogin, userTimezone
    }, { headers: authHeader() })
};

const updateLastLogin = (userId, lastLogin) => {
    return axios.post( API_URL + "lastlogin/", {
        userId, lastLogin
    }, { headers: authHeader() })
};

const updateCurrency = (userId, currency) => {
    return axios.post
    ( API_URL + "currency/", {
        userId, currency
    }, { headers: authHeader() })
};

const getUserSettings = (userId) => {
    console.log("getAllCategory axios");
    return axios.get(API_URL + userId , { headers: authHeader() });
};

const settingService = {
    addUserSetting,
    updateLastLogin,
    updateCurrency,
    getUserSettings
};

export default settingService
import {useDispatch, useSelector} from "react-redux";
import {
    allTransactionList,
    deleteTransaction,
    getRecentTransaction,
    setLatestTransactionHistory
} from "../../store/slices/transaction";
import React, {useEffect, useState} from "react";
import {
    Avatar,
    Button,
    Card,
    CircularProgress,
    Divider
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {getDefaultWallet, getTitleFromCategory, isBothDatesDifferent} from "../../utils/utility.service";
import {allCategoryList, getAllCategory} from "../../store/slices/category";
import {allSubCategoryList, getAllSubCategory} from "../../store/slices/subCategory";
import dayjs from "dayjs";
import '../../assets/styles/transactions/recentTransactions.scss'
import Box from "@mui/material/Box";
import StarIcon from '@mui/icons-material/Star';
import AddTransactionDialog from "./AddTransactionDialog";
import SimpleDialog from "../SimpleDialog";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import TransactionItem from "./TransactionItem";
import {Route, Link, Routes} from 'react-router-dom';
import {allTransactionStats} from "../../store/slices/transactionStats";
import {DEFAULT_CURRENCY} from "../../utils/constants";

const RecentTransactions = ( { transactionList }) => {

    // load all existing list from redux
    //const { lastFewDaysList: transactionList } = useSelector(allTransactionStats);
    const {categoryList} = useSelector(allCategoryList);
    const {subCategoryList} = useSelector(allSubCategoryList);
    const {user: userProfile} = useSelector((state) => state.userProfile);
    const {settings: userSettings} = useSelector((state) => state.userProfile);
    const dispatch = useDispatch();
    const {defaultWallet: walletList} = useSelector((state) => state.wallet);
    // setting up the state
    const [transactionView, setTransactionView] = React.useState(null);
    const [editModal, setEditModal] = useState(false);
    const [deleteModalConfirmation, setDeleteModalConfirmation] = useState(false);
    const addTransactionReference = React.useRef(null);
    const transactionViewReference = React.useRef(null);

    useEffect(() => {
        if (!categoryList && userProfile) {
            dispatch(getAllCategory({ userId:userProfile.id}));
        }
        if (!subCategoryList && userProfile) {
            dispatch(getAllSubCategory({ userId:userProfile.id}));
        }
    }, [userProfile]);

    const [checked, setChecked] = React.useState([0]);
    const handleListClick = (value) => () => {
        /*const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);*/
        if (value) {
            //console.log(transactionList.find(transaction => transaction.id == value))
            setTransactionView(transactionList.find(transaction => transaction.id == value));
        }
    };

    const closeTransactionView = () => {
        setTransactionView(null);
    }

    const toggleEditModal = () => {
        setEditModal(!editModal);
    }

    const toggleDeleteModalConfirmation = () => {
        setDeleteModalConfirmation(!deleteModalConfirmation);
    }

    const handleDeleteTransaction = () => {

        dispatch(deleteTransaction({id: transactionView.id}))
            .unwrap()
            .then(() => {
                toggleDeleteModalConfirmation();

                setTimeout(() => {
                    let indexFound = transactionList.findIndex(trans => trans.id === transactionView.id);

                    Object.freeze(transactionList);

                    const newTransactionList = [...transactionList];
                    newTransactionList.splice(indexFound, 1); // deleting one element
                    dispatch(setLatestTransactionHistory(newTransactionList))
                    //$('body').removeClass('modal-open');

                    setTransactionView(null);
                })

            })
            .catch((err) => {
                console.log(err);
            })
    }

    const updateInfo = (updatedTransaction) => {
        setTransactionView(updatedTransaction);
        //let wallet = getDefaultWallet(walletList);
        //dispatch(getRecentTransaction({walletId: wallet.id}));
    }

    const editTransaction = () => {
        setEditModal(!editModal);
    }

    const getSubCategoryTitle = (id, list, spent) => {

        let title = getTitleFromCategory(id, list);

        if (title === 'Other' || title === 'other') {
            return spent ? spent : title;
        }

        return title;
    }

    return (
        <div className="recent-transaction-section">
            <Card sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}>

                {
                    !transactionList ?
                        <Box sx={{display: 'flex', justifyContent: 'center', paddingTop: '2em'}}>
                            <CircularProgress/>
                        </Box> :
                        transactionList.map((transaction, index) => {

                            let displayDateHeader = true;
                            if (transaction.dateHeaderTotalAmount >=0) {
                                displayDateHeader = true;
                            } else {
                                displayDateHeader = false;
                            }
                            return (
                                <div key={ transaction.id }>

                                        <TransactionItem
                                            onClick={handleListClick(transaction.id)}
                                            transaction={transaction}
                                            categoryList={categoryList}
                                            isDateHeader={displayDateHeader}
                                            subCategoryTitle={getSubCategoryTitle(transaction.subCategoryId, subCategoryList, transaction.spendAt)}
                                            currency={userSettings ? userSettings.currency : DEFAULT_CURRENCY }
                                        />

                                </div>
                            )
                        })
                }
            </Card>

{/*            {
                transactionView ?
                    <TransactionView
                        transactionView={ transactionView }
                        categoryList = {categoryList}
                        subCategoryList = {subCategoryList}
                    /> : ""
            }*/}


            {
                transactionView ?
                    <Card className="editTransaction">
                        <div className="header">
                            <span>Transaction details: </span>
                            <Button className="button-close" onClick={closeTransactionView}
                                    style={{color: "#484747"}}><ClearIcon/></Button>
                        </div>
                        <Divider/>
                        <div className="content">

                            <div className="content-details-left">
                                <div className="logo">
                                    <Avatar alt="M" src="/static/images/avatar/1.jpg"/>
                                </div>
                                <div className="transaction-details">
                                    <span
                                        className="category"> {getTitleFromCategory(transactionView.categoryId, categoryList)} </span>
                                    <span
                                        className="sub-category"> {getSubCategoryTitle(transactionView.subCategoryId, subCategoryList, transactionView.spendAt)}</span>
                                    <span className="note">{transactionView.note}</span>
                                    <span
                                        className="date">Date: {dayjs(transactionView.paidOn).format('YYYY-MM-DD')}</span>
                                    <Divider/>
                                    <span className="amount">-Rs {transactionView.amount}</span>
                                </div>
                            </div>
                            <div className="content-details-right">
                                <span className="wallet">Manish wallets</span>
                                {transactionView.isImportant ? <StarIcon className="important"/> : ""}

                                <div className="action-buttons">
                                    <Divider/>
                                    <Button className="delete-transaction"
                                            variant="text"
                                            startIcon={<DeleteIcon/>}
                                            style={{color: "red"}}
                                            onClick={toggleDeleteModalConfirmation}>
                                        Delete
                                    </Button>

                                    <Button className="edit-transaction"
                                            variant="text"
                                            startIcon={<ModeEditIcon/>}
                                            style={{color: "green"}}
                                            onClick={editTransaction}>
                                        Edit
                                    </Button>

                                </div>
                            </div>
                        </div>
                    </Card> : ""
            }

            <SimpleDialog
                title="Edit transaction"
                subtitle="Please update the transaction details below :"
                positiveBtn="Save"
                negativeBtn="Cancel"
                onClose={toggleEditModal}
                positiveClick={() => addTransactionReference.current()}
                show={editModal}>
                {(<div>
                    <AddTransactionDialog ref={addTransactionReference}
                                          transactionView={transactionView}
                                          isUpdate="true"
                                          updateInfo={updateInfo}
                                          onClose={toggleEditModal}
                    />
                </div>)}
            </SimpleDialog>

            <SimpleDialog
                title="Delete confirmation"
                positiveBtn="Yes"
                negativeBtn="No"
                onClose={toggleDeleteModalConfirmation}
                positiveClick={handleDeleteTransaction}
                show={deleteModalConfirmation}>
                {(<div>
                    Are you sure you want to delete this transaction?
                </div>)}
            </SimpleDialog>
        </div>
    )


}

export default RecentTransactions;
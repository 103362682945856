import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {setMessage} from "./message";
import TransactionService from "../../services/transaction.service";
import {logout, signin, signup} from "./auth";
import CategoryService from "../../services/category.service";
import {ERROR_BAD_REQUEST} from "../../utils/constants";
import {setAllCategory} from "./category";
import {calculateDaywiseAmountSpent} from "../../utils/utility.service";


export const addNewTransaction = createAsyncThunk(
    "transaction/addNewTransaction",
    async ({
               walletId,
               categoryId,
               subCategoryId,
               amount,
               spendAt,
               note,
               location,
               isImportant,
               isExclude,
               transactionType,
               paidType,
               paidOn
           }, thunkAPI) => {

        try {
            const response = await TransactionService.addNewTransaction(
                walletId,
                categoryId,
                subCategoryId,
                amount,
                spendAt,
                note,
                location,
                isImportant,
                isExclude,
                transactionType,
                paidType,
                paidOn);

            if(response.data) {
                let transactionResp = response.data;
                if(transactionResp.id > 0) {

                    response.data = {
                        ...response.data,
                        message: "New transaction has been added"
                    }

                    thunkAPI.dispatch(setMessage("New transaction has been added"));
                } else {
                    thunkAPI.dispatch(setMessage("Error while adding new transaction"));
                    response.data = {
                        ...response.data,
                        message: "Error while adding new transaction"
                    }
                }
            }
            return response.data;
        } catch (error) {
            console.log(error);
            const message =
                (error.response && error.response.data && error.response.data.errorMessage) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);

export const editTransaction = createAsyncThunk(
    "transaction/editTransaction",
    async ({
               id,
               walletId,
               categoryId,
               subCategoryId,
               amount,
               spendAt,
               note,
               location,
               isImportant,
               isExclude,
               transactionType,
               paidType,
               paidOn
           }, thunkAPI) => {

        try {
            const response = await TransactionService.editTransaction(
                id,
                walletId,
                categoryId,
                subCategoryId,
                amount,
                spendAt,
                note,
                location,
                isImportant,
                isExclude,
                transactionType,
                paidType,
                paidOn);
            thunkAPI.dispatch(setMessage(response.data.message));
            return response.data;
        } catch (error) {
            console.log(error);
            const message =
                (error.response && error.response.data && error.response.data.errorMessage) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);

export const getRecentTransaction = createAsyncThunk(
    "transaction/recentTransaction",
    async ({walletId},thunkAPI) => {
        try {
            const response = await TransactionService.getAllTransactionByWalletId(walletId);
            const modifiedList = calculateDaywiseAmountSpent(response.data);
            thunkAPI.dispatch(setLatestTransactionHistory(modifiedList));
            return modifiedList;
        } catch (error) {
            console.log("direct error, it may token expired");
            if(error.response && error.response.status == ERROR_BAD_REQUEST) {
                thunkAPI.dispatch(logout());
            }
            console.log(error);
        }
    }
)

export const deleteTransaction = createAsyncThunk(
    "transaction/deleteTransaction",
    async ({id},thunkAPI) => {
        try {
            const response = await TransactionService.deleteTransaction(id);
            //thunkAPI.dispatch(setLatestTransactionHistory(response.data));
            return response.data;
        } catch (error) {
            console.log("direct error, it may token expired");
            if(error.response && error.response.status == ERROR_BAD_REQUEST) {
                thunkAPI.dispatch(logout());
            }
            console.log(error);
        }
    }
)

const initialState = {};

const transactionSlice = createSlice({
    name: "transaction",
    initialState ,
    reducers: {
        setLatestTransactionHistory: (state, action) => {
            return {transactionList: action.payload};
        },
        setTransaction: (state, action) => {

            if(!state.transactionList) {
                return action.payload;
            }

            return {transactionList: [ action.payload, ...state.transactionList]};
        },
        resetTransaction: (state, action) => {
            return {transactionList: undefined};
        }
    },
    extraReducers: {
        [addNewTransaction.fulfilled]: (state, action) => {
            console.log("addNewTransaction fulfilled");
        },
        [addNewTransaction.rejected]: (state, action) => {
            console.log("addNewTransaction rejected");
        }
    }
});

export const allTransactionList = (state) => {
    return state.transaction;
}

const {reducer, actions} = transactionSlice;
export const {setLatestTransactionHistory, setTransaction, resetTransaction} = actions;
export default reducer;
import axios from "axios";
import authHeader from "./auth-header";
import {DOMAIN_NAME} from "../utils/constants";

const API_URL = DOMAIN_NAME + "/api/notification/";

const updateNotification = (id, categoryId, title, reminderType, startDate, endDate, state, remark) => {
    console.log("updateNotification axios");
    return axios.patch( API_URL, {
        id, categoryId, title, reminderType, startDate, endDate, state, remark
    }, { headers: authHeader() })
};

const getAllNotificationList = (userId) => {
    console.log("getAllNotificationList axios");
    return axios.get(API_URL  + userId , { headers: authHeader() });
};

const notificationMarkDone = (id) => {
    console.log("notificationMarkDone axios");
    return axios.get(API_URL  + 'markdone/'+id , { headers: authHeader() });
};

const deleteNotification = (id) => {
    console.log("deleteNotification axios");
    return axios.delete(API_URL+id , { headers: authHeader() });
};


const notificationService = {
    updateNotification,
    getAllNotificationList,
    deleteNotification,
    notificationMarkDone
}
export default  notificationService;
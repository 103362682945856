import {Avatar, Divider, ListItem, ListItemAvatar, ListItemButton, ListItemText} from "@mui/material";
import {getCategoryObject, getTitleFromCategory} from "../../utils/utility.service";
import dayjs from "dayjs";
import '../../assets/styles/transactions/transactionItem.scss'
import {Link} from "react-router-dom";
import React from "react";
import moment from "moment";

const TransactionItem = ({transaction, categoryList, subCategoryTitle, onClick, isDateHeader, currency}) => {


    let dayOfTheWeek='';
    if(isDateHeader) {
        dayOfTheWeek = dayjs(transaction.paidOn).format('dddd');
    }

    const paidOnDate = new Date(transaction.paidOn);
    let categoryObj = getCategoryObject(transaction.categoryId, categoryList);
    let categoryTitle = categoryObj.title;

    let image_path = '';
    try {
        image_path = require('../../assets/images/' + categoryObj.iconUrl + '.png');
    } catch(err){
        image_path = require('../../assets/images/ic_default.png');  //set default image path
    }

/*    console.log("converting utc to local");
    console.log(transaction.paidOn);
    var dateFormat = 'YYYY-DD-MM HH:mm:ss';
    var testDateUtc = moment.utc(transaction.paidOn);
    var localDate = testDateUtc.local();
    console.log(localDate.format(dateFormat)); // 2015-30-01 02:00:00
    console.log('paiddate existing --> '+paidOnDate); // 2015-30-01 02:00:00*/

    return (
        <div >

            {
                isDateHeader ?
                    <div key={ transaction.id + paidOnDate.getDate() } className="transaction-item-date-header">
                        <div className="date_day">
                            <span>{ paidOnDate.getDate() }</span>
                        </div>
                        <div className="date_description">
                            <span> {dayjs(transaction.paidOn).format('MMM YYYY')} </span>
                            <span> {dayOfTheWeek} </span>
                        </div>
                        <div className="total_amount">
                            <span>{currency} {transaction.dateHeaderTotalAmount}</span>
                        </div>
                    </div> : ""

            }

{/*            <Link to={'/Transactions/'+transaction.id}
                  key={transaction.id}
                  transaction = { transaction }
                  categoryTitle = { categoryTitle }
                  subCategoryTitle = { subCategoryTitle }
            >*/}
            <Link to={'/Transactions/'+transaction.id}
                      key={transaction.id}
                >
                {transaction.isImportant ? <span className="important-flag" /> : ''}
                <div className="transaction-item-section" key={transaction.id}  onClick={onClick}>

                    <div className="category-icon">
                        <img alt="category-icons" src={ image_path }/>
                    </div>
                    <div className="category-item">
                        <span>{ categoryTitle } </span>
                        <span> { subCategoryTitle } </span>
                    </div>
                    <div className="amount">
                        {
                            transaction.isExclude ?
                                <span className="amount-exclude" >{currency} {transaction.amount}</span> :
                                (transaction.transactionType == 0 ? <span>-{currency} {transaction.amount}</span> :
                                        <span className="amount-credit">{currency} {transaction.amount}</span>)

                        }
                    </div>
                </div>
            </Link>
        </div>)


    /*return (<div key={transaction.id}>
            <ListItem alignItems="flex-start" key={transaction.id}>
                <ListItemButton role={undefined}
                                onClick={ onClick }
                                dense>
                    <ListItemAvatar>
                        <Avatar alt="M" src="/static/images/avatar/1.jpg"/>
                    </ListItemAvatar>

                    <div>
                        <span>
                            {subCategoryTitle}
                        </span>

                        <span>{getTitleFromCategory(transaction.categoryId, categoryList)}</span>
                        <span>{dayjs(transaction.paidOn).format('YYYY-MM-DD')}</span>
                    </div>

                    <ListItemText
                        sx={{textAlign: 'right', color: "red"}}>
                        -Rs.{transaction.amount}
                    </ListItemText>
                </ListItemButton>
                <Divider variant="inset" component="li"/>
            </ListItem>
        </div>);*/
}

export default TransactionItem;
import React from "react";
import {alpha, withStyles} from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import {Link} from "react-router-dom";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SimpleDialog from "../SimpleDialog";
import AddTransactionDialog from "../transaction/AddTransactionDialog";
import Snackbar from "@mui/material/Snackbar";
import {Alert} from "@mui/material";
import Box from "@mui/material/Box";
import {connect} from "react-redux";
import {compose} from "@reduxjs/toolkit";
import {getDefaultWallet} from "../../utils/utility.service";
import {getUserInfo, getUserSettings, updateUserSetting} from "../../store/slices/userProfile";
import {getAllWalletByUser, updateWalletBalance} from "../../store/slices/wallet";
import LoadingScreen from "../splash/LoadingScreen";
import WelcomeWizard from "../welcome/WelcomeWizard";
import {getLastXMonthsList} from "../../store/slices/transactionStats";
import {getAllCategory} from "../../store/slices/category";
import {getAllSubCategory} from "../../store/slices/subCategory";
import {getAllNotification} from "../../store/slices/notification";

const styles = theme => ({
    grow: {
        flexGrow: 1
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    title: {
        display: "block",
        [theme.breakpoints.up("sm")]: {
            display: "block"
        }
    },
    search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        "&:hover": {
            backgroundColor: alpha(theme.palette.common.white, 0.25)
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            width: "auto"
        }
    },
    searchIcon: {
        width: theme.spacing(7),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    inputRoot: {
        color: "inherit"
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: 200
        }
    },
    sectionDesktop: {
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "flex"
        }
    },
    sectionMobile: {
        display: "flex",
        [theme.breakpoints.up("md")]: {
            display: "none"
        }
    },
    notificationBadge: {
        "&:hover": {
            color: "#a8a8a8"
        }
    }
});

class ToolbarComponent extends React.Component {
    state = {
        achorEl: false,
        MobileMoreAnchorEl: false,
        modalConfirmation: false,
        dialogBtnProgressEnabled: false, // added as part of AddTransactionDialog Save btn loading
        openSnack: false,
        snackMessage: '',
        loading: true,
        walletTitle: '',
        walletBalance: 0,
        oneTimeSetupWizard: false,
        notificationBadge: 0
    };

    constructor(props) {
        super(props);
        this.addTransactionReference = React.createRef(null);
    }

    componentDidMount() {
        this.fetchAllData();
    }

    handleProfileMenuOpen = event => {
        this.setState({
            achorEl: event.currentTarget
        });
    };

    handleMobileMenuClose = () => {
        this.setState({
            mobileMoreAnchorEl: null
        });
    };

    handleMenuClose = () => {
        this.setState({
            achorEl: null,
            mobileMoreAnchorEl: null
        });
    };

    handleMobileMenuOpen = event => {
        this.setState({
            mobileMoreAnchorEl: event.currentTarget
        });
    };

    handleCloseSnack = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        this.setState({
            openSnack: false,
        });
    };

    toggleConfirmationModal = () => {
        this.setState({
            modalConfirmation: !this.state.modalConfirmation,
            dialogBtnProgressEnabled: false
        });
    }

    enablingDialogBtnLoadingProgress = () => {
        this.setState({
            dialogBtnProgressEnabled: !this.state.dialogBtnProgressEnabled,
        });
    }

    snackState = (value) => {
        this.setState({
            openSnack: value,
        });
    }

    settingSnackMessage = (message) => {
        this.setState({
            snackMessage: message,
        });
    }

    updateWalletInfo = (totalAmountSpentSoFar) => {
        let {defaultWallet} = this.props.wallet;
        let wallet = Object.assign({}, getDefaultWallet(defaultWallet));
        if (wallet)
            this.setState({
                walletTitle: wallet.title ? wallet.title : '',
                walletBalance: wallet.balance ? (wallet.balance - totalAmountSpentSoFar) : 0
            });
    }

    render() {
        const {classes} = this.props;
        const isMenuOpen = Boolean(this.state.anchorEl);
        const isMobileMenuOpen = Boolean(this.state.mobileMoreAnchorEl);

        const menuId = "primary-search-account-menu";
        const renderMenu = (
            <Menu
                anchorEl={this.state.anchorEl}
                anchorOrigin={{vertical: "top", horizontal: "right"}}
                id={menuId}
                keepMounted
                transformOrigin={{vertical: "top", horizontal: "right"}}
                open={isMenuOpen}
                onClose={this.handleMenuClose}
            >
                <MenuItem onClick={this.handleMenuClose}>Profile</MenuItem>
                <MenuItem onClick={this.handleMenuClose}>My account</MenuItem>
            </Menu>
        );

        const mobileMenuId = "primary-search-account-menu-mobile";
        const renderMobileMenu = (
            <Menu
                anchorEl={this.state.mobileMoreAnchorEl}
                anchorOrigin={{vertical: "top", horizontal: "right"}}
                id={mobileMenuId}
                keepMounted
                transformOrigin={{vertical: "top", horizontal: "right"}}
                open={isMobileMenuOpen}
                onClose={this.handleMobileMenuClose}
            >
                {/* <MenuItem>
                    <IconButton aria-label="show 4 new mails" color="inherit"
                                onClick={() => {
                                    this.toggleConfirmationModal();
                                    this.handleMobileMenuClose();} }>
                        <AddCircleOutlineIcon/>
                        <Typography style={{textDecoration: "none", color: "black"}}>
                            &nbsp;Add transaction
                        </Typography>
                    </IconButton>
                </MenuItem>*/}
                <MenuItem>
                    <IconButton color="inherit" component={Link} to={`/Search`}
                                onClick={() => this.handleMobileMenuClose()}>
                        <SearchIcon/>
                        <Typography style={{textDecoration: "none", color: "black"}}>
                            &nbsp;Search
                        </Typography>
                    </IconButton>
                </MenuItem>

                {/*<MenuItem>
                    <IconButton aria-label="show 11 new notifications" color="inherit"
                                style={{textDecoration: "none"}}
                                component={Link} to={`/Notifications`} >
                        <Badge badgeContent={11} color="secondary" overlap="rectangular">
                            <NotificationsIcon/>
                        </Badge>
                        <Typography style={{textDecoration: "none", color: "black"}}>
                            &nbsp;Notifications
                        </Typography>
                    </IconButton>
                </MenuItem>*/}
                <MenuItem onClick={this.handleProfileMenuOpen}>
                    <IconButton
                        aria-label="account of current user"
                        aria-controls="primary-search-account-menu"
                        aria-haspopup="true"
                        color="inherit"
                        style={{textDecoration: "none",}}
                        component={Link} to={`/Profile`}
                        onClick={() => this.handleMobileMenuClose()}>
                        <AccountCircle/>
                        <Typography style={{textDecoration: "none", color: "black"}}>
                            &nbsp;Profile
                        </Typography>
                    </IconButton>
                </MenuItem>
            </Menu>
        );

        if (this.state.loading) {
            return (<LoadingScreen/>)
        }

        //this.getWalletTitle();

        return (
            <div className={classes.grow}>
                <AppBar position="fixed">
                    <Toolbar>
                        <IconButton
                            edge="start"
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="open drawer"
                            onClick={this.props.openDrawerHandler}
                        >
                            <MenuIcon/>
                        </IconButton>

                        <Typography style={{textDecoration: "none", color: "white"}} className={classes.title}
                                    variant="h6" noWrap component={Link} to={"/"}>

                            {
                                this.props.wallet.defaultWallet ?
                                    <div><Box sx={{typography: 'body1'}}>{this.props.wallet.defaultWallet.title}</Box>
                                        <Box sx={{
                                            typography: 'subtitle2',
                                            lineHeight: 0.8
                                        }}>{this.props.userSettings ? this.props.userSettings.currency : ''} {this.props.wallet.defaultWallet.totalSpentSoFar}</Box>
                                    </div> : ''
                            }

                        </Typography>

                        <div className={classes.grow}/>
                        <div className={classes.sectionMobile}>

                            <IconButton aria-label="show 4 new mails" color="inherit"
                                        onClick={() => this.toggleConfirmationModal()}>
                                <AddCircleOutlineIcon/>
                                <Typography style={{textDecoration: "none", color: "white"}}>
                                    &nbsp;
                                </Typography>

                            </IconButton>
                            <IconButton aria-label="show 11 new notifications" color="inherit"
                                        style={{textDecoration: "none"}}
                                        component={Link} to={`/Notifications`}>
                                <Badge
                                    className={ classes.notificationBadge }
                                    badgeContent={ this.props.notification.activeNotificationCount }
                                    color="error"
                                    overlap="rectangular">
                                    <NotificationsIcon/>
                                </Badge>
                            </IconButton>
                        </div>
                        <div className={classes.sectionDesktop}>
                            <IconButton aria-label="show 4 new mails" color="inherit"
                                        onClick={() => this.toggleConfirmationModal()}>
                                <AddCircleOutlineIcon/>
                                <Typography style={{textDecoration: "none", color: "white"}}>
                                    &nbsp;Add transaction
                                </Typography>

                            </IconButton>
                            {/*<IconButton color="inherit" component={Link} to={`/Search`}>
                                <SearchIcon/>
                            </IconButton>*/}
                            {<IconButton
                                aria-label="show 17 new notifications"
                                color="inherit"
                            >
                                <Badge badgeContent={ this.props.notification.activeNotificationCount }
                                       className={ classes.notificationBadge }
                                       color="error"
                                       overlap="rectangular"
                                       style={{textDecoration: "none", color: "white"}}
                                       component={Link}
                                       to={`/Notifications`}>
                                    <NotificationsIcon/>
                                </Badge>
                            </IconButton>}
                            <IconButton
                                edge="end"
                                aria-label="account of current user"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                onClick={this.handleProfileMenuOpen}
                                color="inherit"
                                style={{textDecoration: "none", color: "white"}}
                                component={Link} to={`/Profile`}
                            >
                                <AccountCircle/>
                            </IconButton>
                        </div>
                        <div className={classes.sectionMobile}>
                            <IconButton
                                aria-label="show more"
                                aria-controls={mobileMenuId}
                                aria-haspopup="true"
                                onClick={this.handleMobileMenuOpen}
                                color="inherit"
                            >
                                <MoreIcon/>
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>
                {renderMobileMenu}
                {renderMenu}


                <SimpleDialog
                    title="Add new transaction"
                    subtitle="Please add the transaction details below :"
                    positiveBtn="Save"
                    negativeBtn="Cancel"
                    onClose={this.toggleConfirmationModal}
                    positiveClick={() => this.addTransactionReference.current()}
                    positiveBtnProgress={this.state.dialogBtnProgressEnabled}
                    show={this.state.modalConfirmation}>
                    {(<div>
                        <AddTransactionDialog ref={this.addTransactionReference}
                                              onClose={this.toggleConfirmationModal}
                                              enableProgress={this.enablingDialogBtnLoadingProgress}
                                              snackbarStat={this.snackState}
                                              snackMessage={this.settingSnackMessage}/>
                    </div>)}
                </SimpleDialog>

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.openSnack}
                    autoHideDuration={4000}
                    onClose={this.handleCloseSnack}
                >
                    <Alert
                        onClose={this.handleCloseSnack}
                        severity="success" sx={{width: '100%'}}>
                        {this.state.snackMessage}
                    </Alert>
                </Snackbar>

                {
                    (this.state.oneTimeSetupWizard &&
                        <WelcomeWizard
                            show={this.state.oneTimeSetupWizard}
                            onClose={this.toggleWelcomeWizardModal}/>)
                }

            </div>
        );
    }

    toggleWelcomeWizardModal = () => {
        this.setState({
            ...this.state,
            oneTimeSetupWizard: !this.state.oneTimeSetupWizard
        });
    }

    fetchAllData = async () => {
        this.fetchUserProfile();
    }

    fetchUserProfile = () => {

        this.props.getUserInfo()
            .unwrap()
            .then((user) => {
                console.log("User profile loaded");
                if (user) {
                    this.fetchAllWallet(user.id);
                    this.props.fetchCategories({userId: user.id}).unwrap();
                    this.props.fetchSubCategories({userId: user.id}).unwrap();
                    this.props.updateLastLogin({
                        userId: user.id,
                        currency: undefined, lastLogin: new Date()
                    }).unwrap();
                    this.props.loadUserSettings({userId: user.id}).unwrap();
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    fetchAllWallet = (userId) => {

        //dispatch(getAllWalletByUser({ userId: userProfile.id}))
        this.props.getAllWallet({userId: userId})
            .unwrap()
            .then((response) => {
                if (response && response.length && response.length > 0) {

                    let defaultWalletId = null;
                    response.map((wallet) => {
                        if (wallet.isDefault) {
                            defaultWalletId = wallet.id;
                        }
                    })
                    this.fetchAllNotifications(userId);
                    this.fetchRecentTransaction(defaultWalletId);
                } else if (response && response.errorCode) {
                    this.setState({
                        ...this.state,
                        loading: false,
                        oneTimeSetupWizard: true
                    });

                    console.log(this.oneTimeSetupWizard)
                }

            })
            .catch((err) => {
                console.log(err);
            })
    }

    fetchRecentTransaction = (walletId) => {
        this.props.fetchAllTransactions({walletId})
            .unwrap()
            .then((response) => {
                this.setState({
                    ...this.state,
                    loading: false,
                });
            })
            .catch((err) => {
                console.log(err);
            })
    }

    fetchAllNotifications = (userId) => {
        this.props.getAllNotifications({userId})
            .unwrap()
            .then((response) => {
                let activeNotificationCount = response;
                this.setState({
                    ...this.state,
                    notificationBadge: activeNotificationCount,
                });
            })
            .catch((err) => {
                console.log("Error while fetching notifications list: " + err);
            })
    }
}

const mapStateToProps = state => ({
    wallet: state.wallet,
    userSettings: state.userProfile.settings,
    notification: state.notification
})

const mapDispatchToProps = dispatch => {
    return {
        getUserInfo: () => {
            return dispatch(getUserInfo());
        },
        updateLastLogin: ({userId, currency, lastLogin}) => {
            // last login
            return dispatch(updateUserSetting({userId, currency, lastLogin}));
        },
        loadUserSettings: ({userId}) => {
            return dispatch(getUserSettings({userId}));
        },
        fetchCategories: ({userId}) => {
            return dispatch(getAllCategory({userId}));
        },
        fetchSubCategories: ({userId}) => {
            return dispatch(getAllSubCategory({userId}));
        },
        fetchAllTransactions: ({walletId}) => {
            return dispatch(getLastXMonthsList({walletId}))
        },
        getAllWallet: ({userId}) => {
            return dispatch(getAllWalletByUser({userId}))
        },
        getAllNotifications: ({userId}) => {
            return dispatch(getAllNotification({userId}))
        },
        updateWalletBalance: ({amount, transactionType}) => {
            return dispatch(updateWalletBalance({amount, transactionType}))
        }
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(ToolbarComponent);

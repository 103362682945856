
//export const DOMAIN_NAME = "http://localhost:3000";
//export const DOMAIN_NAME = "http://localhost";
export const DOMAIN_NAME = "https://myexpensetrack.com";  // remove www otherwise newtork error

export const API = "API";
export const API_START = "API_START";
export const API_END = "API_END";
export const ACCESS_DENIED = "ACCESS_DENIED";
export const API_ERROR = "API_ERROR";




export const FETCH_ARTICLE_DETAILS = "FETCH_ARTICLE_DETAILS";
export const SET_ARTICLE_DETAILS = "SET_ARTICLE_DETAILS";

export const ERROR_BAD_REQUEST = 400;
export const ERROR_NO_WALLET_FOUND = 112;

export const STATUS_SUCCESS = "SUCCESS";
export const STATUS_FAILED = "FAILED";

export const TRANSACTION_TYPE_WITHDRAW = '0';
export const TRANSACTION_TYPE_CREDIT = '1';

export const RECENT_TRANSACTION_DAYS_COUNT = 2;

export const THIS_MONTH_TRANSACTION = "this-month";
export const LAST_MONTH_TRANSACTION = "last-month";

export const TRANSACTION_OPERATION_ADD = 1;
export const TRANSACTION_OPERATION_UPDATE = 2;
export const TRANSACTION_OPERATION_DELETE = 3;

export const DEFAULT_CURRENCY = "$";

export const NOTIFICATION_ACTIVE = '1';
export const NOTIFICATION_INACTIVE = '0';
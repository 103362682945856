import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import CategoryService from "../../services/category.service";
import {setMessage} from "./message";
import {ERROR_BAD_REQUEST} from "../../utils/constants";
import {logout} from "./auth";


const user = JSON.parse(localStorage.getItem("user"));
export const addNewSubCategory = createAsyncThunk(
    "subCategory/addNewSubCategory",
    async ({title, desc, iconUrl, categoryId}, thunkAPI) => {

        try{
            const response = await CategoryService.addNewSubCategory(title, desc, iconUrl, categoryId);
            thunkAPI.dispatch(setMessage(response.data.message));
            if(response && response.data && response.data.id) {
                thunkAPI.dispatch(setSubCategory({ id : response.data.id ,
                    title, desc, iconUrl, categoryId}));
            }
            return response.data;
        } catch (error) {
            console.log(error);
            const message =
                (error.response && error.response.data && error.response.data.errorMessage) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);

export const updateSubCategory = createAsyncThunk(
    "subCategory/addNewSubCategory",
    async ({id, title, desc, iconUrl, categoryId}, thunkAPI) => {

        try{
            const response = await CategoryService.updateSubCategory(id, title, desc, iconUrl, categoryId);
            thunkAPI.dispatch(setMessage(response.data.message));
            thunkAPI.dispatch(setSubCategory({id,title, desc, iconUrl, categoryId}));
            return response.data;
        } catch (error) {
            console.log(error);
            const message =
                (error.response && error.response.data && error.response.data.errorMessage) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);

export const getAllSubCategory = createAsyncThunk(
    "subCategory/getAllSubCategory",
    async ({ userId },thunkAPI) => {
        try {
            const response = await CategoryService.getAllSubCategory(userId);
            thunkAPI.dispatch(setAllSubCategory(response.data));
            return response.data;
        } catch (error) {

            console.log("direct error, it may token expired");
            if(error.response && error.response.status == ERROR_BAD_REQUEST) {
                thunkAPI.dispatch(logout());
            }
            console.log(error);
        }
    }
)

export const deleteSubCategory = createAsyncThunk(
    "subCategory/deleteSubCategory",
    async ({id},thunkAPI) => {
        try {
            const response = await CategoryService.deleteSubCategory(id);
            return response.data;
        } catch (error) {

            console.log("direct error, it may token expired");
            if(error.response && error.response.status == ERROR_BAD_REQUEST) {
                thunkAPI.dispatch(logout());
            }
            console.log(error);
        }
    }
)

const initialState = {};

const subCategorySlice = createSlice( {
    name: "subCategory",
    initialState,
    reducers: {
        setAllSubCategory: (state, action) => {
            return { subCategoryList: action.payload };
        },
        setSubCategory: (state, action) => {

            // updating existing sub-cat
            if(action.payload.id) {
                let index = state.subCategoryList.findIndex(cat=> cat.id === action.payload.id);
                if(index != -1) {

                    let newArray = [...state.subCategoryList];
                    newArray[index] = action.payload;
                    return {
                        subCategoryList: newArray
                    }
                }
            }
            return { subCategoryList: [...state.subCategoryList, action.payload]  };
        }
    }
})

export const allSubCategoryList = (state) => {
    return state.subCategory;
}

const {reducer, actions} = subCategorySlice;

export const { setAllSubCategory, setSubCategory } = actions;
export default reducer;
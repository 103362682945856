import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import React from "react";
import {getCategoryObject, getReminderTypeTitle} from "../../utils/utility.service";
import '../../assets/styles/reminder/reminderItem.scss';
import RepeatIcon from '@mui/icons-material/Repeat';
import dayjs from "dayjs";
import ClearIcon from '@mui/icons-material/Clear';
import {Button} from "@mui/material";
import {Link} from "react-router-dom";
import Badge from '@mui/joy/Badge';

const ReminderItem = ({reminderView, categoryList, onClick, deleteClick, lastItem}) => {

    let categoryObj = getCategoryObject(reminderView.categoryId, categoryList);

    let image_path = '';
    try {
        image_path = require('../../assets/images/' + categoryObj.iconUrl + '.png');
    } catch (err) {
        image_path = require('../../assets/images/ic_default.png');  //set default image path
    }

    let reminderType = getReminderTypeTitle(reminderView.reminderType);

    return (
        <div
            className={`reminderItem-section ${!lastItem ? 'divider-item' : ''} ${!reminderView.state ? 'notificationItem-inactive' : ''}`}
            key={reminderView.id}>

            <Link to={'/Reminder/' + reminderView.id}
                  key={reminderView.id}
            >
                <div className="reminder-item-header">
                    <div className="category-icon">
                        <Badge badgeContent={reminderView.remainingDaysCount} max={999} badgeInset=".5em .5em 0em 0em" color="success" >
                            <img alt="category-icons" src={image_path}/>
                        </Badge>
                    </div>

                    <div className="reminder-main-div">
                        <div className="reminder-title">
                            <span className="">{reminderView.title} </span>
                        </div>

                        <div className="reminder-other-details">
                            <div className="category-section">
                                <span className="category-title">{categoryObj.title} </span>
                                {
                                    reminderType != 0 ?
                                        <span className="repeat"><RepeatIcon
                                            className="icon-repeat"/>{reminderType}</span> : ''
                                }
                            </div>
                            <div className="date-section">
                            <span
                                className="start-date">Start: {dayjs(reminderView.startDate).format('DD-MMM-YYYY')}</span>
                                {
                                    reminderView.endDate ?
                                        <span
                                            className="end-date">End : {dayjs(reminderView.endDate).format('DD-MMM-YYYY')}</span> : 'No end date'
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default ReminderItem;
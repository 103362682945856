import {Alert, Avatar, Button, Card, Divider} from "@mui/material";
import {findTransactionDetails, getCategoryObject, getTitleFromCategory} from "../../utils/utility.service";
import dayjs from "dayjs";
import StarIcon from "@mui/icons-material/Star";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import '../../assets/styles/transactions/transactionDetails.scss'
import {useNavigate, useParams} from 'react-router-dom';
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {deleteTransaction} from "../../store/slices/transaction";
import {allCategoryList, getAllCategory} from "../../store/slices/category";
import {allSubCategoryList, getAllSubCategory} from "../../store/slices/subCategory";
import SimpleDialog from "../../components/SimpleDialog";
import AddTransactionDialog from "../../components/transaction/AddTransactionDialog";
import CircularLoading from "../../components/splash/CircularLoading";
import Box from "@mui/material/Box";
import {TRANSACTION_OPERATION_DELETE, TRANSACTION_TYPE_CREDIT, TRANSACTION_TYPE_WITHDRAW} from "../../utils/constants";
import {updateWalletBalance} from "../../store/slices/wallet";
import {allTransactionStats, updateTransactionList} from "../../store/slices/transactionStats";
import {reportData} from "../../store/slices/reports";
import Snackbar from "@mui/material/Snackbar";

const TransactionDetails = () => {

    const {lastTwoMonthsList: transactionList} = useSelector(allTransactionStats);
    const {monthlyReport: monthlyTransactionList} = useSelector(reportData);
    const {defaultWallet} = useSelector((state) => state.wallet);

    const {categoryList} = useSelector(allCategoryList);
    const {subCategoryList} = useSelector(allSubCategoryList);
    const {user: userProfile} = useSelector((state) => state.userProfile);

    const [transaction, setTransaction] = useState(undefined);
    const [openSnack, setOpenSnack] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [category, setCategory] = useState(undefined);
    const [categoryIconPath, setCategoryIconPath] = useState(undefined);
    const [subCategoryTitle, setSubCategoryTitle] = useState(undefined);
    const {id} = useParams();

    const [deleteModalConfirmation, setDeleteModalConfirmation] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const addTransactionReference = React.useRef(null);

    useEffect(() => {

        if (!categoryList && userProfile) {
            dispatch(getAllCategory({ userId:userProfile.id}));
        }

        if (!subCategoryList && userProfile) {
            dispatch(getAllSubCategory({ userId:userProfile.id}));
        }

        loadDetails(id);
    }, [transactionList]);

    const toggleDeleteModalConfirmation = () => {
        setDeleteModalConfirmation(!deleteModalConfirmation);
    }

    const toggleEditModal = () => {
        setEditModal(!editModal);
    }

    const getSubCategoryTitle = (id, list, spent) => {

        let title = getTitleFromCategory(id, list);

        if (title === 'Other' || title === 'other') {
            return spent ? spent : title;
        }
        return title;
    }

    const editTransaction = () => {
        setEditModal(!editModal);
    }

    const transactionUpdated = (updatedTransaction) => {
        setTransaction(updatedTransaction);
        console.log('after updating....');
        console.log(updatedTransaction);

        /*  setTimeout(() => {
              let indexFound = transactionList.findIndex(trans => trans.id === transaction.id);
              Object.freeze(transactionList);

              // to display header title for date
              updatedTransaction.dateHeaderTotalAmount = transactionList[indexFound].dateHeaderTotalAmount;

              const newTransactionList = [...transactionList];
              newTransactionList.splice(indexFound, 1, updatedTransaction); // deleting one element
              dispatch(setLatestTransactionHistory(newTransactionList))
          })*/
    }

    console.log(transaction);

    const loadDetails = (id) => {

        if ((transactionList && transactionList.length > 0) || (monthlyTransactionList && monthlyTransactionList.length > 0)) {
            let transaction = findTransactionDetails(id, transactionList);

            if(!transaction) {
                // finding report monthly data
                transaction = findTransactionDetails(id, monthlyTransactionList);
            }

            if (transaction) {
                setTransaction(transaction);

                let categoryObj = getCategoryObject(transaction.categoryId, categoryList);
                setCategory(categoryObj);
                setSubCategoryTitle(getSubCategoryTitle(transaction.subCategoryId, subCategoryList, transaction.spendAt));

                let image_path='';
                try {
                    image_path = require('../../assets/images/' + categoryObj.iconUrl + '.png');
                } catch(err){
                    image_path = require('../../assets/images/ic_default.png');  //set default image path
                }
                setCategoryIconPath(image_path);
            } else {
                setTransaction("NOT_FOUND");
            }
        }
    }
    const updateWalletBalanceAfterDelete = () => {

        if (!transaction.isExclude.value) {
            let updatedValue = transaction.amount - 0;

            let operation = TRANSACTION_TYPE_CREDIT;
            if (updatedValue > 0) {
                operation = TRANSACTION_TYPE_CREDIT;
            } else {
                operation = TRANSACTION_TYPE_WITHDRAW;
            }

            dispatch(updateWalletBalance(
                {
                    amount: Math.abs(updatedValue),
                    transactionType: operation
                }))
        }
    }
    const handleDeleteTransaction = () => {
        dispatch(deleteTransaction({id: transaction.id}))
            .unwrap()
            .then(() => {
                updateWalletBalanceAfterDelete();

                toggleDeleteModalConfirmation();
                setTimeout(() => {    const handleDeleteTransaction = () => {
                    dispatch(deleteTransaction({id: transaction.id}))
                        .unwrap()
                        .then(() => {
                            updateWalletBalanceAfterDelete();

                            toggleDeleteModalConfirmation();
                            setTimeout(() => {

                                let data = {
                                    transaction: transaction,
                                    operation: TRANSACTION_OPERATION_DELETE
                                }
                                dispatch(updateTransactionList(data));
                                //$('body').removeClass('modal-open');
                                navigate("/");
                            })

                        })
                        .catch((err) => {
                            console.log(err);
                        })
                }

                    let data = {
                        transaction: transaction,
                        operation: TRANSACTION_OPERATION_DELETE
                    }
                    dispatch(updateTransactionList(data));
                    //$('body').removeClass('modal-open');
                    navigate("/");
                })

            })
            .catch((err) => {
                console.log(err);
            })
    }

    const handleCloseSnack = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpenSnack(false);
    };

    const snackState = (value) => {
        setOpenSnack(value);
    }

    const settingSnackMessage = (message) => {
        setSnackMessage(message);
    }

    return (
        <div className="transaction-details-section header-top-padding">

            {
                (transaction && transaction != "NOT_FOUND") ?
                    <Card className="card-details">
                        <div className="header">
                            <span>Transaction details: </span>
                            {transaction.isImportant ? <div className="important-star"><StarIcon/></div> : ""}
                        </div>
                        <Divider/>
                        <div className="wallet-div">
                            <span className="date">{dayjs(transaction.paidOn).format('DD-MMM-YYYY')}</span>
                            <span className="wallet">{defaultWallet.title}</span>
                        </div>
                        <div className="content">

                            <div className="content-details-left">
                                <div className="logo">
                                    <img alt="category-icons" src={ categoryIconPath } />
                                    {/*<Avatar alt={category.title.charAt(0)} src={image_path}/>*/}
                                </div>
                                <div className="transaction-details">
                                    <span
                                        className="category"> {category.title} </span>
                                    <span
                                        className="sub-category"> {subCategoryTitle}{transaction.spendAt ? ' - ' + transaction.spendAt : ''}</span>
                                    <span className="note"> {transaction.note ? 'Note: ' + transaction.note : ''}</span>
                                    <span
                                        className="note"> {transaction.location ? 'Location: ' + transaction.location : ''}</span>
                                    <Divider/>
                                    <span hidden={ transaction.transactionType != TRANSACTION_TYPE_WITHDRAW }
                                        className={"amount " + (transaction.isExclude ? 'amount-exclude' : '')}>{transaction.isExclude ? 'Rs. ' + transaction.amount : '-Rs. ' + transaction.amount}</span>
                                    <span hidden={ transaction.transactionType != TRANSACTION_TYPE_CREDIT }  className={"amount " + (transaction.isExclude ? 'amount-exclude' : 'amount-credited')}>
                                        {transaction.isExclude ? 'Rs. ' + transaction.amount : 'Rs. ' + transaction.amount}
                                    </span>
                                </div>
                            </div>


                            <Divider className="bottom-divider"/>
                            <div className="content-details-right">
                                <div className="action-buttons">

                                    <Button className="edit-transaction"
                                            variant="text"
                                            startIcon={<ModeEditIcon/>}
                                            style={{color: "green"}}
                                            onClick={editTransaction}>
                                        Edit
                                    </Button>
                                    <Button className="delete-transaction"
                                            variant="text"
                                            startIcon={<DeleteIcon/>}
                                            style={{color: "red"}}
                                            onClick={toggleDeleteModalConfirmation}>
                                        Delete
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Card> :
                    (transaction != "NOT_FOUND") ?
                        <Box sx={{display: 'flex', justifyContent: 'center', paddingTop: '2em'}}>
                            <CircularLoading enableFlag={true}/>
                        </Box> : navigate("/")
            }

            <SimpleDialog
                title="Edit transaction"
                subtitle="Please update the transaction details below :"
                positiveBtn="Save"
                negativeBtn="Cancel"
                onClose={toggleEditModal}
                positiveClick={() => addTransactionReference.current()}
                show={editModal}>
                {(<div>
                    <AddTransactionDialog ref={addTransactionReference}
                                          transactionView={transaction}
                                          isUpdate="true"
                                          updateInfo={transactionUpdated}
                                          onClose={toggleEditModal}
                                          snackbarStat={snackState}
                                          snackMessage={settingSnackMessage}
                    />
                </div>)}
            </SimpleDialog>

            <SimpleDialog
                title="Delete confirmation"
                positiveBtn="Yes"
                negativeBtn="No"
                onClose={toggleDeleteModalConfirmation}
                positiveClick={handleDeleteTransaction}
                show={deleteModalConfirmation}>
                {(<div>
                    Are you sure you want to delete this transaction?
                </div>)}
            </SimpleDialog>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={openSnack}
                autoHideDuration={4000}
                onClose={handleCloseSnack}
            >
                <Alert
                    onClose={handleCloseSnack}
                    severity="success" sx={{width: '100%'}}>
                    { snackMessage}
                </Alert>
            </Snackbar>
        </div>
    )


}

export default TransactionDetails;
import axios from "axios";
import authHeader from "./auth-header";
import {DOMAIN_NAME} from "../utils/constants";

const API_URL = DOMAIN_NAME + "/api/category/";
const API_SUB_CATEGORY_URL = DOMAIN_NAME + "/api/subcategory/";

const addNewCategory = (title, desc, transactionType, iconUrl) => {
    console.log("addNewCategory axios");
    return axios.post( API_URL, {
        title, desc, transactionType, iconUrl
    }, { headers: authHeader() })
};

const updateCategory = (id, title, desc, transactionType, iconUrl) => {
    console.log("updateCategory axios");
    return axios.patch( API_URL, {
        id, title, desc, transactionType, iconUrl
    }, { headers: authHeader() })
};

const getAllCategory = (userId) => {
    console.log("getAllCategory axios");
    return axios.get(API_URL + 'user/' + userId , { headers: authHeader() });
};

const addNewSubCategory = (title, desc, iconUrl, categoryId) => {
    console.log("addNewSubCategory axios");
    return axios.post( API_SUB_CATEGORY_URL, {
        title, desc, iconUrl, categoryId
    }, { headers: authHeader() })
};

const updateSubCategory = (id, title, desc, iconUrl, categoryId) => {
    console.log("updateSubCategory axios");
    return axios.patch( API_SUB_CATEGORY_URL, {
        id, title, desc, iconUrl, categoryId
    }, { headers: authHeader() })
};

const getAllSubCategory = (userId) => {
    console.log("getAllSubCategory axios");
    return axios.get(API_SUB_CATEGORY_URL + 'user/' + userId , { headers: authHeader() });
};

const deleteCategory = (id) => {
    console.log("deleteCategory axios");
    return axios.delete(API_URL+id , { headers: authHeader() });
};

const deleteSubCategory = (id) => {
    console.log("deleteSubCategory axios");
    return axios.delete(API_SUB_CATEGORY_URL+id , { headers: authHeader() });
};

const categoryService = {
    addNewCategory,
    updateCategory,
    getAllCategory,
    addNewSubCategory,
    updateSubCategory,
    getAllSubCategory,
    deleteCategory,
    deleteSubCategory
}
export default  categoryService;
import {getCategoryObject} from "../../utils/utility.service";
import '../../assets/styles/transactions/transactionSimpleItem.scss'
import {Link} from "react-router-dom";
import React from "react";
import moment from "moment/moment";

const TransactionSimpleItem = ({transaction, categoryList, subCategoryTitle, onClick, currency}) => {

    let categoryObj = getCategoryObject(transaction.categoryId, categoryList);
    let image_path = '';
    try {
        image_path = require('../../assets/images/' + categoryObj.iconUrl + '.png');
    } catch(err){
        image_path = require('../../assets/images/ic_default.png');  //set default image path
    }

    let dateFormat = 'DD-MMM';
    let testDateUtc = moment.utc(transaction.paidOn);
    let localDate = testDateUtc.local();

    return (
        <div >
            <Link to={'/Transactions/'+transaction.id}
                  key={transaction.id}
            >
                {transaction.isImportant ? <span className="important-flag" /> : ''}
                <div className="transaction-simple-item-section" key={transaction.id}  onClick={onClick}>

                    <div className="category-icon">
                        <img alt="category-icons" src={ image_path }/>
                    </div>
                    <div className="category-item">
                        <span> { subCategoryTitle } </span>
                        <span> {localDate.format(dateFormat)} </span>
                    </div>
                    <div className="amount">
                        {
                            transaction.isExclude ?
                                <span className="amount-exclude" >{currency} {transaction.amount}</span> :
                                (transaction.transactionType == 0 ? <span>-{currency} {transaction.amount}</span> :
                                    <span className="amount-credit">{currency} {transaction.amount}</span>)
                        }
                    </div>
                </div>
            </Link>
        </div>)
}

export default TransactionSimpleItem;
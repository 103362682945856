import axios from "axios";
import authHeader from "./auth-header";
import {DOMAIN_NAME} from "../utils/constants";

const API_URL = DOMAIN_NAME + "/api/report/";


const getAllMonthList = (userId, walletId) => {
    console.log("getAllMonthList axios");
    return axios.get(API_URL + "monthlist/" +userId+"/"+ walletId, { headers: authHeader() });
};

const reportService = {
    getAllMonthList
};

export default reportService
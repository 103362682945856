import axios from "axios";
import authHeader from "./auth-header";
import {DOMAIN_NAME} from "../utils/constants";

const API_URL = DOMAIN_NAME + "/api/reminder/";

const addNewReminder = (userId, categoryId, title, reminderType, startDate, endDate, state, remark, upcomingReminder) => {
    console.log("addNewReminder axios");
    debugger;
    return axios.post( API_URL + "add/", {
        userId, categoryId, title, reminderType, startDate, endDate, state, remark, upcomingReminder
    }, { headers: authHeader() })
};

const updateReminder = (id, categoryId, title, reminderType, startDate, endDate, state, remark, upcomingReminder) => {
    console.log("updateReminder axios");
    return axios.patch( API_URL, {
        id, categoryId, title, reminderType, startDate, endDate, state, remark, upcomingReminder
    }, { headers: authHeader() })
};

const getAllReminderList = (userId) => {
    console.log("getAllReminderList axios");
    return axios.get(API_URL  + userId , { headers: authHeader() });
};

const deleteReminder = (id) => {
    console.log("deleteReminder axios");
    return axios.delete(API_URL+id , { headers: authHeader() });
};


const reminderService = {
    addNewReminder,
    updateReminder,
    getAllReminderList,
    deleteReminder
}
export default  reminderService;
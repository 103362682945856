import '../../assets/styles/transactions/monthlyView.scss'
import Typography from "@mui/material/Typography";
import {Button, Card, CardActions, CardContent} from "@mui/material";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import Box from "@mui/material/Box";

const MonthlyView = ({monthName, totalSpent,currency, handleClick}) => {

    return (

        <div className="monthly-view-container" onClick={handleClick}>

            {/* <span className="month-name">{monthName}</span>
            <span className="total-spent">Rs.{totalSpent}</span>
*/}
            <Card sx={{minWidth: { xs: 170}, display: 'flex'}}>
                <Box sx={{display: 'flex', alignItems: 'center', pl: 1, pb: 1}}>
                    <MonetizationOnIcon fontSize="large" color="primary"/>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                    <CardContent>
                        <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                            {monthName}
                        </Typography>
                        <Typography sx={{
                            fontSize: {
                                lg: 20,
                                md: 20,
                                sm: 18,
                                xs: 16
                            }
                        }} component="div">
                            {currency} {totalSpent}
                        </Typography>
                    </CardContent>
                </Box>

            </Card>
        </div>
    )
}

export default MonthlyView;
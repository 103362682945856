import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {Alert, Button, ListItemText, Select, TextField} from "@mui/material";
import React, {useState} from "react";
import {signup} from "../../store/slices/auth";
import {useDispatch, useSelector} from "react-redux";
import {addNewCategory, allCategoryList, updateCategory} from "../../store/slices/category";
import {currentUserProfile} from "../../store/slices/userProfile";
import {addNewSubCategory, updateSubCategory} from "../../store/slices/subCategory";
import MenuItem from "@mui/material/MenuItem";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import {ListItemIcon} from "@material-ui/core";
import '../../assets/styles/category/createSubCategory.scss';


const CreateSubCategory = ({addCategoryRef, onClose, passCategoryId, isUpdate, updateCategoryItem, handlePositiveSubmit}) => {

    const {user: currentUser} = useSelector(currentUserProfile);
    const dispatch = useDispatch();
    const [successful, setSuccessful] = useState(false);
    const {categoryList} = useSelector(allCategoryList);

    const categoryFormValues = {
        categoryId:{
            value: passCategoryId,
            error:false,
            errorMessage:'Please select the category'
        },
        title:{
            value: isUpdate ? updateCategoryItem.title : '',
            error:false,
            errorMessage:'You must enter title'
        },
        desc:{
            value: isUpdate ? updateCategoryItem.desc : '',
            error:false,
            errorMessage:'You must enter description'
        },
        iconUrl:{
            value: isUpdate ? updateCategoryItem.iconUrl : '',
            error:false,
            errorMessage:'You must enter icon name'
        }
    };

    const [categoryValues, setCategoryValues] = useState(categoryFormValues);

    React.useEffect(() => {
        if (addCategoryRef) {
            addCategoryRef.current = handleSubmitCategory;
        }
    }, [categoryValues])

    const handleCloseClick = () => {
        onClose && onClose(this);
    }

    const handleSubmitCategory = () => {

        const categoryFields = Object.keys(categoryValues);
        let newCategoryValues = {...categoryValues}

        let isFormError = false;
        for (let index = 0; index < categoryFields.length; index++) {
            const currentField = categoryFields[index];
            const currentValue = categoryValues[currentField].value;

            if(currentValue === ''){
                isFormError = true;
                newCategoryValues = {
                    ...newCategoryValues,
                    [currentField]:{
                        ...newCategoryValues[currentField],
                        error:true
                    }
                }
            }
        }

        setCategoryValues(newCategoryValues)
        if(isFormError) {
            return;
        }

        if(isUpdate) {
            editCategory();
        } else {
            addCategory();
        }

    }

    const addCategory = () => {

        setSuccessful(false);
        dispatch(addNewSubCategory({
            title: categoryValues.title.value,
            desc: categoryValues.desc.value,
            iconUrl: categoryValues.iconUrl.value,
            categoryId: categoryValues.categoryId.value}))
            .unwrap()
            .then(() => {
                setSuccessful(true);
                handleCloseClick();
            })
            .catch(() => {
                setSuccessful(false);
            })
    }

    const editCategory = () => {

        setSuccessful(false);
        let categoryDetailsUpdate = {
            id: updateCategoryItem.id,
            title: categoryValues.title.value,
            desc: categoryValues.desc.value,
            categoryId: categoryValues.categoryId.value,
            iconUrl: categoryValues.iconUrl.value };

        dispatch(updateSubCategory(categoryDetailsUpdate))
            .unwrap()
            .then(() => {
                setSuccessful(true);
                handleCloseClick();
                handlePositiveSubmit(categoryDetailsUpdate);
            })
            .catch(() => {
                setSuccessful(false);
            })
    }

    const handleChange = (event) => {
        const {name, value} = event.target;
        let errorValue = (value.length == 0);

        if(name == 'categoryId' && value == 0) {
            errorValue = true;
        }
        setCategoryValues({
            ...categoryValues,
            [name]:{
                ...categoryValues[name],
                value,
                error: errorValue
            }
        });
    }

    return (
        <div key={ categoryValues.categoryId.value } className="create-subcategory-section">

            <div className="create-subcategory-form">
                {
                    categoryValues.categoryId.value ?
                        (<Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={ categoryValues.categoryId.value }
                            label="Category"
                            name="categoryId"
                            error={categoryValues.categoryId.error}
                            helperText={categoryValues.categoryId.error && categoryValues.categoryId.errorMessage}
                            onChange={handleChange}
                        >
                            <MenuItem value="0"> Select category </MenuItem>)

                            {
                                categoryList ? categoryList.map((category) => {
                                    return (<MenuItem value={category.id} id={category.id}>
                                        <InboxIcon /> {category.title} </MenuItem>)
                                }) : ""
                            }

                        </Select>) : ""
                }


                <TextField
                    id="filled-basic"
                    label="Title"
                    variant="outlined"
                    error={categoryValues.title.error}
                    value={ categoryValues.title.value }
                    helperText={categoryValues.title.error && categoryValues.title.errorMessage}
                    onChange={handleChange}
                    name="title"/>
                <TextField
                    id="outlined-basic"
                    label="Description"
                    variant="outlined"
                    error={categoryValues.desc.error}
                    value={ categoryValues.desc.value }
                    helperText={categoryValues.desc.error && categoryValues.desc.errorMessage}
                    onChange={handleChange}
                    name="desc"/>
                <TextField
                    id="filled-basic"
                    label="icon"
                    variant="outlined"
                    error={categoryValues.iconUrl.error}
                    value={ categoryValues.iconUrl.value }
                    helperText={categoryValues.iconUrl.error && categoryValues.iconUrl.errorMessage}
                    onChange={handleChange}
                    name="iconUrl"/>
            </div>

            {/*<Button variant="contained" onClick={ handleSubmitCategory }>Add sub category</Button>*/}
        </div>
    );
}

export default CreateSubCategory
import {Component} from "react";

export default class StepOne extends Component {
    render() {
        if (this.props.currentStep !== 1) {
            return null;
        }
        return (
            <p>
                My expense track website is a user-friendly platform that allows individuals to
                easily track and manage their personal expenses. With a simple and intuitive interface,
                users can input their income and expenses, categorize them, and view their spending habits
                through personalized reports and charts.
            </p>
        );
    }
}
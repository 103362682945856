import React, {Component} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import {Divider} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {Button} from "@mui/material";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";


export default class WelcomeWizard extends Component {

    constructor() {
        super();
        this.state = {
            currentStep: 1,
            btn_prev: 'Previous',
            btn_next: 'Next',
            open: true
        };

        this._next = this._next.bind(this);
        this._prev = this._prev.bind(this);

        this.stepTwo = React.createRef();
    }

    _next(e) {
        let currentStep = this.state.currentStep;

        if (currentStep == 2) {
            // calling step two function
            this.stepTwo.handleCreateWallet();
            /*if(!this.stepTwo.handleCreateWallet()) {
                return;
            }*/
        }

        if (currentStep == 3) {
            // finish
            this.handleCloseClick(e);
        }
        // Make sure currentStep is set to something reasonable
        if (currentStep >= 2) {
            currentStep = 3;
        } else {
            currentStep = currentStep + 1;
        }

        this.setState({
            ...this.state,
            currentStep: currentStep,
            btn_next: (currentStep == 2) ? "Create wallet" : "Next"
        });


    }

    handleCloseClick = (e) => {
        document.body.classList.remove('modal-open');
        this.props.onClose && this.props.onClose(e);
    }

    _prev() {
        let currentStep = this.state.currentStep;
        if (currentStep <= 1) {
            currentStep = 1;
        } else {
            currentStep = currentStep - 1;
        }

        this.setState({
            ...this.state,
            currentStep: currentStep
        });
    }

    render() {

        if (!this.props.show) {
            return null;
        }

        return (
            <Dialog className="modal-container modal-main"
                    modal={true}
                    disableEnforceFocus
                    open={this.props.show}
                    width={200}
                    height={250}
            >

                <DialogTitle>Welcome to my Expense track</DialogTitle>
                <Divider/>
                <DialogContent>
                    <DialogContentText>

                    </DialogContentText>

                    <div>
                        <StepOne currentStep={this.state.currentStep}/>
                        <StepTwo
                            onRef={ref => (this.stepTwo = ref)}
                            currentStep={this.state.currentStep}
                        />
                        <StepThree currentStep={this.state.currentStep}/>
                    </div>

                </DialogContent>

                <DialogActions>
                    {
                        (this.state.currentStep != 1 && this.state.currentStep != 3) && <Button
                            color="secondary"
                            variant="text"
                            onClick={this._prev}>
                            {
                                this.state.btn_prev
                            }
                        </Button>
                    }

                    <Button
                        color="primary"
                        variant="text"
                        onClick={this._next}>
                        {
                            (this.state.currentStep == 3) ? "Finish" : this.state.btn_next
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

}


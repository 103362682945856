import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux";
import {Navigate, useNavigate} from "react-router-dom";
import {logout} from "../../store/slices/auth";
import SimpleDialog from "../../components/SimpleDialog";
import AddTransactionDialog from "../../components/transaction/AddTransactionDialog";
import {Alert} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import RecentTransactions from "../../components/transaction/RecentTransactions";
import {getRecentTransaction} from "../../store/slices/transaction";
import WelcomeWizard from "../../components/welcome/WelcomeWizard";
import MonthlyView from "../../components/transaction/MonthlyView";
import '../../assets/styles/home.scss';
import {allTransactionStats} from "../../store/slices/transactionStats";
import {LAST_MONTH_TRANSACTION, THIS_MONTH_TRANSACTION} from "../../utils/constants";
import ApexSparkLine from "../../components/reports/charts/ApexSparkLine";


const Home = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [modalConfirmation, setModalConfirmation] = useState(false);
    const addTransactionRef = React.useRef(null);
    const [openSnack, setOpenSnack] = useState(false);
    const [snackMessage, setSnackMessage] = useState("nothing");
    const [openWelcomeWizard, setOpenWelcomeWizard] = useState(false);
    const [loading, setLoading] = useState(true);

    const {user: currentUser} = useSelector((state) => state.auth);
    const {user: userProfile} = useSelector((state) => state.userProfile);
    const {settings: userSettings} = useSelector((state) => state.userProfile);
    const {defaultWallet: walletList} = useSelector((state) => state.wallet);
    const {lastMonthTotalSpent, currentMonthTotalSpent, lastFewDaysList} = useSelector(allTransactionStats);

    useEffect(() => {
        if (currentUser) {
            //fetchAllData();
        }
    }, [dispatch]);

    if (currentUser) {
        console.log("User Logged-in ---> moving to Home");
    } else {
        console.log("User Not Logged-in ---> moving to Signin/SignUp");
        return <Navigate to="/signin"/>
    }

    const handleLogout = () => {
        dispatch(logout());
        navigate('/signin');
    }

    const handleSubmit = (e) => {
        console.log("submit");
        //toggleConfirmationModal(e);
    }

    const toggleConfirmationModal = () => {
        setModalConfirmation(!modalConfirmation);
    }

    const toggleWelcomeWizardModal = () => {
        setOpenWelcomeWizard(!openWelcomeWizard);
    }

    const snackState = (value) => {
        setOpenSnack(value)
    }

    const settingSnackMessage = (value) => {
        setSnackMessage(value)
    }

    const handleCloseSnack = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnack(false);
    };

    const fetchRecentTransaction = (walletId) => {
        dispatch(getRecentTransaction({walletId}))
            .unwrap()
            .then((response) => {
                console.log("Recent transaction loaded");
                console.log(response.data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const handleMonthlyViewClick = (page) => {
        console.log("handleMonthlyViewClick clicked");
        navigate('/Transactions/Page/' + page);
    };

    return (
        <div className="home-container header-top-padding">

            {


                <div>
                    {/*
                        <button
                            type="button"
                            onClick={toggleConfirmationModal}
                            className="form-control btn btn-primary">
                            Add transaction
                        </button>
*/}

                    <div className="monthly-stats">

                        <MonthlyView
                            monthName="This month"
                            totalSpent={currentMonthTotalSpent}
                            currency={userSettings ? userSettings.currency : ''}
                            handleClick={() => handleMonthlyViewClick(THIS_MONTH_TRANSACTION)}
                        />

                        <MonthlyView
                            monthName="Last month"
                            totalSpent={lastMonthTotalSpent}
                            currency={userSettings ? userSettings.currency : ''}
                            handleClick={() => handleMonthlyViewClick(LAST_MONTH_TRANSACTION)}
                        />


                    </div>
                    {/*<div className="monthly-stats">
                        <ApexSparkLine />
                    </div>*/}

                    {
                        lastFewDaysList ? lastFewDaysList.length > 0 ?
                            <RecentTransactions transactionList={lastFewDaysList}/> :
                            <span className="no-recent-transaction">No recent transactions</span> : ''
                    }

                    {
                        (userProfile &&
                            <WelcomeWizard
                                show={openWelcomeWizard}
                                onClose={toggleWelcomeWizardModal}/>)
                    }


                    <SimpleDialog
                        title="Add new transaction"
                        subtitle="Please add the transaction details below :"
                        positiveBtn="Save"
                        negativeBtn="Cancel"
                        onClose={toggleConfirmationModal}
                        positiveClick={() => addTransactionRef.current()}
                        show={modalConfirmation}>
                        {(<div>
                            <AddTransactionDialog addTransactionRef={addTransactionRef}
                                                  onClose={toggleConfirmationModal}
                                                  snackbarStat={snackState} snackMessage={settingSnackMessage}/>
                        </div>)}
                    </SimpleDialog>

                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        open={openSnack}
                        autoHideDuration={4000}
                        onClose={handleCloseSnack}
                    >
                        <Alert
                            onClose={handleCloseSnack}
                            severity="success" sx={{width: '100%'}}>
                            {snackMessage}
                        </Alert>
                    </Snackbar>
                </div>
            }
        </div>);
}

export default Home;
import Box from "@mui/material/Box";
import {LinearProgress} from "@mui/material";
import '../../assets/styles/loadingscreen.scss';

const LoadingScreen = () => {
    return (<div className="loading-screen-section">

            <div className="loading-content">
                <span className="title">
                    LOADING...
                </span>
                <Box className="loading-container">
                    <LinearProgress/>
                </Box>
            </div>
        </div>
    )
}

export default LoadingScreen;
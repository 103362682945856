import axios from "axios";
import authHeader from "./auth-header";
import {DOMAIN_NAME} from "../utils/constants";

const API_URL = DOMAIN_NAME + "/api/transaction/";
const getLastFewDaysList = (walletId, filter) => {
    console.log("getLastFewDaysList axios");
    return axios.get(API_URL + "wallet/" + walletId +"/?params="+filter, { headers: authHeader() });
};

const getLastXMonthsList = (walletId, filter) => {
    console.log("getLastXMonthsList axios");
    return axios.get(API_URL + "search/wallet/" + walletId +"/param?params="+filter, { headers: authHeader() });
};

const searchTransactionData = (walletId, filter) => {
    console.log("searchTransactionData axios");
    return axios.get(API_URL + "search/wallet/" + walletId +"/param?params="+filter, { headers: authHeader() });
};


const transactionStatsService = {
    getLastFewDaysList,
    getLastXMonthsList,
    searchTransactionData
}
export default  transactionStatsService;
import '../../assets/styles/reports/otherCategoryItem.scss'
import React from "react";
import moment from "moment/moment";

const OtherCategoryItem = ({transaction, currency}) => {


    let dateFormat = 'DD-MMM';
    let testDateUtc = moment.utc(transaction.paidOn);
    let localDate = testDateUtc.local();

    return (
        <div className="other-category-simple-item-section" key={transaction.id}>
            <div className="category-item">
                <span> {transaction.spendAt} </span>
                <span> {localDate.format(dateFormat)} </span>
            </div>
            <div className="amount">
                {
                    transaction.isExclude ?
                        <span className="amount-exclude">{currency} {transaction.amount}</span> :
                        (transaction.transactionType == 0 ? <span>-{currency} {transaction.amount}</span> :
                            <span className="amount-credit">{currency} {transaction.amount}</span>)
                }
            </div>
        </div>)
}

export default OtherCategoryItem;
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import CategoryService from "../../services/category.service";
import {setMessage} from "./message";
import {setSubCategory} from "./subCategory";
import ReminderService from "../../services/reminder.service";
import {setAllCategory} from "./category";
import TransactionService from "../../services/transaction.service";
import {ERROR_BAD_REQUEST} from "../../utils/constants";
import {logout} from "./auth";
import {calculateDaywiseAmountSpent, getNextReminderDaysCount, updatedReminderList} from "../../utils/utility.service";


export const addNewReminder = createAsyncThunk(
    "reminder/addNewReminder",
    async ({userId, categoryId, title, reminderType, startDate, endDate, state, remark, upcomingReminder}, thunkAPI) => {

        try {
            const response = await ReminderService.addNewReminder(userId, categoryId, title, reminderType, startDate, endDate, state, remark, upcomingReminder);
            thunkAPI.dispatch(setMessage(response.data.message));
            if (response && response.data && response.data.id) {
                debugger;

                thunkAPI.dispatch(setReminder({
                    id: response.data.id,
                    categoryId, title, reminderType, startDate, endDate, state, remark, upcomingReminder
                }));
            }
            return response.data;
        } catch (error) {
            console.log(error);
            const message =
                (error.response && error.response.data && error.response.data.errorMessage) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);

export const updateReminder = createAsyncThunk(
    "reminder/addNewReminder",
    async ({id, categoryId, title, reminderType, startDate, endDate, state, remark, upcomingReminder}, thunkAPI) => {

        try {
            const response = await ReminderService.updateReminder(id, categoryId, title, reminderType, startDate, endDate, state, remark, upcomingReminder);
            thunkAPI.dispatch(setMessage(response.data.message));
            if (response && response.data) {
                thunkAPI.dispatch(setReminder({
                    id,
                    categoryId,
                    title,
                    reminderType,
                    startDate,
                    endDate,
                    state,
                    remark,
                    upcomingReminder
                }));
            }
            return response.data;
        } catch (error) {
            console.log(error);
            const message =
                (error.response && error.response.data && error.response.data.errorMessage) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);

export const getAllReminder = createAsyncThunk(
    "reminder/getAllReminder",
    async ({userId}, thunkAPI) => {
        try {
            const response = await ReminderService.getAllReminderList(userId);
            const modifiedList = updatedReminderList(response.data);
            thunkAPI.dispatch(setAllReminderList(modifiedList));
            return response.data;
        } catch (error) {
            console.log("direct error, it may token expired");
            if (error.response) {
                return error.response;
            }
            console.log(error);
        }
    }
)

export const deleteReminder = createAsyncThunk(
    "reminder/deleteReminder",
    async ({id},thunkAPI) => {
        try {
            debugger;
           const response = await ReminderService.deleteReminder(id);
            thunkAPI.dispatch(updateReminderListAfterDelete({id}));
           return response.data;
        } catch (error) {
            console.log("direct error, it may token expired");
            if(error.response && error.response.status == ERROR_BAD_REQUEST) {
                thunkAPI.dispatch(logout());
            }
            console.log(error);
        }
    }
)

const initialState = {};

const reminderSlice = createSlice({
    name: "reminder",
    initialState,
    reducers: {
        setAllReminderList: (state, action) => {
            return {reminderList: action.payload};
        },
        setReminder: (state, action) => {
            if (action.payload.id) {
                debugger;
                let index = state.reminderList ? state.reminderList.findIndex(rem => rem.id === action.payload.id) : -1;
                if (index != -1) {

                    let newArray = [...state.reminderList];
                    if(action.type == "DELETE") {
                        newArray.splice(index, 1);
                        return {
                            reminderList:  newArray
                        }
                    }
                    newArray[index] = { ...action.payload,
                        remainingDaysCount: getNextReminderDaysCount(action.payload.upcomingReminder)
                    };
                    return {
                        reminderList: newArray
                    }
                } else if (state.reminderList == undefined) {
                    // first time add reminder
                    return {reminderList: { ...action.payload,
                            remainingDaysCount: getNextReminderDaysCount(action.payload.upcomingReminder)
                        }};
                }
            }

            let newlyAddedList = [...state.reminderList, { ...action.payload }];
            const modifiedList = updatedReminderList(newlyAddedList);

            return {reminderList: modifiedList};
        },
        updateReminderListAfterDelete: (state, action) => {

            // Delete and update action
            if (action.payload.id) {
                debugger;
                let index = state.reminderList ? state.reminderList.findIndex(rem => rem.id === action.payload.id) : -1;
                if (index != -1) {
                    let newArray = [...state.reminderList];
                        newArray.splice(index, 1);
                        return {
                            reminderList:  newArray
                        }
                } else if (state.reminderList == undefined) {
                    return {reminderList: [action.payload]};
                }
            }
        }
    }
})

export const allReminderList = (state) => {
    return state.reminder;
}

const {reducer, actions} = reminderSlice;

export const {setAllReminderList, setReminder, updateReminderListAfterDelete} = actions;
export default reducer;
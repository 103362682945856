import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/styles/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './store/index';
import { Provider } from 'react-redux';
import {GoogleOAuthProvider} from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(

    /*<React.StrictMode> toolbar component mount call twice */
    <GoogleOAuthProvider clientId="1039152179493-lm75ag60hc9t5jpgfak9m81uajlmqsvv.apps.googleusercontent.com">
        <Provider store={store} >
            <App/>
        </Provider>
    </GoogleOAuthProvider>
    /*</React.StrictMode>*/
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, {useState} from "react"
import {Checkbox, FormControlLabel, FormGroup, FormHelperText, Link, Select, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import {LocalizationProvider, MobileDatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {allCategoryList, getAllCategory} from "../../store/slices/category";
import {useDispatch, useSelector} from "react-redux";
import {
    addNewTransaction,
    allTransactionList,
    editTransaction,
    setLatestTransactionHistory
} from "../../store/slices/transaction";
import {allSubCategoryList, getAllSubCategory} from "../../store/slices/subCategory";
import MenuItem from "@mui/material/MenuItem";
import {
    addNewTransactionToList,
    getDefaultWallet,
    getSubCategoryListByCategory,
    getTitleFromCategory
} from "../../utils/utility.service";
import '../../assets/styles/transactions/addTransactionDialog.scss';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import {updateWalletBalance} from "../../store/slices/wallet";
import {
    TRANSACTION_OPERATION_ADD,
    TRANSACTION_OPERATION_UPDATE,
    TRANSACTION_TYPE_CREDIT,
    TRANSACTION_TYPE_WITHDRAW
} from "../../utils/constants";
import {updateTransactionList} from "../../store/slices/transactionStats";

const AddTransactionDialog = React.forwardRef((props, ref) => {

    const {onClose, enableProgress, snackbarStat, snackMessage, isUpdate, transactionView, updateInfo} = props;

    const [datePicker, setDatePicker] = React.useState(dayjs());
    const {message} = useSelector((state) => state.message);
    const dispatch = useDispatch();
    const [successful, setSuccessful] = useState(false);
    const [addMoreDetails, setAddMoreDetails] = useState(false);
    const [enableOther, setEnableOther] = useState(false);
    const [loading, setLoading] = useState(false);

    const {categoryList} = useSelector(allCategoryList);
    const {subCategoryList} = useSelector(allSubCategoryList);
    const {wallet: walletList} = useSelector((state) => state.wallet);
    const {defaultWallet} = useSelector((state) => state.wallet);
    const [filterSubCategoryList, setFilterSubCategoryList] = React.useState(null);
    const {transactionList} = useSelector(allTransactionList);


    const formInitialValues = {
        wallet: {
            value: transactionView ? transactionView.walletId : walletList ? getDefaultWallet(walletList).id : '',
            error: false,
            errorMessage: 'Enter wallet id',
            required: true
        },
        category: {
            value: transactionView ? transactionView.categoryId : 0,
            error: false,
            required: true
        },
        subcategory: {
            value: 0,
            required: true
        },
        amount: {
            value: transactionView ? transactionView.amount : '',
            error: false,
            errorMessage: 'Enter amount value',
            required: true
        },
        spentAt: {
            value: transactionView ? transactionView.spendAt : '',
            error: false,
            errorMessage: 'Enter where you spend',
            required: false
        },
        paidFrom: {
            value: transactionView ? transactionView.paidType : '',
            required: false
        },
        location: {
            value: transactionView ? transactionView.location : '',
            required: false
        },
        note: {
            value: transactionView ? transactionView.note : '',
            required: false
        },
        isImportant: {
            value: transactionView ? transactionView.isImportant : false,
            required: false
        },
        date: {
            value: transactionView ? (new Date(transactionView.paidOn)) : datePicker,
            required: false
        },
        isExclude: {
            value: transactionView ? transactionView.isExclude : false,
        },
        transactionType: {
            value: transactionView ? transactionView.transactionType : 0,
            required: false
        }
    };

    const [formValues, setFormValues] = useState(formInitialValues);

    React.useEffect(() => {
        if (ref) {
            console.log(ref);
            ref.current = handleSubmitTransaction;
        }
    }, [formValues])

    React.useEffect(() => {
        //dispatch(getAllCategory());
        //dispatch(getAllSubCategory());
        //fetchAllData();

        if (transactionView && !filterSubCategoryList) {
            prefillTransactionForUpdate(transactionView);
            setAddMoreDetails(true);
        }
    }, [dispatch]);

    const fetchAllData = () => {
        setLoading(true);

        if (transactionView && !filterSubCategoryList) {
            prefillTransactionForUpdate(transactionView);
            setLoading(false);
        } else {
            fetchAllMainCategory();
        }


    }

    const fetchAllMainCategory = () => {

        dispatch(getAllCategory())
            .unwrap()
            .then((category) => {
                //console.log("All main category fetched");
                if (category) {
                    fetchAllSubCategory();
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const fetchAllSubCategory = () => {

        dispatch(getAllSubCategory())
            .unwrap()
            .then((category) => {
                console.log("All sub-category fetched");
                if (category) {
                    if (transactionView && !filterSubCategoryList) {
                        prefillTransactionForUpdate(transactionView);
                    }

                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const handleDateChange = (newValue) => {
        setDatePicker(newValue);

        setFormValues({
            ...formValues,
            date: {
                value: new Date(newValue)
            }
        });
    };

    const prefillTransactionForUpdate = (transaction) => {
        setFilterSubCategoryList(getSubCategoryListByCategory(transaction.categoryId, subCategoryList));
        enableRequiredFlagForSpendAt();

        setFormValues({
            ...formValues,
            subcategory: {
                value: transactionView ? transactionView.subCategoryId : 0,
            }
        });
    }


    const handleChangeCheckBoxImportant = (event) => {

        setFormValues({
            ...formValues,
            isImportant: {
                value: event.target.checked
            }
        });
    }

    const handleChangeCheckBoxExclude = (event) => {
        setFormValues({
            ...formValues,
            isExclude: {
                value: event.target.checked
            }
        });
    }

    const handleChange = (event) => {

        const {name, value} = event.target;
        const errorValue = value.length == 0;

        setFormValues({
            ...formValues,
            [name]: {
                ...formValues[name],
                value,
                error: errorValue
            }
        });

        if (name == 'category' && value) {

            // resetting sub-category options to first selection
            setFormValues({
                ...formValues,
                category: {
                    ...formValues["category"],
                    value,
                },
                subcategory: {
                    value: 0
                },
                transactionType: {
                    value: categoryList.filter((cat) => cat.id == value)[0].transactionType
                }
            });
            setFilterSubCategoryList(getSubCategoryListByCategory(event.target.value, subCategoryList));
            setEnableOther(false);
        } else if (name == 'subcategory') {
            if (getTitleFromCategory(value, filterSubCategoryList) === 'Other') {
                // add required flag for spendAt
                enableRequiredFlagForSpendAt();
            } else
                setEnableOther(false);
        }

        console.log(formValues)
    }

    const enableRequiredFlagForSpendAt = () => {
        setEnableOther(true);
    }
    const handleCloseClick = () => {
        onClose && onClose(this);
    }

    const handleEnableProgressForDialog = () => {
        enableProgress && enableProgress(this);
    }

    const handleSubmitTransaction = (e) => {

        console.log(formValues);

        const formFields = Object.keys(formValues);
        let newFormValues = {...formValues}

        let isFormError = false;
        for (let index = 0; index < formFields.length; index++) {
            const currentField = formFields[index];
            const currentValue = formValues[currentField].value;

            if ((currentValue === '' && formValues[currentField].required) ||
                currentValue == '0' && formValues[currentField].required &&
                (currentField == 'category' || currentField == 'subcategory')
            ) {
                isFormError = true;
                newFormValues = {
                    ...newFormValues,
                    [currentField]: {
                        ...newFormValues[currentField],
                        error: true
                    }
                }
            }
        }

        setFormValues(newFormValues);
        if (isFormError) {
            return;
        }

        handleEnableProgressForDialog();
        setSuccessful(false);
        if (isUpdate) {
            updateTransaction();
        } else {
            addTransaction();
        }
    }

    const addTransaction = () => {

        let newTransaction = {
            walletId: formValues.wallet.value,
            categoryId: formValues.category.value,
            subCategoryId: formValues.subcategory.value,
            amount: formValues.amount.value,
            spendAt: formValues.spentAt.value,
            note: formValues.note.value,
            location: formValues.location.value,
            isImportant: formValues.isImportant.value,
            isExclude: formValues.isExclude.value,
            transactionType: formValues.transactionType.value,
            paidType: 1,
            paidOn: formValues.date.value
        };

        dispatch(addNewTransaction(newTransaction))
            .unwrap()
            .then((response) => {

                console.log("transaction added");
                setSuccessful(true);
                snackMessage(response.message);
                snackbarStat(true);
                //handleCloseClick();

                // update default wallet
                updateWalletTotalSpent(formValues.amount.value, formValues.transactionType.value);

                if (defaultWallet.id == formValues.wallet.value) {
                    let data = {
                        transaction: response,
                        operation: TRANSACTION_OPERATION_ADD
                    }
                    dispatch(updateTransactionList(data));
                }

                handleCloseClick();
            })
            .catch((err) => {
                console.log(err);
                setSuccessful(false);
                snackMessage("Unable to add this transaction")
                snackbarStat(true);
            });
    }

    const updateTransaction = () => {
        let transactionUpdatedInfo = {
            id: transactionView.id,
            walletId: formValues.wallet.value,
            categoryId: formValues.category.value,
            subCategoryId: formValues.subcategory.value,
            amount: formValues.amount.value,
            spendAt: formValues.spentAt.value,
            note: formValues.note.value,
            location: formValues.location.value,
            isImportant: formValues.isImportant.value,
            isExclude: formValues.isExclude.value,
            transactionType: formValues.transactionType.value,
            paidType: 1,
            paidOn: formValues.date.value
        }
        dispatch(editTransaction(transactionUpdatedInfo))
            .unwrap()
            .then(() => {
                console.log("transaction update");
                setSuccessful(true);
                updateInfo(transactionUpdatedInfo);
                //handleCloseClick();
                updateWalletTotalSpent(formValues.amount.value, formValues.transactionType.value);

                if (defaultWallet.id == formValues.wallet.value) {
                    let data = {
                        transaction: transactionUpdatedInfo,
                        operation: TRANSACTION_OPERATION_UPDATE
                    }
                    dispatch(updateTransactionList(data));
                }
                handleCloseClick();
            })
            .catch((err) => {
                console.log("upt err:"+err);
                setSuccessful(false);
                snackMessage("Unable to update this transaction")
                snackbarStat(true);
            })
    }

    const updateWalletTotalSpent = (transactionAmount, operation) => {

        if (!formValues.isExclude.value) {
            let updatedValue = transactionAmount;

            if(transactionView && isUpdate) {
                updatedValue = transactionAmount - transactionView.amount;
            }

            dispatch(updateWalletBalance(
                {
                    amount: updatedValue,
                    transactionType: operation
                }))
        }
    }

    return (
        <div className="add-update-transaction-dialog">

            {
                loading ?
                    <p>Loading...</p> :
                    <div>

                        <div className="transaction-form-header">

                            {/*                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Wallet"
                                sx={{width: '20%'}}
                                value={formValues.wallet.value}
                                name="wallet"
                                error={formValues.wallet.error}
                                helperText={formValues.wallet.error && formValues.wallet.errorMessage}
                                onChange={handleChange}
                            >
                                {
                                    walletList ? walletList.map((wallet) => {

                                        return (<MenuItem value={wallet.id} id={wallet.id}
                                                          key={wallet.id}> {wallet.title} </MenuItem>)
                                    }) : ""
                                }
                            </Select>*/}

                            <TextField select label="Select"
                                       className="field-wallet"
                                       label="Wallet"
                                       value={formValues.wallet.value}
                                       name="wallet"
                                       error={formValues.wallet.error}
                                       helperText={formValues.wallet.error && formValues.wallet.errorMessage}
                                       onChange={handleChange}
                            >
                                {
                                    walletList ? walletList.map((wallet) => {

                                        return (<MenuItem value={wallet.id}
                                                          id={wallet.id}
                                                          key={wallet.id}>
                                            <img className="wallet-icon" alt="wallet"
                                                 src={require('../../assets/images/ic_wallet.png')}/>{ wallet.title}
                                        </MenuItem>)
                                    }) : ""
                                }
                            </TextField>

                            <TextField select label="Select"
                                       className="field-category"
                                       label="Category"
                                       value={formValues.category.value}
                                       name="category"
                                       error={formValues.category.error}
                                       helperText={formValues.category.error && formValues.category.errorMessage}
                                       onChange={handleChange}
                            >
                                <MenuItem value="0"> Category </MenuItem>)
                                {
                                    categoryList ? categoryList.map((category) => {

                                        let image_path = '';
                                        try {
                                            image_path = require('../../assets/images/' + category.iconUrl + '.png');
                                        } catch(err){
                                            image_path = require('../../assets/images/ic_default.png');  //set default image path
                                        }
                                        return (<MenuItem value={category.id} id={category.id}
                                                          key={category.id}><img className="select-category-icon" alt="category-icons" src={ image_path }/>  {category.title} </MenuItem>)
                                    }) : ""
                                }
                            </TextField>

                            {/*                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Category"
                                sx={{width: '20%'}}
                                value={ formValues.category.value }
                                name="category"
                                error={formValues.category.error}
                                helperText={formValues.category.error && formValues.category.errorMessage}
                                onChange={handleChange}
                            >
                                <MenuItem value="0"> Category </MenuItem>)
                                {
                                    categoryList ? categoryList.map((category) => {

                                        return (<MenuItem value={category.id} id={category.id}
                                                          key={category.id}> {category.title} </MenuItem>)
                                    }) : ""
                                }

                            </Select>*/}


                            <TextField
                                className="field-amount"
                                type="number"
                                id="outlined-basic"
                                label="Amount"
                                name="amount"

                                value={formValues.amount.value}
                                onChange={handleChange}
                                maxLength={5}
                                error={formValues.amount.error}
                                helperText={formValues.amount.error && formValues.amount.errorMessage}
                                variant="outlined"
                                InputProps={{
                                    inputProps: { min: 0 }
                                }}
                                onKeyPress={(event) => {
                                    if (event?.key === '-' || event?.key === '+') {
                                        event.preventDefault();
                                    }
                                }}
                            />

                            {/*                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Sub-category"
                                name="subcategory"
                                value={formValues.subcategory.value}
                                error={formValues.subcategory.error}
                                helperText={formValues.subcategory.error && formValues.subcategory.errorMessage}
                                onChange={handleChange}
                            >
                                <MenuItem value="0"> Sub-category </MenuItem>)
                                {
                                    filterSubCategoryList ? filterSubCategoryList.map((category) => {
                                        return (<MenuItem value={category.id} id={category.id}
                                                          key={category.id}> {category.title} </MenuItem>)
                                    }) : ""
                                }
                            </Select>*/}

                            <TextField select label="Select"
                                       className="field-sub-category"
                                       id="demo-simple-select"
                                       label="Sub-category"
                                       name="subcategory"
                                       value={formValues.subcategory.value}
                                       error={formValues.subcategory.error}
                                       helperText={formValues.subcategory.error && formValues.subcategory.errorMessage}
                                       onChange={handleChange}
                            >
                                <MenuItem value="0"> Sub-category </MenuItem>)
                                {
                                    filterSubCategoryList ? filterSubCategoryList.map((category) => {
                                        return (<MenuItem value={category.id} id={category.id}
                                                          key={category.id}> {category.title} </MenuItem>)
                                    }) : ""
                                }
                            </TextField>


                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <MobileDatePicker
                                    className="field-date"
                                    label="Date"
                                    inputFormat="MM/DD/YYYY"
                                    value={formValues.date.value}
                                    onChange={handleDateChange}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>

                            {
                                enableOther && <TextField
                                    className="field-spend"
                                    id="outlined-basic"
                                    label="Spent at"
                                    name="spentAt"
                                    value={formValues.spentAt.value}
                                    error={formValues.spentAt.error}
                                    onChange={handleChange}
                                    inputProps={{maxLength: 20}}
                                    variant="outlined"/>
                            }


                        </div>

                        {
                            !addMoreDetails &&
                            <Link
                                className="add-more-details"
                                component="button"
                                variant="body2"
                                onClick={() => setAddMoreDetails(!addMoreDetails)}
                            >
                                + more details
                            </Link>
                        }

                        {
                            addMoreDetails &&
                            <div className="transaction-form-extra">
                                <TextField
                                    className="field-paidFrom"
                                    id="filled-basic"
                                    label="Paid from"
                                    name="paidFrom"
                                    value={formValues.paidFrom.value}
                                    onChange={handleChange}
                                    variant="outlined"/>

                                <TextField
                                    className="field-location"
                                    id="outlined-basic"
                                    label="Location"
                                    name="location"
                                    value={formValues.location.value}
                                    onChange={handleChange}
                                    variant="outlined"/>


                                <TextField
                                    className="field-note"
                                    id="filled-basic"
                                    label="Note"
                                    name="note"
                                    inputProps={{maxLength: 60}}
                                    value={formValues.note.value}
                                    onChange={handleChange}
                                    variant="outlined"/>

                                <FormGroup className="field-important">
                                    <FormControlLabel
                                        control={<Checkbox name="isImportant"
                                                           checked={formValues.isImportant.value}
                                                           value={formValues.isImportant.value}
                                        />}
                                        label="Important"
                                        onChange={handleChangeCheckBoxImportant}
                                    />
                                    <FormHelperText className="checkbox-sub-text">
                                        Add to favourite & Setup reminder
                                    </FormHelperText>
                                </FormGroup>
                                <FormGroup className="field-exclude">
                                    <FormControlLabel
                                        control={<Checkbox name="isExclude"
                                                           checked={formValues.isExclude.value}
                                                           value={formValues.isExclude.value}
                                        />}
                                        label="Exclude transaction"
                                        onChange={handleChangeCheckBoxExclude}
                                    />
                                    <FormHelperText className="checkbox-sub-text">Do not include this transaction in
                                        report/wallet</FormHelperText>
                                </FormGroup>

                            </div>
                        }
                    </div>
            }
        </div>
    )
});

export default AddTransactionDialog;
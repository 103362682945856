import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { setMessage} from "./message";
import AuthService from "../../services/auth.service";

const user = JSON.parse(localStorage.getItem("user"));

export const signup = createAsyncThunk(
    "auth/signup",
    async ({ firstName, lastName, email, password}, thunkAPI) => {
        try {
            const response = await AuthService.signup(firstName, lastName, email, password);
            thunkAPI.dispatch(setMessage(response.data.message));
            return response.data;
        } catch (error) {
            console.log(error);
            const message =
                (error.response && error.response.data && error.response.data.errorMessage) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);

export const signin = createAsyncThunk(
    "auth/signin",
    async ( { username, password, firstName, lastName, email, pictureUrl, signInSource, uniqueId }, thunkAPI) => {
        try {
            const data = await AuthService.signin( username, password, firstName, lastName, email, pictureUrl, signInSource, uniqueId);
            thunkAPI.dispatch(setMessage(data.message));
            return { user : data};
        } catch (error) {
            const message = ( error.response && error.response.data && error.response.data.errorMessage)
                    || error.message
                    || error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);

export const logout = createAsyncThunk("auth/logout", async() => {
    await AuthService.logout();
});

const initialState = user ? { isLoggedIn: true, user } : { isLoggedIn: false, user: null};

const authSlice = createSlice({
    name: "auth",
    initialState,
    extraReducers: {
        [signup.fulfilled]: (state, action) => {
            console.log("signup fulfilled");
            state.isLoggedIn = false;
        },
        [signup.rejected]: (state, action) => {
            console.log("signup rejected");
            state.isLoggedIn = false;
        },
        [signin.fulfilled]: (state, action) => {
            console.log("signin fulfilled");
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [signin.rejected]: (state, action) => {
            console.log("signin rejected");
            state.isLoggedIn = false;
            state.user = null;
        },
        [logout.fulfilled]: (state, action) => {
            console.log("logout fulfilled");
            state.isLoggedIn = false;
            state.user = null;
        }
    }
});

const {reducer} = authSlice;
export default reducer;


import CreateCategory from "../../components/category/CreateCategory";
import CreateSubCategory from "../../components/category/CreateSubCategory";
import {allCategoryList, deleteCategory, getAllCategory, setAllCategory} from "../../store/slices/category";
import {
    allSubCategoryList,
    deleteSubCategory,
    getAllSubCategory,
    setAllSubCategory
} from "../../store/slices/subCategory";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react"
import '../../assets/styles/categoryPage.scss';
import {Alert, Avatar, Button, List, ListItem, ListItemAvatar, ListItemText, Stack, Tooltip} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SimpleDialog from "../../components/SimpleDialog";
import {getSubCategoryListByCategory} from "../../utils/utility.service";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import {STATUS_FAILED, STATUS_SUCCESS} from "../../utils/constants";
import Typography from "@mui/material/Typography";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from "@mui/material/IconButton";
import FolderIcon from '@mui/icons-material/Folder';
import AddIcon from '@mui/icons-material/Add';
import Snackbar from "@mui/material/Snackbar";

const Category = () => {

    const dispatch = useDispatch();
    const {categoryList} = useSelector(allCategoryList);
    const {subCategoryList} = useSelector(allSubCategoryList);
    const [categoryValue, setCategoryValue] = React.useState(0);

    const [categoryModal, setCategoryModal] = useState(false);
    const [subCategoryModal, setSubCategoryModal] = useState(false);
    const addCategoryRef = React.useRef(null);
    const [filterSubCategoryList, setFilterSubCategoryList] = React.useState(null);

    const [updateCategoryItem, setUpdateCategoryItem] = React.useState(null);
    const [updateCategoryModal, setUpdateCategoryModal] = useState(false);
    const [updateSubCategoryModal, setUpdateSubCategoryModal] = useState(false);
    const [updateSubCategoryItem, setUpdateSubCategoryItem] = React.useState(null);
    const [deleteModalConfirmation, setDeleteModalConfirmation] = useState(false);
    const [categoryDeleteModalConfirmation, setCategoryDeleteModalConfirmation] = useState(false);

    const [openSnack, setOpenSnack] = useState(false);
    const [snackSeverity, setSnackSeverity] = useState("success");
    const [snackMessage, setSnackMessage] = useState("nothing");

/*    useEffect(() => {

        if (!categoryList && userProfile) {
            dispatch(getAllCategory({ userId:userProfile.id}));
        }

        if (!subCategoryList && userProfile) {
            dispatch(getAllSubCategory({ userId:userProfile.id}));
        }
    }, [userProfile]);*/
    const handleCategoryChange = (event) => {

        const {name, value} = event.target;
        setCategoryValue(value);

        let selectedCategory = categoryList.filter((cat) => cat.id == value)[0];
        setUpdateCategoryItem(selectedCategory);

        setFilterSubCategoryList(getSubCategoryListByCategory(value, subCategoryList));
    }
    const toggleCategoryModal = () => {
        setCategoryModal(!categoryModal);
    }

    const addSubCategoryModal = (category) => {
        setCategoryValue(category.id);
        setSubCategoryModal(!subCategoryModal);
    }
    const toggleSubCategoryModal = () => {
        setSubCategoryModal(!subCategoryModal);
    }

    const updateMainCategoryModal = (category) => {
        setUpdateCategoryItem(category);
        toggleUpdateCategoryModal();
    }
    const toggleUpdateCategoryModal = () => {
        setUpdateCategoryModal(!updateCategoryModal);
    }
    const toggleUpdateSubCategoryModal = () => {
        setUpdateSubCategoryModal(!updateSubCategoryModal);
    }

    const onClickEditSubCategoryItem = (subCategory) => {

        console.log(subCategory);
        setUpdateSubCategoryItem(subCategory);
        setCategoryValue(subCategory.categoryId);
        setTimeout(() => {
            toggleUpdateSubCategoryModal();
        }, 100);
    }

    const toggleDeleteModalConfirmation = (subCategory) => {
        setDeleteModalConfirmation(!deleteModalConfirmation);

        if (subCategory) {
            setUpdateSubCategoryItem(subCategory);
        } else {
            setUpdateSubCategoryItem(null);
        }
    }
    const onClickDeleteSubCategoryItem = () => {

        //setUpdateSubCategoryItem(subCategory);
        setTimeout(() => {
            if (updateSubCategoryItem.id) {
                // deleting from database
                deleteSubCategoryItem(updateSubCategoryItem.id);
            }
        }, 100);
    }

    const deleteSubCategoryItem = (id) => {
        dispatch(deleteSubCategory({id}))
            .unwrap()
            .then((response) => {

                if (response && response.status == STATUS_SUCCESS) {
                    console.log(response.message);
                    refreshSubCategoryListAfterDelete();
                    setSnackSeverity("success");
                } else if (response && response.status == STATUS_FAILED) {
                    setSnackSeverity("warning")
                }
                setSnackMessage(response.message);
                setOpenSnack(true);

                toggleDeleteModalConfirmation();
            })
            .catch(() => {
            })
    }

    const refreshSubCategoryListAfterDelete = () => {

        // delete category from memory list
        let indexFound = subCategoryList.findIndex(subCat => subCat.id === updateSubCategoryItem.id);
        Object.freeze(subCategoryList);

        const newSubCategoryList = [...subCategoryList];
        newSubCategoryList.splice(indexFound, 1); // deleting one element
        dispatch(setAllSubCategory(newSubCategoryList))

        // deleting from filter list
        //let indexFoundFilter = filterSubCategoryList.findIndex(subCat => subCat.id === updateSubCategoryItem.id);
        //filterSubCategoryList.splice(indexFoundFilter, 1);
    }

    const refreshCategoryListAfterDelete = () => {
        // delete category from memory list
        let indexFound = categoryList.findIndex(subCat => subCat.id === updateCategoryItem.id);
        Object.freeze(subCategoryList);

        const newCategoryList = [...categoryList];
        newCategoryList.splice(indexFound, 1); // deleting one element
        dispatch(setAllCategory(newCategoryList))
    }

    const handlePositiveSubmit = (updatedCategory) => {

        console.log("Printing  ");
        console.log(categoryValue);

    }

    const deleteCategoryItem = (id) => {
        dispatch(deleteCategory({id}))
            .unwrap()
            .then((response) => {
                if (response && response.status == STATUS_SUCCESS) {
                    console.log(response.message);
                    refreshCategoryListAfterDelete();
                    setSnackSeverity("success");
                } else if (response && response.status == STATUS_FAILED) {
                    setSnackSeverity("warning")
                }
                setSnackMessage(response.message);
                setOpenSnack(true);
                toggleCategoryDeleteModalConfirmation();
            })
            .catch(() => {
            })
    }

    const onClickDeleteCategoryItem = () => {

        setTimeout(() => {
            if (updateCategoryItem.id) {
                // deleting category from database
                deleteCategoryItem(updateCategoryItem.id);
            }
        }, 100);
    }

    const toggleCategoryDeleteModalConfirmation = (category) => {
        setCategoryDeleteModalConfirmation(!categoryDeleteModalConfirmation);

        if (category) {
            setUpdateCategoryItem(category);
        } else {
            setUpdateCategoryItem(null);
        }
    }

    const handleCloseSnack = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpenSnack(false);
    };
    return (
        <div className="category-page-section header-top-padding">
            <div className="main-category-section">
                <span className="heading">Category List</span>
                <Button className="add-category-btn" variant="contained" startIcon={<AddCircleOutlineIcon/>}
                        onClick={toggleCategoryModal}>
                    Add category
                </Button>
                {
                    categoryList ?
                        categoryList.map((category) => {

                            let image_path = '';
                            try {
                                image_path = require('../../assets/images/' + category.iconUrl + '.png');
                            } catch(err){
                                image_path = require('../../assets/images/ic_default.png');  //set default image path
                            }

                            return (<Accordion className="accordion-main" key={category.id}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                                                  aria-controls="panel1bh-content"
                                                  id="panel1bh-header"
                                                  className="accordion-summary"
                                >
                                    <ListItemAvatar>
                                        <img className="category-icon" lt="category-icons" src={ image_path }/>
                                    </ListItemAvatar>
                                    <Typography className="category-title">{category.title}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Stack direction="row" spacing={1} className="main-category-action-icons">
                                        <Tooltip title="Add sub-category">
                                            <IconButton aria-label="Add sub-category" onClick={() => {
                                                addSubCategoryModal(category)
                                            }}>
                                                <AddIcon/>
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Modify category">
                                            <IconButton aria-label="modify category"
                                                        onClick={() => updateMainCategoryModal(category)}>
                                                <ModeEditIcon/>
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Delete category">
                                            <IconButton aria-label="add an alarm"
                                                        onClick={() => toggleCategoryDeleteModalConfirmation(category)}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        </Tooltip>

                                    </Stack>
                                    <List>

                                        {
                                            subCategoryList ? getSubCategoryListByCategory(category.id, subCategoryList).map((cat) => {
                                                return (
                                                    <ListItem
                                                        secondaryAction={
                                                            <IconButton
                                                                edge="end"
                                                                aria-label="delete"
                                                                onClick={() => {
                                                                    toggleDeleteModalConfirmation(cat)
                                                                }}>
                                                                <DeleteIcon/>
                                                            </IconButton>}>
                                                        <ListItemText primary={cat.title}/>
                                                        <Button edge="end"
                                                                aria-label="edit"
                                                                onClick={() => {
                                                                    onClickEditSubCategoryItem(cat)
                                                                }}>
                                                            <ModeEditIcon/>
                                                        </Button>
                                                    </ListItem>)
                                            }) : ""
                                        }
                                    </List>
                                </AccordionDetails>
                            </Accordion>)
                        }) : ""
                }

            </div>

            <SimpleDialog
                title="Add new category"
                subtitle="Please add the category details below :"
                positiveBtn="Save"
                negativeBtn="Cancel"
                onClose={toggleCategoryModal}
                positiveClick={() => addCategoryRef.current()}
                show={categoryModal}>
                {(<div>
                    <CreateCategory addCategoryRef={addCategoryRef}
                                    onClose={toggleCategoryModal}
                    />
                </div>)}
            </SimpleDialog>

            <SimpleDialog
                title="Add new sub-category"
                subtitle="Please add the sub-category details below :"
                positiveBtn="Save"
                negativeBtn="Cancel"
                onClose={toggleSubCategoryModal}
                positiveClick={() => addCategoryRef.current()}
                show={subCategoryModal}>
                {(<div>
                    <CreateSubCategory addCategoryRef={addCategoryRef}
                                       passCategoryId={categoryValue}
                                       onClose={toggleSubCategoryModal}
                    />
                </div>)}
            </SimpleDialog>

            <SimpleDialog
                title="Update category"
                subtitle="Please update the below category details:"
                positiveBtn="Update"
                negativeBtn="Cancel"
                onClose={toggleUpdateCategoryModal}
                positiveClick={() => addCategoryRef.current()}
                show={updateCategoryModal}>
                {(<div>
                    <CreateCategory addCategoryRef={addCategoryRef}
                                    updateCategoryItem={updateCategoryItem}
                                    isUpdate="true"
                                    handlePositiveSubmit={handlePositiveSubmit}
                                    onClose={toggleUpdateCategoryModal}
                    />
                </div>)}
            </SimpleDialog>

            <SimpleDialog
                title="Update sub-category"
                subtitle="Please update the below sub-category details:"
                positiveBtn="Update"
                negativeBtn="Cancel"
                onClose={toggleUpdateSubCategoryModal}
                positiveClick={() => addCategoryRef.current()}
                show={updateSubCategoryModal}>
                {(<div>
                    <CreateSubCategory
                        addCategoryRef={addCategoryRef}
                        updateCategoryItem={updateSubCategoryItem}
                        passCategoryId={categoryValue}
                        isUpdate="true"
                        handlePositiveSubmit={handlePositiveSubmit}
                        onClose={toggleUpdateSubCategoryModal}
                    />
                </div>)}
            </SimpleDialog>

            <SimpleDialog
                title="Delete confirmation"
                positiveBtn="Yes"
                negativeBtn="No"
                onClose={toggleDeleteModalConfirmation}
                positiveClick={onClickDeleteSubCategoryItem}
                show={deleteModalConfirmation}>
                {(<div>
                    Are you sure you want to delete this sub-category?
                </div>)}
            </SimpleDialog>


            <SimpleDialog
                title="Delete confirmation"
                positiveBtn="Yes"
                negativeBtn="No"
                onClose={toggleCategoryDeleteModalConfirmation}
                positiveClick={onClickDeleteCategoryItem}
                show={categoryDeleteModalConfirmation}>
                {(<div>
                    Are you sure you want to delete this category?
                </div>)}
            </SimpleDialog>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={openSnack}
                autoHideDuration={4000}
                onClose={handleCloseSnack}
            >
                <Alert
                    onClose={handleCloseSnack}
                    severity={snackSeverity}
                    sx={{width: '100%'}}>
                    {snackMessage}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default Category;
import {findTransactionDetails, getCategoryObject, getReminderTypeTitle} from "../../utils/utility.service";
import dayjs from "dayjs";
import '../../assets/styles/reminder/reminderDetails.scss';
import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {allCategoryList, getAllCategory} from "../../store/slices/category";
import {getAllSubCategory} from "../../store/slices/subCategory";
import {useDispatch, useSelector} from "react-redux";
import {allReminderList, deleteReminder} from "../../store/slices/reminder";
import {Button, Card, Divider} from "@mui/material";
import RepeatIcon from '@mui/icons-material/Repeat';
import EventIcon from '@mui/icons-material/Event';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import SimpleDialog from "../../components/SimpleDialog";
import {deleteTransaction} from "../../store/slices/transaction";
import {TRANSACTION_OPERATION_DELETE} from "../../utils/constants";
import {updateTransactionList} from "../../store/slices/transactionStats";
import AddReminderDialog from "./AddReminderDialog";

const ReminderDetails = () => {

    const [editReminderModal, setEditReminderModal] = useState(false);
    const [reminderView, setReminderView] = useState(undefined);
    const [reminderType, setReminderType] = useState(undefined);
    const [catImagePath, setCatImagePath] = useState(undefined);
    const [categoryTitle, setCategoryTitle] = useState(undefined);
    const {id} = useParams();
    const {reminderList} = useSelector(allReminderList);
    const {categoryList} = useSelector(allCategoryList);

    const [deleteModalConfirmation, setDeleteModalConfirmation] = useState(false);
    const addReminderRef = React.useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        loadDetails(id);
    }, [reminderList]);


    const loadDetails = (id) => {

        if (reminderList && reminderList.length > 0) {

            let remViewData = reminderList.filter((rem) => rem.id == id);
            let remView;
            if (remViewData.length > 0) {
                remView = remViewData[0];
            } else {
                return;
            }

            let categoryObj = getCategoryObject(remView.categoryId, categoryList);
            setCategoryTitle(categoryObj.title);

            let image_path = '';
            try {
                image_path = require('../../assets/images/' + categoryObj.iconUrl + '.png');
            } catch (err) {
                image_path = require('../../assets/images/ic_default.png');  //set default image path
            }

            setReminderView(remView);
            setReminderType(getReminderTypeTitle(remView.reminderType));
            setCatImagePath(image_path);
        }
    }

    const toggleDeleteModalConfirmation = () => {
        setDeleteModalConfirmation(!deleteModalConfirmation);
    }

    const handleDeleteTransaction = () => {
        dispatch(deleteReminder({id: reminderView.id}))
            .unwrap()
            .then(() => {
                toggleDeleteModalConfirmation();
                setTimeout(() => {
                    navigate("/Reminder/page");
                }, 100)

            })
            .catch((err) => {
                console.log(err);
            })
    }

    const toggleEditReminderModal = () => {
        setEditReminderModal(!editReminderModal);
    }

    return (<div className="reminder-details-section header-top-padding">

        {
            reminderView ?
                (
                    <Card className="card-details">
                        <div className="reminder-details-header">

                            <div className={`category-header ${!reminderView.state ? 'notificationItem-inactive' : ''}`}>
                                <div><img alt="category-icons" className="category-icons" src={catImagePath}/></div>
                                <div className="reminder-category-title">
                                    <span>{reminderView.title}</span>
                                    <span>{categoryTitle}</span>
                                </div>
                            </div>
                            <Divider/>

                            <div className="reminder-details">
                                <div>
                                    <span><EventIcon/>&nbsp;&nbsp;Start date: </span>
                                    <span>{dayjs(reminderView.startDate).format('DD-MMM-YYYY')}</span>
                                </div>
                                <div>
                                    <span><EventIcon/>&nbsp;&nbsp;End date: </span>
                                    {
                                        reminderView.endDate ?
                                            <span>{dayjs(reminderView.endDate).format('DD-MMM-YYYY')}</span> :
                                            <span>No end date</span>
                                    }
                                </div>
                                <div>
                                    <span><RepeatIcon/>&nbsp;&nbsp;Repeat: </span>
                                    <span>{reminderType}</span>
                                </div>
                                <div>
                                    <span><MailOutlineIcon/>&nbsp;&nbsp; Message: </span>
                                    <span>{reminderView.remark}</span>
                                </div>
                            </div>
                            <div className="reminder-upcoming">
                                {
                                    reminderView.state ?
                                        <div>
                                            <span>Upcoming reminder: </span><span>{dayjs(reminderView.upcomingReminder).format('DD-MMM-YYYY hh:mm A')}</span>
                                        </div> :
                                        <div><span className="error-message">This reminder is in inactive state. Change end date and repeat field to activate</span>
                                        </div>
                                }
                            </div>
                            <Divider className="bottom-divider"/>
                            <div className="content-details">
                                <div className="action-buttons">

                                    <Button className="edit-transaction"
                                            variant="text"
                                            startIcon={<ModeEditIcon/>}
                                            style={{color: "green"}}
                                            onClick={toggleEditReminderModal}>
                                        Edit
                                    </Button>
                                    <Button className="delete-transaction"
                                            variant="text"
                                            startIcon={<DeleteIcon/>}
                                            style={{color: "red"}}
                                            onClick={toggleDeleteModalConfirmation}>
                                        Delete
                                    </Button>
                                </div>
                            </div>

                        </div>
                    </Card>
                ) : 'NOT FOUND!'
        }


        <SimpleDialog
            title="Delete confirmation"
            positiveBtn="Yes"
            negativeBtn="No"
            onClose={toggleDeleteModalConfirmation}
            positiveClick={handleDeleteTransaction}
            show={deleteModalConfirmation}>
            {(<div>
                Are you sure you want to delete this reminder?
            </div>)}
        </SimpleDialog>
        <SimpleDialog
            title="Setup reminder"
            subtitle="Add below details to setup alert :"
            positiveBtn="Update"
            negativeBtn="Cancel"
            onClose={toggleEditReminderModal}
            positiveClick={() => addReminderRef.current()}
            show={editReminderModal}>
            {(<div>
                <AddReminderDialog
                    reminderView={reminderView}
                    addReminderRef={addReminderRef}
                    onClose={toggleEditReminderModal}
                    isUpdate={true}
                    categoryList={categoryList}
                />
            </div>)}
        </SimpleDialog>
    </div>)

}

export default ReminderDetails;
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import CategoryService from "../../services/category.service";
import {setMessage} from "./message";
import {ERROR_BAD_REQUEST} from "../../utils/constants";
import {logout} from "./auth";


const user = JSON.parse(localStorage.getItem("user"));
export const addNewCategory = createAsyncThunk(
    "category/addNewCategory",
    async ({title, desc, transactionType, iconUrl}, thunkAPI) => {

        try{
            const response = await CategoryService.addNewCategory(title, desc,transactionType, iconUrl);
            thunkAPI.dispatch(setMessage(response.data.message));
            thunkAPI.dispatch(setCategory({title, desc, transactionType, iconUrl}));
            return response.data;
        } catch (error) {
            console.log(error);
            const message =
                (error.response && error.response.data && error.response.data.errorMessage) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);

export const updateCategory = createAsyncThunk(
    "category/updateCategory",
    async ({ id, title, desc, transactionType, iconUrl}, thunkAPI) => {

        try{
            const response = await CategoryService.updateCategory(id, title, desc,transactionType, iconUrl);
            thunkAPI.dispatch(setMessage(response.data.message));
            thunkAPI.dispatch(setCategory({title, desc, transactionType, iconUrl}));
            return response.data;
        } catch (error) {
            console.log(error);
            const message =
                (error.response && error.response.data && error.response.data.errorMessage) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);

export const getAllCategory = createAsyncThunk(
    "category/getAllCategory",
    async ({ userId },thunkAPI) => {
        try {
            const response = await CategoryService.getAllCategory(userId);
            let sortCategories = response.data.sort((a, b) => a.title.localeCompare(b.title));
            thunkAPI.dispatch(setAllCategory(sortCategories));
            return response.data;
        } catch (error) {
            console.log("direct error, it may token expired");
            if(error.response ) {
               return error.response;
            }
            console.log(error);
        }
    }
)

export const deleteCategory = createAsyncThunk(
    "category/deleteCategory",
    async ({id},thunkAPI) => {
        try {
            const response = await CategoryService.deleteCategory(id);
            return response.data;
        } catch (error) {

            console.log("direct error, it may token expired");
            if(error.response && error.response.status == ERROR_BAD_REQUEST) {
                thunkAPI.dispatch(logout());
            }
            console.log(error);
        }
    }
)

const initialState = {};

const categorySlice = createSlice( {
    name: "category",
    initialState,
    reducers: {
        setAllCategory: (state, action) => {
            return { categoryList: action.payload };
        },
        setCategory: (state, action) => {
            //return { categoryList: [...state.categoryList, action.payload]  };
            // setting category as null, so that fresh id will be fetch from db
            return { categoryList: null  };
        }
    }
})

export const allCategoryList = (state) => {
    return state.category;
}

const {reducer, actions} = categorySlice;

export const { setAllCategory, setCategory } = actions;
export default reducer;
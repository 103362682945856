import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    addNewTransactionToList,
    calculateDaywiseAmountSpent,
    calculateTotalTransactionsAmount,
    getEncodedJson,
    isFallUnderRecentTransactions, isTransactionsFallUnderPassMonthCounts
} from "../../utils/utility.service";
import {
    ERROR_BAD_REQUEST,
    RECENT_TRANSACTION_DAYS_COUNT,
    TRANSACTION_OPERATION_ADD,
    TRANSACTION_OPERATION_UPDATE, TRANSACTION_TYPE_WITHDRAW
} from "../../utils/constants";
import {logout} from "./auth";
import TransactionStatsService from "../../services/transactionStats.service";
import moment from "moment/moment";
import {setLatestTransactionHistory} from "./transaction";

export const getLastFewDaysList = createAsyncThunk(
    "transactionStats/lastFewDaysList",
    async ({walletId}, thunkAPI) => {
        try {

            const filter = {
                lastFewDays: true,
                number: 3
            };
            const response = await TransactionStatsService.getLastFewDaysList(walletId, filter);
            const modifiedList = calculateDaywiseAmountSpent(response.data);
            thunkAPI.dispatch(setLastFewDaysList(modifiedList));
            return modifiedList;
        } catch (error) {
            console.log("direct error, it may token expired");
            if (error.response && error.response.status == ERROR_BAD_REQUEST) {
                thunkAPI.dispatch(logout());
            }
            console.log(error);
        }
    })

export const getLastXMonthsList = createAsyncThunk(
    "transactionStats/lastXMonthsList",
    async ({walletId}, thunkAPI) => {
        try {

            const filter = {'lastXMonths': {'enable': true, 'value': '1'}}; // last two months data
            const response = await TransactionStatsService.getLastXMonthsList(walletId, getEncodedJson(filter));

            rearrangeAllData(response.data, thunkAPI);
            return response;
        } catch (error) {
            console.log("direct error, it may token expired");
            if (error.response && error.response.status == ERROR_BAD_REQUEST) {
                thunkAPI.dispatch(logout());
            }
            console.log(error);
        }
    })

const rearrangeAllData = (data, thunkAPI) => {
    let twoMonthsData = data;
    let lastMonthTransactionList = [];
    let currentMonthTransactionList = [];
    let recentTransactionList = [];

    let currentMonthTotalSpent = 0;
    let lastMonthTotalSpent = 0;

    let currentMonthMMYYYY = moment().format("MMYYYY");

    if (twoMonthsData) {

        twoMonthsData.map((transaction) => {

            let dateMonth = moment(transaction.paidOn).format("MMYYYY");
            if (currentMonthMMYYYY === dateMonth) {
                currentMonthTransactionList.push(transaction);
                currentMonthTotalSpent = calculateTotalTransactionsAmount(transaction, currentMonthTotalSpent);
            } else {
                // last month
                lastMonthTransactionList.push(transaction);
                lastMonthTotalSpent = calculateTotalTransactionsAmount(transaction, lastMonthTotalSpent);
            }

            // if recent 3 days old transaction
            if (isFallUnderRecentTransactions(transaction.paidOn, RECENT_TRANSACTION_DAYS_COUNT)) {
                // recent list only
                recentTransactionList.push(transaction);
            }
        })

        thunkAPI.dispatch(setLastTwoMonthsList(twoMonthsData));

        if (lastMonthTransactionList) {
            const modifiedList = calculateDaywiseAmountSpent(lastMonthTransactionList);
            thunkAPI.dispatch(setLastMonthList(modifiedList));
            thunkAPI.dispatch(setLastMonthTotalSpent(parseFloat(lastMonthTotalSpent.toFixed(2))));
        }

        if (currentMonthTransactionList) {
            const modifiedList = calculateDaywiseAmountSpent(currentMonthTransactionList);
            thunkAPI.dispatch(setCurrentMonthList(modifiedList));
            thunkAPI.dispatch(setCurrentMonthTotalSpent(parseFloat(currentMonthTotalSpent.toFixed(2))));
        }

        if (recentTransactionList) {
            const modifiedList = calculateDaywiseAmountSpent(recentTransactionList);
            thunkAPI.dispatch(setLastFewDaysList(modifiedList));
        }
    }

}


export const updateTransactionList = createAsyncThunk(
    "transactionStats/lastXMonthsList",
    async ({transaction, operation}, thunkAPI) => {
        try {
            updateAllTransactionList(transaction, operation, thunkAPI)
        } catch (error) {
            console.log("direct error, it may token expired");
            if (error.response && error.response.status == ERROR_BAD_REQUEST) {
                thunkAPI.dispatch(logout());
            }
            console.log(error);
        }
    })


export const updateAllTransactionList = (transaction, operation, thunkAPI) => {

    if (transaction) {
        let {
            lastFewDaysList,
            currentMonthList,
            currentMonthTotalSpent,
            lastMonthList,
            lastMonthTotalSpent,
            lastTwoMonthsList
        } = thunkAPI.getState().transactionStats;

        let lastMonthMMYYYY = moment().subtract(1, 'months').format("MMYYYY");
        let currentMonthMMYYYY = moment().format("MMYYYY");
        let transactionMMYYYY = moment(transaction.paidOn).format("MMYYYY");

        let indexFoundTwoMonths = -1;
        let indexFoundLastMonth = -1;
        let indexFoundCurrentMonth = -1;
        let indexFoundRecentList = -1;
        let existingTransactionExcludeFlag = false;
        if(operation == TRANSACTION_OPERATION_UPDATE) {
            indexFoundTwoMonths = lastTwoMonthsList ? lastTwoMonthsList.findIndex(trans => trans.id === transaction.id) : -1;
            indexFoundLastMonth = lastMonthList ? lastMonthList.findIndex(trans => trans.id === transaction.id) : -1;
            indexFoundCurrentMonth = currentMonthList ? currentMonthList.findIndex(trans => trans.id === transaction.id) : -1;
            indexFoundRecentList = lastFewDaysList ? lastFewDaysList.findIndex(trans => trans.id === transaction.id) : -1;

            if(indexFoundTwoMonths >= 0) {
                existingTransactionExcludeFlag = lastTwoMonthsList[indexFoundTwoMonths].isExclude;
            }
        }

        if (transactionMMYYYY === currentMonthMMYYYY || transactionMMYYYY === lastMonthMMYYYY || indexFoundTwoMonths >= 0) {

            let newTransactionList = [];

            newTransactionList = [...lastTwoMonthsList];
            let { transactionList } = performTransactionOperation(newTransactionList, transaction, operation, true);
            thunkAPI.dispatch(setLastTwoMonthsList(transactionList));

            if (isFallUnderRecentTransactions(transaction.paidOn, RECENT_TRANSACTION_DAYS_COUNT) || indexFoundRecentList >=0) {
                newTransactionList = [...lastFewDaysList];
                let { transactionList } = performTransactionOperation(newTransactionList, transaction, operation);
                thunkAPI.dispatch(setLastFewDaysList(transactionList));
            }

            if (transactionMMYYYY === currentMonthMMYYYY || indexFoundCurrentMonth >= 0) {
                newTransactionList = [...currentMonthList];
                let { transactionList, totalSpent } = performTransactionOperation(newTransactionList, transaction, operation);

                thunkAPI.dispatch(setCurrentMonthList(transactionList));

                let transactionNew = Object.assign({}, transaction);
                transactionNew.amount = totalSpent; // temporary
               if(operation == TRANSACTION_OPERATION_UPDATE && !existingTransactionExcludeFlag) {
                    transactionNew.isExclude = false; // making it false to perform transaction
                }
                thunkAPI.dispatch(setCurrentMonthTotalSpent(calculateTotalTransactionsAmount(transactionNew, currentMonthTotalSpent)));
            }

            if (transactionMMYYYY === lastMonthMMYYYY || indexFoundLastMonth >= 0) {
                newTransactionList = [...lastMonthList];
                let { transactionList, totalSpent } = performTransactionOperation(newTransactionList, transaction, operation);

                thunkAPI.dispatch(setLastMonthList(transactionList));

                let transactionNew = Object.assign({}, transaction);
                transactionNew.amount = totalSpent; // temporary
                if(operation == TRANSACTION_OPERATION_UPDATE && !existingTransactionExcludeFlag) {
                    transactionNew.isExclude = false; // making it false to perform transaction
                }
                thunkAPI.dispatch(setLastMonthTotalSpent(calculateTotalTransactionsAmount(transactionNew, lastMonthTotalSpent)));
            }
        }
    }
}

const performTransactionOperation = (transactionList, transaction, operation, isOriginalListFlag) => {

    let totalSpent = 0;
    if (operation == TRANSACTION_OPERATION_ADD) {
        transactionList = addNewTransactionToList(transactionList, transaction);
        totalSpent = transaction.amount;
    } else if (operation == TRANSACTION_OPERATION_UPDATE) {
        let indexFound = transactionList.findIndex(trans => trans.id === transaction.id);
        let existingTransactionMMYYYY = null;
        if(indexFound >= 0) {
            existingTransactionMMYYYY = moment(transactionList[indexFound].paidOn).format("MMYYYY");
        }
        let newTransactionMMYYYY = moment(transaction.paidOn).format("MMYYYY");

        if(existingTransactionMMYYYY === newTransactionMMYYYY || isOriginalListFlag) {

          /*  let existingTransaction = transactionList[indexFound];
            if(existingTransaction.isExclude && !transaction.isExclude) {
                totalSpent = transaction.amount;
            } else if(!existingTransaction.isExclude && transaction.isExclude) {
                totalSpent = -transaction.amount;
            } else {
                totalSpent = transaction.amount - existingTransaction.amount;
            }*/

            totalSpent = calculateSpentAmountIfExcludeOrTransactionTypeChange(transactionList[indexFound], transaction);

            transactionList.splice(indexFound, 1, transaction); // deleting one element
            transactionList = calculateDaywiseAmountSpent(transactionList);
        } else if(existingTransactionMMYYYY == null) {
            // means new entry need to add this month (months switching)
            transactionList = addNewTransactionToList(transactionList, transaction);
            totalSpent = transaction.amount;
        } else {
            totalSpent =  transaction.amount - transactionList[indexFound].amount - transaction.amount;
            transactionList.splice(indexFound, 1); // deleting this element
            transactionList = calculateDaywiseAmountSpent(transactionList);
        }
    } else {
        let indexFound = transactionList.findIndex(trans => trans.id === transaction.id);
        Object.freeze(transactionList);
        totalSpent = -transactionList[indexFound].amount;

        let newTransactionList = [...transactionList];
        newTransactionList.splice(indexFound, 1); // deleting one element
        transactionList = calculateDaywiseAmountSpent(newTransactionList);
    }
    return { transactionList, totalSpent } ;
}

const calculateSpentAmountIfExcludeOrTransactionTypeChange = (existingTransaction, newTransaction) => {
    let totalSpent= 0;
    if(existingTransaction.isExclude && !newTransaction.isExclude) {
        totalSpent = newTransaction.amount;
    } else if(!existingTransaction.isExclude && newTransaction.isExclude) {
        totalSpent = -newTransaction.amount;
    } else {
        totalSpent = newTransaction.amount - existingTransaction.amount;
    }

    if(existingTransaction.transactionType != newTransaction.transactionType) {
        if(newTransaction.transactionType == TRANSACTION_TYPE_WITHDRAW) {
            totalSpent = Math.abs(totalSpent) + (newTransaction.amount * 2);
        } else {
            totalSpent = (Math.abs(totalSpent) + (existingTransaction.amount * 2));
        }
    }
    return totalSpent;
}

const initialState = {
    lastFewDaysList: null,
    monthWiseList: null,
    yearWiseList: null,
    lastTwoMonthsList: null,
    lastMonthList: null,
    currentMonthList: null,
    lastMonthTotalSpent: 0,
    currentMonthTotalSpent: 0
};

const transactionStatsSlice = createSlice({
    name: "transactionStats", initialState, reducers: {
        setLastFewDaysList: (state, action) => {
            state.lastFewDaysList = action.payload;
        }, setMonthWiseList: (state, action) => {
            state.monthWiseList = action.payload;
        }, setYearWiseList: (state, action) => {
            state.yearWiseList = action.payload;
        },
        setLastTwoMonthsList: (state, action) => {
            state.lastTwoMonthsList = action.payload;
        }, setLastMonthList: (state, action) => {
            state.lastMonthList = action.payload;
        }, setCurrentMonthList: (state, action) => {
            state.currentMonthList = action.payload;
        }, setLastMonthTotalSpent: (state, action) => {
            state.lastMonthTotalSpent = action.payload;
        }, setCurrentMonthTotalSpent: (state, action) => {
            state.currentMonthTotalSpent = action.payload;
        }
    }
});

export const allTransactionStats = (state) => {
    return state.transactionStats;
}

const {reducer, actions} = transactionStatsSlice;
export const {
    setLastFewDaysList,
    setMonthWiseList,
    setYearWiseList,
    setLastTwoMonthsList,
    setLastMonthList,
    setCurrentMonthList,
    setLastMonthTotalSpent,
    setCurrentMonthTotalSpent
} = actions;
export default reducer;

import axios from "axios";
import authHeader from "./auth-header";
import {DOMAIN_NAME} from "../utils/constants";

const API_URL = DOMAIN_NAME + "/api/wallet/";

const addUserWallet = (title, balance, isDefault, userId) => {
    console.log("addUserWallet axios");
    return axios.post( API_URL, {
        title, balance, isDefault, userId
    }, { headers: authHeader() })
};

const updateWallet = (id, title, balance, isDefault, userId) => {
    console.log("updateWallet axios");
    return axios.patch( API_URL, {
        id, title, balance, isDefault, userId
    }, { headers: authHeader() })
};

const getAllWalletByUser = (userId) => {
    console.log("getAllWalletByUser axios");
    return axios.get(API_URL + "user/" + userId, { headers: authHeader() });
};

const getDefaultWallet = (userId) => {
    console.log("getDefaultWallet axios");
    return axios.get(API_URL + "user/" + userId, { headers: authHeader() });
};


const walletService = {
    addUserWallet,
    updateWallet,
    getAllWalletByUser,
    getDefaultWallet
};

export default walletService
import Chart from "react-apexcharts";
import ReactApexChart from "react-apexcharts";

const ApexBarChart = ({ data, chartTitle, chartSubTitle, yAxisTitle, chartSeriesName}) => {

    const { categories, chartSeries} = data;

    const options = {
        chart: {
            type: 'bar',
            width: '50%'
        },
        plotOptions: {
            bar: {
                borderRadius: 1,
                dataLabels: {
                    position: 'top', // top, center, bottom
                },
            }
        },
        dataLabels: {
            enabled: false,
            formatter: function (val) {
                return val + "%";
            },
            offsetY: -20,
            style: {
                fontSize: '12px',
                colors: ["#304758"]
            }
        },

        xaxis: {
            categories: categories,
            position: 'bottom',
            axisBorder: {
                show: true
            },
            axisTicks: {
                show: true
            },
            crosshairs: {
                fill: {
                    type: 'gradient',
                    gradient: {
                        colorFrom: '#D8E3F0',
                        colorTo: '#BED1E6',
                        stops: [0, 100],
                        opacityFrom: 0.4,
                        opacityTo: 0.5,
                    }
                }
            },
            tooltip: {
                enabled: true,
            },
            toolbar: {
                show: false,
                tools: {
                    download: false
                }
            }
        },
        yaxis: {
            axisBorder: {
                show: true
            },
            axisTicks: {
                show: true,
            },
            labels: {
                show: true,
                formatter: function (val) {
                    return val + "";
                }
            }

        },
        title: {
            text: chartTitle,
            floating: true,
            offsetY: 0,
            align: 'center',
            style: {
                color: '#444'
            }
        },
        animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
        }
    };

    const series = [{
        name: 'Inflation',
        data: [2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2]
    }];


    return (
        <div className="parent-apex-chart">
            <ReactApexChart
                options={ options }
                series={ chartSeries }
                type="bar"
            />
        </div>
    )
}

export default ApexBarChart;
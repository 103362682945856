import AddWallet from "./AddWallet";
import SimpleDialog from "../../components/SimpleDialog";
import CreateCategory from "../../components/category/CreateCategory";
import React, {useState} from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {Button, Checkbox} from "@mui/material";
import {useSelector} from "react-redux";
import '../../assets/styles/wallet/wallet.page.scss'
import ModeEditIcon from "@mui/icons-material/ModeEdit";


const Wallets = () => {

    const [addWalletModal, setAddWalletModal] = useState(false);
    const [updateWalletModal, setUpdateWalletModal] = useState(false);
    const walletRef = React.useRef(null);
    const {wallet: walletList} = useSelector((state) => state.wallet);
    const { settings } = useSelector((state) => state.userProfile);
    const [selectedWallet, setSelectedWallet] = React.useState(null);

    const toggleWalletModal = () => {
        setAddWalletModal(!addWalletModal);
    }

    const toggleUpdateWalletModal = () => {
        setUpdateWalletModal(!updateWalletModal);
    }

    const editWallet = () => {
        toggleUpdateWalletModal();
    }

    return (
        <div className="wallet-page-section header-top-padding">

            <div className="wallet-container">
                <Button variant="contained" className="add-new-wallet" onClick={toggleWalletModal}>
                    Add new wallet
                </Button>

                <div className="wallet-list">
                    <span className="heading"> All wallets</span>
                    <div>

                    </div>
                    {
                        walletList ? walletList.map((wallet) => {
                            return (
                                <div key={wallet.id} className="wallet-item">
                                    <img alt="wallet" src={require('../../assets/images/ic_wallet.png')}/>
                                    <div className="wallet-title">
                                        <span>{wallet.title}</span>
                                        <span>{ settings ? settings.currency : ''} {wallet.totalSpentSoFar}</span>
                                        <span hidden={ !wallet.isDefault} className="default-wallet">Default wallet</span>
                                    </div>
                                    {/*<span>{wallet.transactionCount}</span>*/}
                                    <Button className="edit-wallet"
                                            variant="text"
                                            startIcon={<ModeEditIcon/>}
                                            style={{color: '#3f51b5'}}
                                            onClick={ () =>{
                                                setSelectedWallet(wallet);
                                                editWallet();
                                            } }>
                                    </Button>

                            </div>)
                        }) : ''
                    }
                </div>

            </div>


            <SimpleDialog
                title="Add new wallet"
                subtitle="Please add the wallet details below :"
                positiveBtn="Save"
                negativeBtn="Cancel"
                onClose={toggleWalletModal}
                positiveClick={() => walletRef.current()}
                show={addWalletModal}>
                {(<div>
                    <AddWallet walletRef={walletRef}
                               onClose={toggleWalletModal}
                    />
                </div>)}
            </SimpleDialog>

            <SimpleDialog
                title="Update wallet"
                subtitle="Please update the wallet details below :"
                positiveBtn="Save"
                negativeBtn="Cancel"
                onClose={toggleUpdateWalletModal}
                positiveClick={() => { walletRef.current(); window.location.reload();}}
                show={updateWalletModal}>
                {(<div>
                    <AddWallet walletRef={walletRef}
                               updateWallet={ selectedWallet }
                               onClose={toggleUpdateWalletModal}
                    />
                </div>)}
            </SimpleDialog>
        </div>
    )
}

export default Wallets;
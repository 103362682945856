
export const ALL_COUNTRY_CURRENCY = [
    {
        'country': 'Albania',
        'code':'ALL',
        'symbol':'L'
    },
    {
        'country': 'Argentina',
        'code':'ARS',
        'symbol':'$'
    },
    {
        'country': 'Armenia',
        'code':'AMD',
        'symbol':'Դ'
    },
    {
        'country': 'Australia',
        'code':'AUD',
        'symbol':'$'
    },
    {
        'country': 'Azerbaijan',
        'code':'AZN',
        'symbol':'₼'
    },
    {
        'country': 'Belarus',
        'code':'BYN',
        'symbol':'Br'
    },
    {
        'country': 'Bolivia',
        'code':'BOB',
        'symbol':'Bs.'
    },
    {
        'country': 'Botswana',
        'code':'BWP',
        'symbol':'P'
    },
    {
        'country': 'Bulgaria',
        'code':'BGN',
        'symbol':'Лв.'
    },
    {
        'country': 'India',
        'code':'INR',
        'symbol':'₹'
    }
];
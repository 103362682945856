import {Component} from "react";
import {connect} from "react-redux";
import { getAllWalletByUser} from "../../store/slices/wallet";

class StepThree extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.currentStep !== 3) {
            return null;
        }

        //console.log("StepThree render called");
        const { user } = this.props.user;
        const { defaultWallet } = this.props.wallet;
        return (
            <div className="step_three_wallet_created">

                {
                    !defaultWallet ?
                        <p>Loading...</p>:
                        <p>
                            Your wallet has been created successfully!
                        </p>
                }

            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.userProfile,
    wallet: state.wallet
})

export default connect(mapStateToProps, null)(StepThree)
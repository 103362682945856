import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {signin, signup} from "../../store/slices/auth";
import {clearMessage} from "../../store/slices/message";
import {Navigate, useNavigate} from "react-router-dom";
import {Alert, Button, Checkbox, FormControlLabel, Link, TextField} from "@mui/material";
import {googleLogout, useGoogleLogin} from "@react-oauth/google";
import axios from "axios";
import {updateUserSetting} from "../../store/slices/userProfile";

const SignIn = () => {

    const [successful, setSuccessful] = useState(false);
    const {message} = useSelector((state) => state.message);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [usernameError, setUsernameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);

    const [user, setUser] = useState(null);
    const [profile, setProfile] = useState(null);

    useEffect(() => {
        dispatch(clearMessage());

        if (user) {
            axios
                .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                    headers: {
                        Authorization: `Bearer ${user.access_token}`,
                        Accept: 'application/json'
                    }
                })
                .then((res) => {

                    if (res.data) {
                        let googleSignInData = {
                            username: res.data.email,
                            firstName: res.data.given_name,
                            lastName: res.data.family_name,
                            email: res.data.email,
                            pictureUrl: res.data.picture,
                            signInSource: 'google-oauth',
                            uniqueId: res.data.id
                        }
                        setSuccessful(false);
                        dispatch(signin(googleSignInData))
                            .unwrap()
                            .then(() => {
                                setSuccessful(true);
                                navigate("/")
                            })
                            .catch(() => {
                                setSuccessful(false);
                            })
                    }

                    setProfile(res.data);
                })
                .catch((err) => console.log(err));
        }

    }, [user]);

    const handleSignin = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);

        setUsernameError(false);
        setPasswordError(false);

        if (formData.get('email') == '' && formData.get('password') == '') {
            setUsernameError(true);
            setPasswordError(true);
            return;
        }

        if (formData.get('email') == '') {
            setUsernameError(true);
            return;
        }
        if (formData.get('password') == '') {
            setPasswordError(true);
            return;
        }

        setSuccessful(false);
        dispatch(signin({
            username: formData.get('email'),
            password: formData.get('password')
        }))
            .unwrap()
            .then(() => {
                setSuccessful(true);
                navigate("/");
            })
            .catch(() => {
                setSuccessful(false);
            })
    }

    const handleSignUp = () => {
        navigate('/Signup');
    }

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => setUser(codeResponse),
        onError: (error) => console.log('Login Failed:', error)
    });

    // log out function to log the user out of google and set the profile array to null
    const logOut = () => {
        console.log("Logged-out")
        googleLogout();
        setProfile(null);
        setUser(null)
    };


    return (
        <div className="Auth-form-container">
            <form className="Auth-form" onSubmit={handleSignin}>
                <div className="Auth-form-content">
                    <h3 className="Auth-form-title">Sign In</h3>

                    <div className="text-center">
                        Not registered yet?{" "}
                        <span className="link-primary" onClick={handleSignUp}>
            Sign Up
            </span>
                    </div>
                    {
                        message && (
                            <div className="form-group mt-3">
                                {successful ?
                                    <Alert severity="success">{message}</Alert> :
                                    <Alert severity="error">{message}</Alert>
                                }
                            </div>
                        )
                    }
                    <div className="form-group mt-3">

                        <TextField className="form-control"
                                   variant="outlined"
                                   label="Username"
                                   error={usernameError}
                                   name="email"/>
                        {/* {emailError ?
                            <span className="form-error-message">Email/username is already taken</span> : ''}*/}
                    </div>
                    <div className="form-group mt-3">
                        <TextField className="form-control"
                                   variant="outlined"
                                   type="password"
                                   label="Password"
                                   error={passwordError}
                                   name="password"/>
                    </div>
                    <div className="form-group mt-3 forgot-remember">
                        <FormControlLabel
                            control={<Checkbox name="rememberMe"/>}
                            label="Remember Me"
                        />
                        <Link
                            component="button"
                            variant="body2">
                            Forgot Password?
                        </Link>
                    </div>
                    <div className="d-grip gap-2 mt-3">
                        <Button
                            variant="contained"
                            type="submit"
                            style={{
                                backgroundColor: "#34ac23"
                            }}
                            className="form-control btn btn-primary">
                            Login
                        </Button>
                    </div>

                    <div>
                        <br/>
                        <p style={{textAlign: 'center'}}>OR</p>
                        <Button variant="contained"
                                style={{
                                    backgroundColor: "#FFFFFF",
                                    color: "#000000"
                                }}
                                className="btn-google-sign-in form-control btn btn-primary"
                                onClick={() => login()}>
                            <img className="btn-google-sign-in-icon" alt="wallet"
                                 src={require('../../assets/images/ic_google.png')}/>
                            <span>Login in with Google</span>
                            <span> </span>
                        </Button>

                    </div>

                </div>
            </form>
        </div>
    )
}

export default SignIn;
import {Alert, Autocomplete, Button, Card, CardActions, CardContent, Grid, TextField} from "@mui/material";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import Box from "@mui/material/Box";
import '../../assets/styles/setting.scss';
import Typography from "@mui/material/Typography";
import {ALL_COUNTRY_CURRENCY} from "../../utils/currency.symbol";
import {logout} from "../../store/slices/auth";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {currentUserProfile, setUserSetting, updateUserSetting} from "../../store/slices/userProfile";
import {deleteSubCategory} from "../../store/slices/subCategory";
import Snackbar from "@mui/material/Snackbar";

const Settings = () => {

    const [openSnack, setOpenSnack] = useState(false);
    const [isAnyChange, setAnyChange] = useState(false);
    const dispatch = useDispatch();
    const {settings: userSettings} = useSelector((state) => state.userProfile);
    const [ selectedCurrency, setSelectedCurrency] = useState({
        'country': 'Botswana',
        'code':'BWP',
        'symbol':'P'
    });

    useEffect(() => {
        if (userSettings) {
            setSelectedCurrency(getSelectedItem(userSettings.currency));
        }
    }, [dispatch]);

    const handleAutoComplete = (event, value) => {
        console.log(value);
        setAnyChange(value.code != selectedCurrency.code);
        setSelectedCurrency(value);
    }

    const handleSaveCurrency = () => {
        console.log(selectedCurrency.symbol);

        dispatch(updateUserSetting(
            {
                userId: userSettings.userId,
                currency: selectedCurrency.symbol,
                lastLogin: undefined}))
            .unwrap()
            .then((response) => {
                if(response) {
                    console.log("setting has been updated");
                    console.log(response);
                    setOpenSnack(true)
                }
            });

        let updatedCurrencySetting = {
            ...userSettings,
            'currency': selectedCurrency.symbol
        };
        dispatch(setUserSetting(updatedCurrencySetting));
    }

    const getSelectedItem = (userSymbol) => {
        const item = ALL_COUNTRY_CURRENCY.find((opt)=>{
            if (opt.symbol == userSymbol)
                return opt;
        })
        return item || {};
    }

    const handleCloseSnack = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnack(false);
    };
    return (
        <div className="setting-section">


            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
            >

                <Grid item xs={5}>

                    <Card sx={{minWidth: {xs: 180} }}>

                        <Box sx={{display: 'flex', alignItems: 'center', pl: 1, pb: 1}}>
                            <Typography sx={{fontSize: 18, padding: 1, fontWeight: 600 }} color="text.secondary" gutterBottom>
                                <p>Setting page</p>
                            </Typography>
                        </Box>

                        <CardContent sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', pl: 1, pb: 1}}>
                            {/* <Typography sx={{fontSize: 16, pr: 5}} color="text.secondary" gutterBottom>
                        Currency
                    </Typography>*/}

                            <Autocomplete
                                id="country-select-demo"
                                sx={{ width: 250, marginLeft: 5, marginRight: 5 }}
                                options={ ALL_COUNTRY_CURRENCY }
                                autoHighlight
                                onChange={ handleAutoComplete }
                                value={ selectedCurrency }
                                isOptionEqualToValue={(option, value) => option.code === value.code}
                                getOptionLabel={(option) => option.code + ' - ' +option.country}
                                renderOption={(props, option) => (
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                        {option.code} - {option.country}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Choose Currency "
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                        </CardContent>

                        <CardActions sx={{display: 'flex', justifyContent: 'center',mt: 5, mb: 2}}>
                            <Button
                                sx={{ pl: 4, pr: 4}}
                                 disabled={ !isAnyChange }
                                 color="primary"
                                 variant="contained"
                                 onClick={ handleSaveCurrency }>
                                Save
                            </Button>
                        </CardActions>
                    </Card>


                </Grid>
            </Grid>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={openSnack}
                autoHideDuration={2000}
                onClose={handleCloseSnack}
            >
                <Alert
                    onClose={handleCloseSnack}
                    severity="success" sx={{width: '100%'}}>
                    Saved changes
                </Alert>
            </Snackbar>

        </div>
    )
}

export default Settings;
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {Alert, Button, TextField} from "@mui/material";
import React, {useState} from "react";
import {signup} from "../../store/slices/auth";
import {useDispatch, useSelector} from "react-redux";
import {addNewCategory, updateCategory} from "../../store/slices/category";
import {currentUserProfile} from "../../store/slices/userProfile";
import MenuItem from "@mui/material/MenuItem";
import CircularLoading from "../splash/CircularLoading";
import '../../assets/styles/category/createCategory.scss';

const CreateCategory = ({addCategoryRef, onClose, isUpdate, updateCategoryItem, handlePositiveSubmit}) => {

    const {user: currentUser} = useSelector(currentUserProfile);
    const dispatch = useDispatch();
    const [successful, setSuccessful] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const categoryFormValues = {
        title: {
            value: isUpdate ? updateCategoryItem.title : '',
            error: false,
            errorMessage: 'You must title name'
        },
        desc: {
            value: isUpdate ? updateCategoryItem.desc : '',
            error: false,
            errorMessage: 'You must enter description'
        },
        transactionType: {
            value: 0, //isUpdate ? updateCategoryItem.transactionType : 0,
            error: false,
            errorMessage: 'You must select transaction type'
        },
        iconUrl: {
            value: isUpdate ? updateCategoryItem.iconUrl : '',
            error: false,
            errorMessage: 'You must enter icon name'
        }
    };

    const [categoryValues, setCategoryValues] = useState(categoryFormValues);

    React.useEffect(() => {
        if (addCategoryRef) {
            addCategoryRef.current = handleSubmitCategory;
        }
    }, [categoryValues])

    const handleCloseClick = () => {
        onClose && onClose(this);
    }

    const handleSubmitCategory = () => {

        const categoryFields = Object.keys(categoryValues);
        let newCategoryValues = {...categoryValues}

        let isFormError = false;
        for (let index = 0; index < categoryFields.length; index++) {
            const currentField = categoryFields[index];
            const currentValue = categoryValues[currentField].value;

            if (currentValue === '') {
                isFormError = true;
                newCategoryValues = {
                    ...newCategoryValues,
                    [currentField]: {
                        ...newCategoryValues[currentField],
                        error: true
                    }
                }
            }
        }

        setCategoryValues(newCategoryValues)
        if (isFormError) {
            return;
        }

        setLoading(true);
        if (isUpdate) {
            editCategory();
        } else {
            addCategory();
        }

    }

    const addCategory = () => {

        setSuccessful(false);
        dispatch(addNewCategory({
            title: categoryValues.title.value,
            desc: categoryValues.desc.value,
            transactionType: categoryValues.transactionType.value,
            iconUrl: categoryValues.iconUrl.value
        }))
            .unwrap()
            .then(() => {
                setSuccessful(true);
                handleCloseClick();
            })
            .catch(() => {
                setSuccessful(false);
            })
    }

    const editCategory = () => {

        setSuccessful(false);
        let categoryDetailsUpdate = {
            id: updateCategoryItem.id,
            title: categoryValues.title.value,
            desc: categoryValues.desc.value,
            transactionType: categoryValues.transactionType.value,
            iconUrl: categoryValues.iconUrl.value
        };

        dispatch(updateCategory(categoryDetailsUpdate))
            .unwrap()
            .then(() => {
                handlePositiveSubmit(categoryDetailsUpdate);
                setSuccessful(true);
                handleCloseClick();
            })
            .catch(() => {
                setSuccessful(false);
            })
    }

    const handleChange = (event) => {
        const {name, value} = event.target;
        const errorValue = value.length == 0;
        setCategoryValues({
            ...categoryValues,
            [name]: {
                ...categoryValues[name],
                value,
                error: errorValue
            }
        });
    }

    return (
        <div className="create-category-section">
            {
                isLoading ? <CircularLoading/> : ""
            }

            <div className="create-category-form">
                <TextField
                    id="filled-basic"
                    label="Title"
                    variant="outlined"
                    value={categoryValues.title.value}
                    error={categoryValues.title.error}
                    helperText={categoryValues.title.error && categoryValues.title.errorMessage}
                    onChange={handleChange}
                    name="title"/>
                <TextField
                    id="outlined-basic"
                    label="Description"
                    variant="outlined"
                    value={categoryValues.desc.value}
                    error={categoryValues.desc.error}
                    helperText={categoryValues.desc.error && categoryValues.desc.errorMessage}
                    onChange={handleChange}
                    name="desc"/>

                <TextField select label="Select"
                           label="Transaction type"
                           name="transactionType"
                           value={categoryValues.transactionType.value}
                           value={categoryValues.transactionType.value}
                           error={categoryValues.transactionType.error}
                           helperText={categoryValues.transactionType.error && categoryValues.transactionType.errorMessage}
                           onChange={handleChange}
                >
                    <MenuItem value="0"> Withdraw </MenuItem>)
                    <MenuItem value="1"> Credit </MenuItem>)
                </TextField>
                <TextField
                    id="filled-basic"
                    label="icon"
                    variant="outlined"
                    value={categoryValues.iconUrl.value}
                    error={categoryValues.iconUrl.error}
                    helperText={categoryValues.iconUrl.error && categoryValues.iconUrl.errorMessage}
                    onChange={handleChange}
                    name="iconUrl"/>
            </div>

            {/*<Button variant="contained" onClick={ handleSubmitCategory }>Add category</Button>*/}
        </div>
    );
}

export default CreateCategory
import {Card, CardContent, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import React, {useState} from "react";
import '../../assets/styles/reports/subCategoryStats.scss';
import {calculateTotalSpentSoFar, getTitleFromCategory} from "../../utils/utility.service";
import SubCategoryStatsItem from "./SubCategoryStatsItem";
import SimpleDialog from "../SimpleDialog";
import OtherCategoryItem from "./OtherCategoryItem";


const SubCategoryStats = ({transactionList, categoryList, subCategoryList, currency}) => {

    const [selectCategory, setSelectCategory] = useState(0);
    const [subCategoryStatsList, setSubCategoryStatsList] = useState([]);
    const [totalSpendForSubCategory, setTotalSpendForSubCategory] = useState([]);
    const [otherCategoryId, setOtherCategoryId] = useState(0);
    const [otherCategoryList, setOtherCategoryList] = useState([]);

    const [modalConfirmation, setModalConfirmation] = useState(false);

    React.useEffect(() => {
        if (transactionList && transactionList.length > 0) {
            let displayCategory = findCategoryIdForData()
            setSelectCategory(displayCategory);
            calculateSubCategoryStats(displayCategory);
        }
    }, [transactionList])

    const handleChange = (event) => {
        const {name, value} = event.target;
        const errorValue = value.length == 0;
        setSelectCategory(value);
        calculateSubCategoryStats(value);
    }

    // this is first time when user jump to report page to see the sub-category stats which has some data otherwise "No data" String
    const findCategoryIdForData = () => {

        debugger
        if(categoryList && categoryList.length > 0 && transactionList.length > 0) {

            let foundCategory = categoryList.find(category => {
                return transactionList.find(item => item.categoryId == category.id)
            });
            return foundCategory.id;
        }
        return categoryList[0].id;
    }

    const calculateSubCategoryStats = (categoryId) => {

        let subCategoryTransactionList = []
        let subCategoryMap = []
        let totalSpend = 0;
        let otherCategoryId = 0;

        if (transactionList && transactionList.length > 0) {

            transactionList.map(transaction => {

                if (transaction.isExclude || transaction.categoryId != categoryId) {
                    return; // skipping exclude items in the report
                }
                subCategoryTransactionList.push(transaction);

                let indexFound = subCategoryMap.findIndex(data => data.subCategoryId === transaction.subCategoryId);
                if (subCategoryMap[indexFound]) {
                    subCategoryMap[indexFound] = {
                        ...subCategoryMap[indexFound],
                        amount: Number(subCategoryMap[indexFound].amount) + Number(transaction.amount)
                    }
                } else {
                    let title = getTitleFromCategory(transaction.subCategoryId, subCategoryList);
                    subCategoryMap.push({
                        subCategoryId: transaction.subCategoryId,
                        subcategory: title,
                        amount: transaction.amount
                    });

                    if(title == 'Other' || title == "other") {
                        otherCategoryId = transaction.subCategoryId;
                    }
                }

            })
        }

        totalSpend = calculateTotalSpentSoFar(subCategoryTransactionList)
        setTotalSpendForSubCategory(totalSpend);

        if (subCategoryMap && subCategoryMap.length > 0) {
            let modifiedSubCategoryMap = subCategoryMap.map(item => {
                return {...item, percentage: (item.amount * 100) / totalSpend}
            })

            // sort and set the array
            setSubCategoryStatsList(modifiedSubCategoryMap.sort((a, b) => b.amount - a.amount));

            // Setting up other category to show popup and display list
            if( otherCategoryId != 0) {
                let subCategoryOtherList= subCategoryTransactionList.filter(item => item.subCategoryId == otherCategoryId)
                setOtherCategoryId(otherCategoryId);
                setOtherCategoryList(subCategoryOtherList);
            }
        } else {
            setSubCategoryStatsList([]);
            setOtherCategoryId(0);
            setOtherCategoryList([]);
        }

    }

    const toggleConfirmationModal = () => {
        setModalConfirmation(!modalConfirmation);
    }

    return (
        <div className="subcategory-stats-section">
            <Card title="Title" sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}>
                <CardContent className="card-content-main">

                    <Typography gutterBottom variant="h6" component="div" className="title">
                        Sub-category Statistics
                    </Typography>

                    <TextField select label="Select"
                               className="field-category"
                               label="Category"
                               value={selectCategory}
                               name="category"
                               onChange={handleChange}>

                        <MenuItem value="0"> Category </MenuItem>)
                        {
                            categoryList ? categoryList.map((category) => {
                                let image_path = '';
                                try {
                                    image_path = require('../../assets/images/' + category.iconUrl + '.png');
                                } catch (err) {
                                    image_path = require('../../assets/images/ic_default.png');  //set default image path
                                }
                                return (<MenuItem value={category.id} id={category.id}
                                                  key={category.id}><img className="select-category-icon"
                                                                         alt="category-icons"
                                                                         src={image_path}/> {category.title}
                                </MenuItem>)
                            }) : ""}
                    </TextField>

                    {
                        (subCategoryStatsList && subCategoryStatsList.length > 0) ?
                            <div>
                                <div className="totalSpent">
                                    <span>Total spent : {currency} {totalSpendForSubCategory}</span>
                                </div>
                                {
                                    subCategoryStatsList.map(item => {
                                        return (<SubCategoryStatsItem
                                            item={item}
                                            percentage="10"
                                            currency={currency}
                                            onClick={toggleConfirmationModal}
                                        />)
                                    })
                                }
                            </div>
                            :
                            <div className="not-found-section">
                                <span>No data available!</span>
                            </div>
                    }

                </CardContent>
            </Card>

            <SimpleDialog
                title="Other"
                positiveBtn="Save"
                isPositiveBtnDisable = "true"
                negativeBtn="Close"
                onClose={toggleConfirmationModal}
                show={modalConfirmation}>
                {(<div>
                    {
                        (otherCategoryList && otherCategoryList.length > 0) ?
                            otherCategoryList.map(item => {
                                    return (<OtherCategoryItem currency={currency} transaction={item}/>)
                            }) : ''
                    }
                </div>)}
            </SimpleDialog>
        </div>)
}

export default SubCategoryStats;
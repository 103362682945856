import axios from "axios";
import authHeader from "./auth-header";
import {DOMAIN_NAME} from "../utils/constants";

const API_URL = DOMAIN_NAME + "/api/transaction/";

const addNewTransaction = (walletId,
                           categoryId,
                           subCategoryId,
                           amount,
                           spendAt,
                           note,
                           location,
                           isImportant,
                           isExclude,
                           transactionType,
                           paidType,
                           paidOn) => {
    console.log("addNewTransaction axios");
    return axios.post( API_URL, {
        walletId,
        categoryId,
        subCategoryId,
        amount,
        spendAt,
        note,
        location,
        isImportant,
        isExclude,
        transactionType,
        paidType,
        paidOn
    }, { headers: authHeader() })
};

const editTransaction = (id,
                           walletId,
                           categoryId,
                           subCategoryId,
                           amount,
                           spendAt,
                           note,
                           location,
                           isImportant,
                           isExclude,
                           transactionType,
                           paidType,
                           paidOn) => {
    console.log("editTransaction axios");
    return axios.put( API_URL + id, {
        walletId,
        categoryId,
        subCategoryId,
        amount,
        spendAt,
        note,
        location,
        isImportant,
        isExclude,
        transactionType,
        paidType,
        paidOn
    }, { headers: authHeader() })
};

const getAllTransactionByWalletId = (walletId) => {
    console.log("getAllTransactionByWalletId axios");
    return axios.get(API_URL + "wallet/" + walletId, { headers: authHeader() });
};

const deleteTransaction = (id) => {
    return axios.delete(API_URL + id, { headers: authHeader() });
};

const transactionService = {
    addNewTransaction,
    getAllTransactionByWalletId,
    editTransaction,
    deleteTransaction
}
export default  transactionService;
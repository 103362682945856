import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux";
import {Navigate} from "react-router-dom";
import {currentUserProfile, getUserInfo} from "../../store/slices/userProfile";
import {Avatar, Card, CardContent} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import '../../assets/styles/profile.scss';
import EmailIcon from '@material-ui/icons/Email';
import {printTest} from "../../utils/utility.service";

const Profile = () => {
    const [successful, setSuccessful] = useState(false);
    const {user: currentUser} = useSelector(currentUserProfile);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUserInfo());
    }, [dispatch]);

    /*
        const loadUserProfile = () => {
            dispatch(getUserInfo())
                .unwrap()
                .then((response) => {
                    console.log(response);
                    setSuccessful(true)
                })
                .catch(() => {
                    setSuccessful(false);
                })
        }
    */


    printTest();

    return (<section className="profile-screen-section header-top-padding">

        {/*
         <h5>Profile</h5>

        {currentUser ?
            (
                <div>
                    <span> {currentUser.firstName + " " + currentUser.lastName} </span><br/>
                    <span> {"Email: " + currentUser.email} </span><br/>
                    <span> {"UserId: " + currentUser.id} </span><br/>
                    <span> {"Role: " + currentUser.role} </span>
                </div>
            )
            :
            ""
        }
*/}


        <Card sx={{display: 'flex', minWidth: {lg: 500, md: 400, sm: 300, xs: 100}, marginLeft: 1, marginRight: 1}}>
            <Avatar
                src={ currentUser.pictureUrl  }
                sx={{width: 80, height: 80, margin: 1.5 }}
            >
                { currentUser.firstName.charAt(0)+""+currentUser.lastName.charAt(0)}
            </Avatar>
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                <CardContent sx={{flex: '1 0 auto'}}>
                    <Typography component="div" variant="h5">
                        {currentUser.firstName + " " + currentUser.lastName}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                        <EmailIcon color="primary"  />{ " " + currentUser.email }
                    </Typography>
                </CardContent>
            </Box>

        </Card>


    </section>);
}

export default Profile;
import {logout} from "../../store/slices/auth";
import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {resetTransaction} from "../../store/slices/transaction";

const Logout = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(logout());
        //dispatch(resetTransaction());
        navigate('/Signin');
    }, [dispatch]);

    return (<div> User logout successfully !</div>)
}

export default Logout;
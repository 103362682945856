import '../../assets/styles/reports/subCategoryStatsItem.scss'
import React from "react";
import {styled} from '@mui/material/styles';
import LinearProgress, {linearProgressClasses} from '@mui/material/LinearProgress';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const SubCategoryStatsItem = ({item, percentage, currency, onClick}) => {

    const BorderLinearProgress = styled(LinearProgress)(({theme}) => ({
        height: 8,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[200],
            ...theme.applyStyles('dark', {
                backgroundColor: theme.palette.grey[800],
            }),
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: '#1a90ff',
            ...theme.applyStyles('dark', {
                backgroundColor: '#308fe8',
            }),
        },
    }));

    let isOther = (item.subcategory == 'Other' || item.subcategory == "other")

    return (
        <div className="subcategory-stats-item-section" key={item.subCategoryId} >

            <div className="sub-category-header">
                <div className="sub-category-item">
                    <span> {item.subcategory} { isOther? <InfoOutlinedIcon color="primary" onClick={onClick} /> : ''}</span>
                </div>
                <div className="amount">
                    <span className="amount-credit">{currency} {item.amount}</span>
                </div>
            </div>
            <BorderLinearProgress variant="determinate" value={item.percentage}/>
        </div>)
}

export default SubCategoryStatsItem;
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {signup} from "../../store/slices/auth";
import {clearMessage} from "../../store/slices/message";
import {useNavigate} from "react-router-dom";
import {Alert, Button, Checkbox, FormControlLabel, Link, TextField} from "@mui/material";


const SignUp = () => {
    let [authMode, setAuthMode] = useState("signin");

    const [successful, setSuccessful] = useState(false);
    const {message} = useSelector((state) => state.message);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [key, setKey] = useState(0);

    useEffect(() => {
        dispatch(clearMessage());
    }, [dispatch]);

    const formInitialValues = {
        firstName:{
            value:'',
            error:false,
            errorMessage:'You must enter first name'
        },
        lastName:{
            value:'',
            error:false,
            errorMessage:'You must enter last name'
        },
        email:{
            value:'',
            error:false,
            errorMessage:'You must enter your valid email address'
        },
        password:{
            value:'',
            error:false,
            errorMessage:'You must enter password'
        }
    };

    const [formValues, setFormValues] = useState(formInitialValues);

    const changeAuthMode = () => {
        setAuthMode(authMode === "signin" ? "signup" : "signin")
    }

    const handleChange = (event) => {
        const {name, value} = event.target;
        const errorValue = value.length == 0;
        setFormValues({
            ...formValues,
            [name]:{
                ...formValues[name],
                value,
                error: errorValue
            }
        });
    }

    const clearForm = () => {
        setKey(key + 1);
        setFormValues(formInitialValues);
    }

    const handleSignup = (event) => {
        event.preventDefault();

        console.log("calling handle signup");

        const formFields = Object.keys(formValues);
        let newFormValues = {...formValues}

        let isFormError = false;
        for (let index = 0; index < formFields.length; index++) {
            const currentField = formFields[index];
            const currentValue = formValues[currentField].value;

            if(currentValue === ''){
                isFormError = true;
                newFormValues = {
                    ...newFormValues,
                    [currentField]:{
                        ...newFormValues[currentField],
                        error:true
                    }
                }
            }
        }

        setFormValues(newFormValues)
        if(isFormError) {
            return;
        }


        const formData = new FormData(event.target);

        setSuccessful(false);
        dispatch(signup({
            firstName: formData.get('firstName'),
            lastName: formData.get('lastName'),
            email: formData.get('email'),
            password: formData.get('password')}))
            .unwrap()
            .then(() => {
                setSuccessful(true);
                clearForm();
            })
            .catch(() => {
                setSuccessful(false);
            })
    }

    const handleSignIn = () => {
        navigate('/Signin');
    }

    return (
        <div className="Auth-form-container">
            <form key={key} className="Auth-form" onSubmit={handleSignup}>
                <div className="Auth-form-content">
                    <h3 className="Auth-form-title">Sign Up</h3>
                    <div className="text-center">
                        Already registered?{" "}
                        <span className="link-primary" onClick={handleSignIn}>
                             Sign In
                        </span>
                    </div>
                    {
                        message && (
                            <div className="form-group mt-3">
                                {successful ?
                                    <Alert severity="success">{message}</Alert> :
                                    <Alert severity="error">{message}</Alert>
                                }
                            </div>
                        )
                    }

                    <div className="form-group mt-3">
                        <TextField className="form-control"
                                   variant="outlined"
                                   label="First Name"
                                   onChange={handleChange}
                                   error={formValues.firstName.error}
                                   helperText={formValues.firstName.error && formValues.firstName.errorMessage}
                                   name="firstName"/>
                    </div>
                    <div className="form-group mt-3">
                        <TextField className="form-control"
                                   variant="outlined"
                                   label="Last Name"
                                   onChange={handleChange}
                                   error={formValues.lastName.error}
                                   helperText={formValues.lastName.error && formValues.lastName.errorMessage}
                                   name="lastName"/>
                    </div>
                    <div className="form-group mt-3">
                        <TextField className="form-control"
                                   type="email"
                                   variant="outlined"
                                   label="Email address"
                                   onChange={handleChange}
                                   error={formValues.email.error}
                                   helperText={formValues.email.error && formValues.email.errorMessage}
                                   name="email"/>
                    </div>
                    <div className="form-group mt-3">
                        <TextField className="form-control"
                                   type="password"
                                   variant="outlined"
                                   label="Password"
                                   onChange={handleChange}
                                   error={formValues.password.error}
                                   helperText={formValues.password.error && formValues.password.errorMessage}
                                   name="password"/>
                    </div>
                    <div className="d-grid gap-2 mt-3">
                        <Button
                            variant="contained"
                            type="submit"
                            style={{
                                backgroundColor: "#34ac23"
                            }}
                            className="form-control btn btn-primary">
                            Sign up
                        </Button>

                        <button
                            type="button"
                            onClick={ clearForm }
                            className="form-control btn btn-primary">
                            Reset
                        </button>
                    </div>
                </div>

            </form>
        </div>
    );
};

export default SignUp;
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {allReminderList, deleteReminder, getAllReminder} from "../../store/slices/reminder";
import {allNotificationList, getAllNotification, notificationCompleted} from "../../store/slices/notification";
import ReminderItem from "../reminder/ReminderItem";
import NotificationItem from "./NotificationItem";
import '../../assets/styles/notification/notificationPage.scss';
import {allCategoryList} from "../../store/slices/category";
import SimpleDialog from "../../components/SimpleDialog";

const Notifications = () => {

    const dispatch = useDispatch();
    const {notificationList} = useSelector(allNotificationList);
    const {categoryList} = useSelector(allCategoryList);
    const {user: userProfile} = useSelector((state) => state.userProfile);
    const [modalConfirmation, setModalConfirmation] = useState(false);
    const [notificationSelected, setNotificationSelected] = useState(null);

    useEffect(() => {

        if (!notificationList && userProfile) {
            dispatch(getAllNotification({userId: userProfile.id}));
        }
    }, [userProfile]);

    const toggleConfirmationModal = () => {
        setModalConfirmation(!modalConfirmation);

        if (modalConfirmation) {
            setNotificationSelected(null);
        }
    }

    const handleListClick = (value) => () => {
        if (value) {
            setNotificationSelected(value);
            toggleConfirmationModal();
        }
    };

    const handleMarkDone = () => {
        dispatch(notificationCompleted({notificationObj: notificationSelected}))
            .unwrap()
            .then((response) => {
               console.log("Notification completed: "+response);
                toggleConfirmationModal();
            })
            .catch((err) => {
                console.log(err);
            })
    }

    return (
        <section className="notification-page-section header-top-padding">
            <div className="main-notification-section">
                <span className="heading">Notification List</span>
                <span className="description col-sm-1 col-md-4">You can view all notifications here.
                Actions taken on older notifications will disappear
                if they are more than 10 days old.</span>
                <div className="notification-all-items">
                    {
                        (notificationList && notificationList.length > 0) ?
                            notificationList.map((notification, index) => {
                                return (
                                    <div key={notification.id}>
                                        <NotificationItem
                                            notificationView={notification}
                                            categoryList={categoryList}
                                            onClick={handleListClick(notification)}
                                            lastItem={ index == (notificationList.length-1)}
                                        />
                                    </div>);
                            }) :
                            <span>
                        No notification available!
                    </span>
                    }
                </div>
            </div>
            <SimpleDialog
                title={ (notificationSelected && notificationSelected.state) ? "Have you completed this task?" : "Task completed!" }
                positiveBtn= "Mark done"
                negativeBtn={ (notificationSelected && notificationSelected.state) ? "Cancel" : "Close" }
                isPositiveBtnDisable= { (notificationSelected && notificationSelected.state) ? false : true }
                onClose={ toggleConfirmationModal }
                positiveClick={ handleMarkDone }
                show={ modalConfirmation }>
                {(<div className="notification-status-dialog">
                    <div className="title">
                        <span>{ notificationSelected ? notificationSelected.title : ''}</span>
                    </div>
                    <div className="message">
                        <span>Message: </span>
                        <span>{ notificationSelected ? notificationSelected.remark : '' }</span>
                    </div>
                </div>)}
            </SimpleDialog>

        </section>
    )
}

export default Notifications